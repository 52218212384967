// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import React from 'react'

import RewindSvg from '../../assets/images/rewind-30.svg'
// import Rewind from '../../components/svgs/Rewind'
import { progressData } from '../../constant/TrainingConstant'
import { ShipContext } from '../../context/ShipContext'
import { ShipInfo } from '../../types/progressBarTypes'
import ProgressWrapper from './ProgressBar'

const dotStyle = {
  width: '12px',
  height: '12px',
  borderRadius: '100%',
  backgroundColor: '#fff',
  outline: 'none',
  border: 'none',
  margin: '0 auto'
}
const dotStyleNotDone = {
  width: '12px',
  height: '12px',
  borderRadius: '100%',
  backgroundColor: '#B9B0C4',
  outline: 'none',
  border: 'none',
  margin: '0 auto'
}

const containerStyle = {
  display: 'flex',
  // marginTop: '10px',
  alignItems: 'center',
  justifyContent: 'space-between'
}
const titleStyle = {
  display: 'block',
  color: '#fff',
  marginTop: '10px'
}
const titleStyleNotDone = {
  display: 'block',
  color: '#B9B0C4',
  marginTop: '10px'
}

const Progress: React.FC<{ shipInfo: ShipInfo[], isTrainingCompleted: Boolean, order: number, onChangeCallback: (val: string, b?: boolean)=>void, rewindVideo: ()=>void
}> = ({ shipInfo, isTrainingCompleted, order, onChangeCallback, rewindVideo }) => {
  const { trainingVideoTime } = React.useContext(ShipContext)
  return (
    <ProgressWrapper style={containerStyle}>
      <div className="progress-container">
        <button className="back-btn">
          <img src={RewindSvg} alt="" className='rewind-btn' onClick={rewindVideo} />
          {/* <Rewind /> */}
        </button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }} className="progress-wrapper">
        {
          progressData.map((item, index) => {
            const isIntroTime = item.introTime < trainingVideoTime
            return (
              <>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                    <ProgressWrapper.Dot style={isIntroTime ? dotStyle : dotStyleNotDone} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ProgressWrapper.Dots count={item.count} subItemFrame={item.subItemsTimeFrame} title={item.title} progressOrder={item?.order} onChangeCallback={onChangeCallback}/>
                    </div>
                  </div>
                  <ProgressWrapper.Title title={item.title} style={isIntroTime ? titleStyle : titleStyleNotDone} />
                </div>
              </>
            )
          })
        }
      </div>
    </ProgressWrapper>
  )
}

export default Progress
