import React from 'react'

import CalibrateCards from '../../components/CalibrateCards/CalibrateCards'
import Text from '../../components/Text/Text'

export default function DirectionStack () {
  return (
    <>
      {/* <ShipManifests /> */}
      {/* {(2 + 2 === 4) */}
      {/* ? ( */}
      <div className='calibrate-wrapper'>
        <Text preset='h2' text={'The 7C\'s Training'} style={{ marginTop: '0', marginBottom: '0' }} />
        <CalibrateCards />
      </div>
      {/* )
        : ( */}
      {/* <CalibrateBanner /> */}
      {/* )} */}
    </>
  )
}
