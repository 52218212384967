import React from 'react'

import './index.css'
import { Logo } from '../../assets/images/icons'

const PageLoader = () => {
  return (
 <div className='loader-wrapper'>
    <img src={Logo} alt="" className='logo-img' />
    <div className="waveWrapper waveAnimation">
  <div className="waveWrapperInner bgTop">

    <div className="wave waveTop" style={{ backgroundImage: 'url(\'http://front-end-noobs.com/jecko/img/wave-top.png\')' }}></div>

  </div>
  <div className="waveWrapperInner bgMiddle">

       <div className="wave waveTop" style={{ backgroundImage: 'url(\'http://front-end-noobs.com/jecko/img/wave-mid.png\')' }}></div>
  </div>
  <div className="waveWrapperInner bgBottom">

      <div className="wave waveTop" style={{ backgroundImage: 'url(\'http://front-end-noobs.com/jecko/img/wave-bot.png\')' }}></div>
  </div>
</div>
 </div>
  )
}

export default PageLoader
