/* eslint-disable indent */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
interface Props {
    isOpen: boolean;
    closeHandler: () => void;
    okHandler: () => void;
    okHandler2?: () => void;
    cancelHandler: () => void;
    header: string;
    body: string | React.ReactNode;
    labelOk: string;
    labelOk2?: string;
    labelCancel: string;
}
const BasicModal: React.FC<Props> = ({ isOpen, closeHandler, okHandler, cancelHandler, header, body, labelCancel, labelOk, okHandler2, labelOk2 }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={closeHandler}
            aria-labelledby='Delete recording modal'
            aria-describedby='Confirm this action before proceeding'
        >
            <DialogTitle id='alert-dialog-title' >{header}</DialogTitle>
            <DialogContent sx={{ maxWidth: 'auto' }} id="scrollableDiv">
                <DialogContentText id='alert-dialog-description'>
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelHandler}>{labelCancel}</Button>
                <Button
                    onClick={okHandler}
                    autoFocus
                >
                    {labelOk}
                </Button>
              {
                okHandler2 && (
                <Button
                    onClick={okHandler2}
                    autoFocus
                >
                    {labelOk2}
                </Button>
                )
              }
            </DialogActions>
        </Dialog>
    )
}
export default BasicModal
