import React, { MouseEventHandler } from 'react'
import './index.css'

interface Props{
    eventHandle: MouseEventHandler<HTMLButtonElement>
    buttonText:string,
    isTransparent?:boolean,
    size?: 'sm' | 'lg' | 'md'
    width?:string
    fontSize?:string
    style?:React.CSSProperties,
    formStage?:string,
    isDisable?:boolean
    isBackStep?: boolean
}

const CustomButton:React.FC<Props> = (props) => {
  const { eventHandle, buttonText, isTransparent, size = 'sm', style, formStage, isDisable = false, isBackStep = false } = props

  const btnStyle = isTransparent
    ? {
        backgroundColor: 'transparent',
        border: '1px solid #fff',
        color: '#fff',
        width: props.width,
        fontSize: props.fontSize
      }
    : {
        width: props.width,
        fontSize: props.fontSize,
        margin: '0px 10px',
        letterSpacing: '5px'
      }
  return (
    <button className={`custom-btn ${size}`} onClick={(e) => { eventHandle(e) }} style={style || btnStyle } id={formStage} disabled={isDisable} data-backStep={isBackStep}>{buttonText}</button>

  )
}

export default CustomButton
