import { api } from '../api'
export const uploadImage = async (file: File | null) => {
  return api.post(
    '/users/upload-profile-image',
    {
      image: file
    }
    ,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}
