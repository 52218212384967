import CloseIcon from '@mui/icons-material/Close'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useState, useEffect, useRef, useContext } from 'react'
import './index.css'
import { useTheme } from '@mui/material/styles'

import bgImage from '../../assets/images/training_background.png'
import { trainingList, ShipItemLimit, progressData } from '../../constant/TrainingConstant'
import { AuthContext } from '../../context/AuthContext'
import { ShipContext } from '../../context/ShipContext'
import { pdfData } from '../../services/ship.service/pdfData'
import { getUserDetails, updateTrainingCompleted } from '../../services/users.service/getUser'
import { ShipItemsPdfData } from '../../types'
import { ShipInfo, ShipItemFrame } from '../../types/progressBarTypes'
import { ShipItemParams } from '../../types/ShipTypes'
import { deleteStorage, getStorageDate, setStorageData } from '../../utils/localStorage'
import AudioTabs from '../AudioTabs/AudioTabs'
import BasicModal from '../BasicModal/BasicModal'
import { addData, deleteData, getAllData } from '../IDB/idb'
import CommonSkeleton from '../Skeleton'
import AuditoryCue from './AuditoryCue'
import DownloadSection from './download'
import ImageUpload from './ImageUpload'
import ManifestReview from './ManifestReview'
import ManifeastStatement from './MeanifeastStatement'
import Progress from './Progress'
import SparkWord from './SparkWord'
import TrainingStart from './TrainingStart'
import { shipName } from './utils'

import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'

const messageStyle =
{
  width: '0px',
  padding: '0px',
  overflow: 'hidden'
}

const initialTrainingFormData: ShipItemParams = {
  title: '',
  manifest: '',
  image: '',
  audio: '',
  depositURL: ''
}

const Training = () => {
  const [show, setShow] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [showForm, setShowForm] = useState<string>('start')

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const myVideo = useRef<HTMLVideoElement | null>(null)

  const [trainingFormData, setTrainingFormData] = useState<ShipItemParams>(initialTrainingFormData)
  const [shipinfo, setShipInfo] = useState<ShipInfo[]>([])
  const [subTitle, setSubTitle] = useState('')
  const [isTrainingCompleted, setIsTrainingCompleted] = useState(false)
  const { setTrainingVideoTime, trainingVideoTime, order, setOrder, shipDataArray, setShipDataArray } = useContext(ShipContext)
  const { setUser, timeStamp, shipLimit, user } = useContext(AuthContext)

  const [muted, setMuted] = useState(false)
  const [showExpand, setShowExpand] = useState(false)
  const [play, setPlay] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const videoPlayer = document.getElementById('trainingVideo') as HTMLVideoElement
  const titles = ['Intro', 'Calm', 'Finish', 'Clear']
  const [showModal, setShowModal] = useState(false)
  const [trainingIndexedDbData, setTrainingIndexedDbData] = useState<ShipItemParams>(initialTrainingFormData)
  const FIRST_MANIFEST_TIME = '12:42'
  const TRAINING_COMPLETION_TIME = '30:32'
  const VIDEO_END_TIME = '30:51'

  const getStageAndTitle = () => {
    let subTitle = ''
    let stage = ''
    switch (getStorageDate('step')) {
      case 'start':
      case 'manifest_statement':
        subTitle = 'Ship Manifest'
        stage = getStorageDate('step') || ''
        break
      case 'spark_word':
        subTitle = 'Spark Words'
        stage = 'spark_word'
        break
      case 'auditory_cue':
        subTitle = 'Auditory Cues'
        stage = 'auditory_cue'
        break
      case 'image_upload_step':
        subTitle = 'Visual Imagery'
        stage = 'image_upload_step'
        break
      case 'review_ship':
        subTitle = 'Manifest Review'
        stage = 'review_ship'
        break
    }

    return { subTitle, stage }
  }

  const db = async (data: ShipItemParams) => {
    await addData({ id: 1, trainingData: data })
  }

  useEffect(() => {
    getAllData().then((result: any) => {
      if (result[0]?.trainingData) {
        setTrainingIndexedDbData(result[0].trainingData)
        setTrainingFormData(result[0].trainingData)
      }
    })
  }, [showForm])

  useEffect(() => {
    !getStorageDate('step') && setStorageData('step', 'start')
    !getStorageDate('ship_limit') && setStorageData('ship_limit', shipLimit || '3')
    setSubTitle(getStageAndTitle().subTitle)
  }, [showForm])

  // Function to add leading zero to single-digit numbers
  const padTime = (time: number): string => {
    return time.toString().padStart(2, '0')
  }

  const convertToSeconds = (time: string) => {
    const [minutes, seconds] = time ? time!.split(':') : ['0', '0']
    return Number(minutes) * 60 + Number(seconds)
  }

  useEffect(() => {
    if (myVideo.current) {
      // myVideo.current.muted = false
      // myVideo.current.play()
    }
    const videoPlayer = document.getElementById('trainingVideo') as HTMLVideoElement

    // videoPlayer.play()
    // Function to update the time
    const updateTime = () => {
      const minutes = Math.floor(videoPlayer.currentTime / 60)
      const seconds = Math.floor(videoPlayer.currentTime % 60)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define

      const minuteAndSec = `${padTime(minutes)}:${padTime(seconds)}`
      setTrainingVideoTime(minuteAndSec)
      setStorageData('videoTime', minuteAndSec)
      const newProgressData = progressData.filter((d) => !titles.includes(d.title))

      const timeFrames = [
        { step: 'start', index: 0 },
        { step: 'spark_word', index: 1 },
        { step: 'auditory_cue', index: 2 },
        { step: 'image_upload_step', index: 3 },
        { step: 'review_ship', index: 4 }
      ]

      const timeFrameIndex = timeFrames.findIndex(
        (frame) => getStorageDate('step') === frame.step && newProgressData.map((d) => d.subItemsTimeFrame[frame.index].time).includes(minuteAndSec)
      )

      if (timeFrameIndex !== -1) {
        setStorageData('shipStatus', 'started')
        videoPlayer.pause()
        setShow(true)
        setShowExpand(true)
      }
    }
    // Event listener for the video time update
    videoPlayer.addEventListener('timeupdate', updateTime)
    videoPlayer.disablePictureInPicture = true

    return () => {
      videoPlayer.removeEventListener('timeupdate', updateTime)
    }
  }, [])

  const handleFormCallback: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    const fieldId = event.target.id
    setTrainingFormData({
      ...trainingIndexedDbData,
      [fieldId]: event.target.value
    })
  }

  const handleCustomFormCallback: (key: string, val: string) => void = (key, val) => {
    setTrainingFormData(prevState => ({ ...prevState, [key]: val }))
  }

  const userDetails = async () => {
    const userResp = await getUserDetails()

    return userResp?.data?.data
  }

  const onChangeCallback = async (formStep: string, submitted: Boolean = false, skipped: Boolean = false, isBackStep: Boolean = false) => {
    if (!submitted) {
      db(trainingFormData)
      const count = shipDataArray.find((d) => d.order === order)?.count
      if (count === 0) {
        const indexedValues = await getAllData()
        const values: string[] = Object.values(indexedValues[0].trainingData)
        const step = formStep === 'manifest_statement' ? 'start' : formStep
        const ord = progressData.find((d) => d.order === order)?.subItemsTimeFrame.findIndex((d) => d.step === step) as number
        const newOrd = ord === 0 ? 0 : ord - 1
        if (!values.every((d: string) => d.length > 0)) {
          const time = progressData.find((d) => d.order === order)?.subItemsTimeFrame[newOrd].time as string
          if (formStep !== 'manifest_statement' && !isBackStep && getStorageDate('videoTime') === time) {
            videoPlayer.play()
            setPlay(true)
            setShow(!show)
            setShowExpand(!showExpand)
          }
        } else if (getStorageDate('step') === 'review_ship') {
          const time = progressData.find((d) => d.order === order)?.subItemsTimeFrame[4].time as string
          if (time !== trainingVideoTime) {
            videoPlayer.play()
            setPlay(true)
            setShow(!show)
            setShowExpand(!showExpand)
          }
        }
      }
    } else {
      if (skipped) {
        const { is_training_completed: isTrainingCompleted } = await userDetails()
        if (isTrainingCompleted) {
          setShow(true)
          setShowExpand(true)
          const indexedValues = await getAllData()
          if (indexedValues[0]?.audio || indexedValues[0]?.audio === '') {
            videoPlayer.play()
            setPlay(true)
          }
        } else {
          setShow(!show)
          setShowExpand(!showExpand)
          videoPlayer.play()
          setPlay(true)
        }
      }
      setTrainingFormData(initialTrainingFormData)
      setTrainingIndexedDbData(initialTrainingFormData)
      deleteData()
    }
    setShowForm(formStep)
    setStorageData('shipStatus', 'started')
  }

  useEffect(() => {
    if (trainingVideoTime < '30:30') {
      setShowForm(getStageAndTitle().stage)
    }
  }, [trainingVideoTime])

  const fetchShipArray = async () => {
    const response = await pdfData()
    const data = response.data?.data.map((d: ShipItemsPdfData) => ({
      order: d.order,
      count: d.shipItems.length
    }))

    return data
  }
  const fetchCurrentShip = async () => {
    let ship
    const data = await fetchShipArray()
    if (Number(getStorageDate('ship_limit')) > ShipItemLimit) {
      const allShips = data.filter((d: ShipInfo) => d.count >= ShipItemLimit)
      ship = allShips.slice(-1)[0]
    } else {
      ship = data.find((d: ShipInfo) => d.count < ShipItemLimit)
    }

    return ship
  }

  type labalData = { order: number, next: string, current: string }

  const fetchLabel = () => {
    const labelsData: labalData[] = [
      { order: 1, next: 'Self Ships', current: 'Health Ships' },
      { order: 2, next: 'Play Ships', current: 'Self Ships' },
      { order: 3, next: 'Passion Ships', current: 'Play Ships' },
      { order: 4, next: 'Wealth Ships', current: 'Passion Ships' },
      { order: 5, next: 'Complete Training', current: 'Wealth Ships' }
    ]

    const labelObj = labelsData.find((d) => d.order === order)!
    return order === 5 ? { current: `Add more ${labelObj.current}`, next: labelObj.next } : { current: `Add more ${labelObj.current}`, next: 'Move on to Next Section' }

    // const ship = await fetchCurrentShip()
    // if (ship) {
    //   const label = labelsData.find((d) => d.order === ship.order)!.label
    //   setLabel()
    // }
  }

  useEffect(() => {
    const processPdfData = async () => {
      if (showForm === 'start' || showForm === 'audio_list') {
        const response = await pdfData()
        const data = response.data?.data.map((d: ShipItemsPdfData) => ({
          order: d.order,
          count: d.shipItems.length
        }))
        setShipDataArray(data)
        if (data) {
          const indexedValues = await getAllData()
          const userResp = await userDetails()
          if (userResp.is_training_completed) {
            setIsTrainingCompleted(userResp.is_training_completed)
            const audioId = userResp?.videoBookmark?.training_data?.audioId
            if (audioId === '' || audioId) {
              setOrder(7)
              setShowForm('download')
            } else {
              setOrder(6)
              setShowForm('audio_list')
            }
            setLoading(false)
            setShow(true)
            setShowExpand(true)
          } else {
            const ship = await fetchCurrentShip()
            if (ship) {
              const values: string[] = indexedValues[0]?.trainingData
              if (ship.count >= ShipItemLimit && !values) {
                setShowModal(true)
              }
              setOrder(ship.order)
              setLoading(false)
            }
          }
        }
        setShipInfo(data)
      }
    }

    processPdfData()
  }, [showForm])

  const fetchPauseVideoTimes = () => {
    const newProgressData = progressData.filter(d => !titles.includes(d.title))
    return [...newProgressData.map((d) => d.subItemsTimeFrame.map(d => d.time)).flat(), VIDEO_END_TIME]
  }

  useEffect(() => {
    const videoPlayer = document.getElementById('trainingVideo') as HTMLVideoElement

    const video = (videoTime: string) => {
      if (videoTime) {
        if (fetchPauseVideoTimes().includes(videoTime)) {
          setShowExpand(true)
          setShow(true)
        }
        if (videoTime === '36:55') {
          videoPlayer.currentTime = convertToSeconds(TRAINING_COMPLETION_TIME)
        } else {
          videoPlayer.currentTime = convertToSeconds(videoTime)
        }
        videoPlayer.pause()
      }
    }
    const resetVideoTime = async () => {
      const videoTime = getStorageDate('videoTime')
      const userResp = await userDetails()
      if (userResp.is_training_completed) {
        if (userResp?.videoBookmark?.training_data?.audioId || userResp?.videoBookmark?.training_data?.audioId === '') {
          if (timeStamp < '30:31') {
            video(TRAINING_COMPLETION_TIME)
          } else {
            video(timeStamp)
          }
        } else {
          video('30:31')
        }
      } else {
        if (videoTime || timeStamp < FIRST_MANIFEST_TIME) {
          video(videoTime || timeStamp)
        } else {
          const ship = await fetchCurrentShip()
          const shipCount = ship.count
          const shipOrder = ship.order
          const index = shipCount === 0 ? 0 : 4
          const newTimeStamp = progressData.find((d) => d.order === shipOrder)?.subItemsTimeFrame[index]?.time
          video(newTimeStamp as string)
        }
      }
    }

    resetVideoTime()
  }, [])

  const handleVideoClick = () => {
    const videoPlayer = document.getElementById('trainingVideo') as HTMLVideoElement
    if (fetchPauseVideoTimes().includes(getStorageDate('videoTime') || '') || loading) return
    setPlay((prevPlay) => !prevPlay)
    if (videoPlayer.paused) {
      videoPlayer.play()
    } else {
      videoPlayer.pause()
    }
  }

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === ' ') {
        handleVideoClick()
      }
    }

    window.addEventListener('keypress', handleKeyPress)
    return () => {
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [handleVideoClick])

  const handleInspiration = () => {
    setShowMessage(!showMessage)
  }
  const continueToAddShip = async () => {
    setShowModal(false)
    await onChangeCallback('start', true)
    setStorageData('step', 'start')
  }

  const skipToAddShip = async () => {
    setShowModal(false)
    if (order === 5) {
      deleteStorage('step')
      deleteStorage('ship_limit')
      const resp = await updateTrainingCompleted()
      setUser(resp?.data?.data)
      await onChangeCallback('audio_list', true, true)
    } else {
      if (setOrder) {
        setOrder(order + 1)
      }
      setStorageData('ship_limit', '3')
      await onChangeCallback('start', true, true)
      setStorageData('step', 'start')
    }
  }

  const extractPlayTime = (pauseTime: string) => {
    let time
    const timeDiff = convertToSeconds(trainingVideoTime) - convertToSeconds(pauseTime)
    if (timeDiff > 30) {
      if ((convertToSeconds(trainingVideoTime) - 30) >= convertToSeconds('30:35') && (convertToSeconds(trainingVideoTime) - 30) <= convertToSeconds('30:43')) {
        time = convertToSeconds('30:34')
      } else {
        time = convertToSeconds(trainingVideoTime) - 30
      }
    } else {
      time = convertToSeconds(pauseTime) + 1
    }

    return time
  }
  const findSubItemFrames = (order: number) => progressData.find((d) => d.order === order)?.subItemsTimeFrame
  const rewindVideo = () => {
    if (fetchPauseVideoTimes().includes(trainingVideoTime)) return
    let time
    if (trainingVideoTime < FIRST_MANIFEST_TIME) {
      time = convertToSeconds(trainingVideoTime) - 30
    } else if (trainingVideoTime > '30:31') {
      time = extractPlayTime('30:31')
    } else {
      const currentStep = getStorageDate('step')
      if (currentStep !== 'start') {
        const shipItemsFrame = findSubItemFrames(order)
        const currentIndex = shipItemsFrame?.findIndex((d: ShipItemFrame) => d.step === currentStep)
        if (shipItemsFrame) {
          const pauseTime = shipItemsFrame[currentIndex! - 1].time!
          time = extractPlayTime(pauseTime)
        }
      } else {
        const prevOrder = order - 1
        const subItemsTimeFrame = findSubItemFrames(prevOrder)
        const lastPauseTime = subItemsTimeFrame?.find((d) => d.order === 4)?.time
        time = extractPlayTime(lastPauseTime!)
      }
    }
    videoPlayer.currentTime = time!
  }

  const getTitle = () => {
    let title = ''
    if (trainingVideoTime < '03:01') {
      title = 'INTRO'
    } else if (trainingVideoTime < '07:52') {
      title = 'CALM'
    } else if (trainingVideoTime < '10:48') {
      title = 'CLEAR'
    } else {
      if (order > 5) {
        title = order > 6 ? 'CONGRATULATIONS' : 'FINISHING TOUCHES'
      } else {
        const subname = 'SHIPS'
        const name = shipName(order)?.toUpperCase()
        title = `${name} ${subname}`
      }
    }

    return title
  }

  return (
    <div className="trainig-container" style={{ backgroundImage: `url(${bgImage})` }}>
      {
        order <= 5 && <BasicModal body="" cancelHandler={() => continueToAddShip()} closeHandler={() => setShowModal(true)} header="Do you wish to continue adding more ships?" isOpen={showModal} labelCancel={fetchLabel().current} labelOk={fetchLabel().next} okHandler={() => skipToAddShip()} />
      }
      <div style={{ position: 'relative', height: '100%' }} className='video-wrapper'>
        {
          !play && !fetchPauseVideoTimes().includes(trainingVideoTime) && !loading && (
            <div style={{ position: 'absolute', top: '50px', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 99, backgroundColor: 'rgb(255 255 255 / 67%)', color: '#212121', padding: '10px 20px', borderRadius: '25px', fontSize: '13px' }}>
              Please click on the screen to play video.
            </div>
          )
        }
        <video ref={myVideo} id='trainingVideo' className="video-container" controls={false} onClick={handleVideoClick}>
          <source src={'https://d3fzisqnsukjql.cloudfront.net/training_video/new_training_video.mp4'} type="video/mp4" />
        </video>
        {
          !fetchPauseVideoTimes().includes(trainingVideoTime) && !loading && getStorageDate('videoTime') && (
            <div className='play-control' onClick={!loading && handleVideoClick}>
              {
                play ? <PauseCircleOutlineIcon style={{ fontSize: '50px', opacity: '0.5' }} /> : <PlayCircleIcon style={{ fontSize: '50px', opacity: '0.5' }} />
              }
            </div>
          )
        }
        {
          muted
            ? <VolumeOffIcon className="volume-btn" onClick={() => {
              videoPlayer.muted = !videoPlayer.muted
              setMuted(!muted)
            }} />
            : <VolumeUpIcon className="volume-btn" onClick={() => {
              videoPlayer.muted = !videoPlayer.muted
              setMuted(!muted)
            }} />
        }
      </div>
      <div className="message-container" style={!showMessage ? messageStyle : {}}>
        <div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" color={'#37445F'} marginBottom={2} style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{`${shipName(order)} | Ship Manifest Examples`}</Typography >

            </div>
          </div>
        </div>
        <button type="button" className="message-close" onClick={() => setShowMessage(false)}>
          <CloseIcon />
        </button>
        <ul>
          {
            trainingList.find((list) => list.order === order)?.manifestExamples.map((manifest, index) => {
              return (
                <li key={index}>
                  {manifest}
                </li>
              )
            })
          }
        </ul>
      </div>
      <div className="trainig-content-overlay">
        {
          loading
            ? <>
              <CommonSkeleton height={50} baseColor='#414c5ba2' highlightColor='#414c5b85' width='10%' count={1} style={{ marginBottom: ' 20px' }} />
              <CommonSkeleton height={50} baseColor='#414c5ba2' highlightColor='#414c5b85' width='97%' count={1} />
            </>
            : <>
              {
                showExpand && (
                  <button className="collaps-btn" onClick={() => setShow(!show)}>
                    {
                      show ? <ExpandMoreIcon /> : <ExpandLessIcon />
                    }
                  </button>
                )
              }

              {
                show
                  ? <>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <div style={{ position: 'absolute', top: '-50px', left: '0px', background: 'red', padding: '10px' }}>
                        </div> */}
                        {
                          order < 7 && <Typography style={{ fontFamily: 'Montserrat', fontWeight: '600' }} variant={isMobile ? 'h6' : 'h3'} display="flex" alignItems="baseline" color={'white'} marginBottom={2} >{shipName(order)} {order <= 5 && <>| <span style={{ fontSize: '20px', marginLeft: '15px' }}>{shipName(order) && subTitle}</span></>}
                          </Typography >
                        }
                      </div>
                    </div>

                    {
                      showForm === 'start'
                        ? <>
                          <Box sx={{ width: '100%', marginBottom: '35px' }}>
                            <TrainingStart handleInspiration={handleInspiration} handleFormCallback={handleFormCallback} onChangeCallback={onChangeCallback} data={trainingFormData} order={order} shipData={shipDataArray} indexedDBdata={trainingIndexedDbData} />
                          </Box>
                        </>
                        : showForm === 'manifest_statement'
                          ? <>
                            <Box sx={{
                              width: '50%',
                              marginBottom: '35px',
                              '@media (max-width: 600px)': {
                                width: '100%'
                              }
                            }} >
                              <ManifeastStatement handleInspiration={handleInspiration} handleFormCallback={handleFormCallback} onChangeCallback={onChangeCallback} data={trainingFormData} order={order} shipData={shipDataArray} indexedDBdata={trainingIndexedDbData} />
                            </Box>
                          </>
                          : showForm === 'spark_word'
                            ? <>
                              <Box sx={{
                                width: '50%',
                                marginBottom: '35px',
                                '@media (max-width: 600px)': {
                                  width: '100%'
                                }
                              }}>
                                <SparkWord handleFormCallback={handleFormCallback} onChangeCallback={onChangeCallback} data={trainingFormData} order={order} indexedDBdata={trainingIndexedDbData} />
                              </Box>
                            </>
                            : showForm === 'auditory_cue'
                              ? <>
                                <Box sx={{
                                  width: '50%',
                                  marginBottom: '35px',
                                  '@media (max-width: 600px)': {
                                    width: '100%'
                                  }
                                }}>
                                  <AuditoryCue onChangeCallback={onChangeCallback} handleCustomFormCallback={handleCustomFormCallback} data={trainingFormData} order={order} indexedDBdata={trainingIndexedDbData} />
                                </Box>
                              </>
                              : showForm === 'image_upload_step'
                                ? <>
                                  <Box sx={{
                                    width: '50%',
                                    marginBottom: '35px',
                                    '@media (max-width: 600px)': {
                                      width: '100%'
                                    }
                                  }}>
                                    <ImageUpload onChangeCallback={onChangeCallback} handleCustomFormCallback={handleCustomFormCallback} data={trainingFormData} order={order} indexedDBdata={trainingIndexedDbData} />
                                  </Box>
                                </>
                                : showForm === 'review_ship'
                                  ? <>
                                    <Box sx={{
                                      width: '50%',
                                      marginBottom: '35px',
                                      '@media (max-width: 600px)': {
                                        width: '100%'
                                      }
                                    }}>
                                      <ManifestReview onChangeCallback={onChangeCallback} setTrainingFormData={setTrainingFormData} order={order} setOrder={setOrder} setShowModal={setShowModal} indexedDBdata={trainingIndexedDbData} />
                                    </Box>
                                  </>
                                  : showForm === 'audio_list'
                                    ? <>
                                      <Box sx={{
                                        width: '50%',
                                        marginBottom: '35px',
                                        '@media (max-width: 600px)': {
                                          width: '100%'
                                        }
                                      }}>
                                        <AudioTabs onChangeCallback={onChangeCallback} order={order} setOrder={setOrder} />
                                      </Box>
                                    </>
                                    : showForm === 'download'
                                      ? <>
                                        <Box sx={{
                                          width: '50%',
                                          marginBottom: '35px',
                                          '@media (max-width: 600px)': {
                                            width: '100%'
                                          }
                                        }}>
                                          <DownloadSection />
                                        </Box>
                                      </>
                                      : null
                    }

                  </>

                  : null
              }

              <Box>
                {
                  !show
                    ? <div className='progress-title-mobile' style={{ position: 'absolute', top: '-57px', left: '0px', background: '#414c5b85', padding: '15px 20px', display: 'block', borderTopRightRadius: '5px' }}>
                      <p style={{ fontFamily: 'Montserrat', fontSize: '22px', fontWeight: '600', margin: '0', color: '#fff' }}>{getTitle()}</p >
                    </div>
                    : null
                }
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {
                    !user?.is_training_completed && <Progress shipInfo={shipinfo} isTrainingCompleted={isTrainingCompleted} order={order} onChangeCallback={onChangeCallback} rewindVideo={rewindVideo} />
                  }

                </div>
              </Box>
            </>
        }

      </div>
    </div>
  )
}

export default Training
