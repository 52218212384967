import { api } from '../api'
export const getUserDetails = async () => {
  return api.get(
    '/users'
  )
}

export const updateTrainingCompleted = async () => {
  return api.post(
    '/users/complete-training'
  )
}
