import React from 'react'

import Video from '../../components/video/7C/video'

const Crew = () => {
  // const body = 'You only have a 5% chance of NOT reaching your dream if you are consistently reporting your progress to a supportive friend or group. CREW means committing to a plan of action with another dreamer or group of dreamers, then creating an accountability meeting or method. '
  return (
    <>
      <Video name='crew.mp4' title='Crew' body={''} />
    </>
  )
}

export default Crew
