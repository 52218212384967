import { Button } from '@mui/material'
import React from 'react'

import Text from '../../components/Text/Text'

interface Iprops {
  title: string
  subtitle: string
  text: any
  path: string,
  buttonText:string
}

const GetStarted: React.FC<Iprops> = ({ title, subtitle, text, path, buttonText }) => {
  const handleClick = () => {
    window.location.href = path
  }

  return (
    <>
      <Text preset='eyebrow' text={title} style={{ textAlign: 'center', marginBottom: 0 }} />
      <Text preset="h1" text={subtitle} style={{ textAlign: 'center', marginTop: '0' }} />
      <Text preset='paragraph' text={text} style={{ textAlign: 'center', marginBottom: 0, maxWidth: '550px', margin: '0 auto' }} />
      <div style={{ textAlign: 'center' }}>
        <Button
          size="large"
          type="submit"
          className="form-submit-button"
          onClick={handleClick}
        >
     {buttonText}
        </Button>
      </div>
    </>
  )
}

export default GetStarted
