// import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined'
// import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import React, { useRef, useState, ChangeEvent, useEffect, useContext } from 'react'

import './AudioTabs.css'
import { AudioGroup } from '../../assets/images'
// import { audioLists } from '../../constant/TrainingConstant'
import { AuthContext } from '../../context/AuthContext'
import { createAudio, getAudioList } from '../../services/audio.services/audio'
import { updateBookmark } from '../../services/bookmarks.services/bookmark'
import { AudioDetails, AudioPayload } from '../../types/audioTypes'
import { TabPanelProps, TrainingProps, UpdatedSelectedFiles } from '../../types/trainingTypes'
import { deleteStorage, getStorageDate, setStorageData } from '../../utils/localStorage'
import AudioRecorder from '../AudioRecorder/AudioRecorder'
import CustomButton from '../Button'
import { addData } from '../IDB/idb'

const thisStyle = {
  background: '#1322351A',
  borderRadius: '10px 10px 0px 0px',
  border: 'none',
  color: '#FFFFFF66',
  fontWeight: 'bold'
}

function AudioTabPanel (props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="p0 m0"
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps (index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const AudioTabs:React.FC<TrainingProps> = ({ onChangeCallback, order, setOrder }) => {
  const [value, setValue] = React.useState(0)
  const [currentPlayingIndex, setcurrentPlayingIndex] = useState<number | undefined>(0)
  const [audioBlob, setAudioBlob] = useState<AudioPayload>()
  const [ambientSoundtrackList, setAmbientSoundtrackList] = useState<AudioDetails[]>()
  const [audioSizeError, setAudioSizeError] = useState(false)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const inputFileRef = useRef<HTMLInputElement | null>(null)
  const [uploadAudio, setUploadAudio] = useState<UpdatedSelectedFiles>()
  const [selectAudio, setSelectAudio] = useState<{
  label: string;
  url: string;
  id:string
} | null>(null)
  const [audio, setAudio] = useState('')
  const { user, setUser } = useContext(AuthContext)

  const formStage = 'download'
  const onUploadFileClick = () => {
    if (!inputFileRef || !inputFileRef.current) {
      return
    }
    inputFileRef.current.click()
  }

  const onUploadFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setAudioBlob({ audio: e.target.files[0] })
      const fileName = e.target.files[0].name
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.addEventListener('load', () => {
        setAudio(reader.result as string)
      })
      setUploadAudio({ label: fileName, url: URL.createObjectURL(e.target.files[0]) })
    }
  }
  const onAudioChange = () => {
    deleteStorage('selectedAudio')
    setSelectAudio(null)
  }
  const onSelect = (label: string, url: string, id:string) => {
    setSelectAudio({ label, url, id })
  }
  const onAudioSelect = async () => {
    if (selectAudio?.url) {
      await addData({ id: 2, audio: selectAudio.url })

      setStorageData('background_audio_id', selectAudio.id)
      const bookmarkData = await updateBookmark('', getStorageDate('background_audio_id') || '')
      setUser({ ...user, videoBookmark: bookmarkData.data.data })
      if (setOrder) {
        setOrder(7)
      }
    }
    onChangeCallback(formStage, true, true)
  }

  const audioUpload = async (file:AudioPayload) => {
    try {
      const resp = await createAudio(file)
      if (resp) {
        setStorageData('background_audio_id', resp.data.data.id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const skipAudio = async () => {
    await addData({ id: 2, audio: '' })
    const bookmarkData = await updateBookmark('', '')
    setUser({ ...user, videoBookmark: bookmarkData.data.data })
    if (setOrder) {
      setOrder(7)
    }
    onChangeCallback(formStage, true, true)
  }

  const onUploadSave = async () => {
    if (audio) {
      if (audioBlob) {
        audioUpload(audioBlob)
      }
      const base64Data = audio.split(',')[1]

      // Convert the Base64 string to binary data
      const binaryData = atob(base64Data)

      // Calculate the size of the binary data (in bytes)
      const fileSizeBytes = binaryData.length

      const fileSizeMB = (fileSizeBytes / 1024) / 1024 // Size in MB
      if (fileSizeMB <= 7) {
        await addData({ id: 2, audio })
        const bookmarkData = await updateBookmark('', getStorageDate('background_audio_id') || '')
        setUser({ ...user, videoBookmark: bookmarkData.data.data })
        if (setOrder) {
          setOrder(7)
        }
        onChangeCallback(formStage, true, true)
      } else {
        setAudioSizeError(true)
      }
    }
  }

  const featchAudio = async () => {
    try {
      const resp = await getAudioList()
      setAmbientSoundtrackList(resp.data.data)
    } catch (error:any) {
    }
  }

  useEffect(() => {
    featchAudio()
  }, [])

  return (
    <Box sx={{ maxWidth: '487px', width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& active': {
            color: '#000000'
          }
        }}
      >
        <Tabs
          sx={{
            '& .MuiTabs-indicator': { display: 'none' },
            '& .Mui-selected': {
              background: '#1322354D',
              color: '#ffffff !important'
            }
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab sx={thisStyle} label="Audio Library" {...a11yProps(0)} />
          <Tab sx={thisStyle} label="Upload File" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <AudioTabPanel value={value} index={0}>
        {!selectAudio
          ? (
          <div className="audio-library-container">
            <div className="audio-library-inner">
              {ambientSoundtrackList?.map((item, index) => (
                <div key={item.id} className="audio-library-individual">
                  <AudioRecorder
                  indexId={index}
                  setcurrentPlayingIndex={setcurrentPlayingIndex}
                    audioUrl={item.url}
                    currentStatus={index === currentPlayingIndex}
                    requireRecordDelete={false}
                    height={20}
                    audioTitle={item.name}
                    width="140px"
                    iswhite={true}
                  />
                  <CustomButton
                    buttonText="SELECT"
                    eventHandle={() => {
                      onSelect(item.name, item.url, item.id)
                    }}
                    size="sm"
                    width="54"
                    fontSize="8px"
                  />
                </div>
              ))}
            </div>
          </div>
            )
          : (
          <>
            <AudioRecorder
              audioUrl={selectAudio?.url || JSON.parse(getStorageDate('selectedAudio') || '')?.url}
              requireRecordDelete={false}
              height={20}
              audioTitle={selectAudio?.label || JSON.parse(getStorageDate('selectedAudio') || '')?.label}
              iswhite={true}
            />
            <div className='finish-mob-wrapper mbo-center' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
            <CustomButton
              buttonText="CHANGE"
              eventHandle={() => {
                onAudioChange()
              }}
              size="sm"
            />
            <CustomButton
              buttonText="SELECT"
              eventHandle={() => {
                onAudioSelect()
              }}
              size="sm"
            />
              </div>
              <div className='mbo-center'>

                  <CustomButton
                buttonText="SKIP"
                eventHandle={() => {
                  skipAudio()
                }}
                size="sm"
              />
              </div>

            </div>

          </>
            )}
      </AudioTabPanel>
      <AudioTabPanel value={value} index={1}>
        <div className="upload-audio__container">
          {!uploadAudio
            ? (
            <img srcSet={AudioGroup} className="training-audio-placeholder" />
              )
            : (
            <AudioRecorder
              audioUrl={uploadAudio.url}
              requireRecordDelete={false}
              height={20}
              audioTitle={uploadAudio.label}
              iswhite={true}
            />
              )}
          <div className="upload-audio__btn">
            <input
              type="file"
              id="file"
              accept=".mp3, .wav, .aac"
              ref={inputFileRef}
              style={{ display: 'none' }}
              onChange={onUploadFileChange}
            />
            <div className='finish-mob-wrapper' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
            <CustomButton
              buttonText="BROWSE"
              eventHandle={() => {
                onUploadFileClick()
              }}
              width="121"
              size="sm"
              isTransparent={true}
            />
            <CustomButton buttonText="SAVE" eventHandle={() => onUploadSave()} size="sm" />
              </div>
<div className='mbo-center'>

              <CustomButton buttonText="SKIP" eventHandle={() => skipAudio()} size="sm"/>
</div>

            </div>

            { audioSizeError && <p style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>File size must be less than or equal to 7 MB.</p> }
          </div>
        </div>
      </AudioTabPanel>
    </Box>
  )
}

export default AudioTabs
