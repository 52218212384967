import { ApiResponse } from '../../types'
import { PaymentMethodDeleteResponse, PaymentMethodResponse, Subscription, UserSubscriptionStatus } from '../../types/accountTypes'
import { api } from '../api'

export const sendPaymentId = async (paymentMethodId:string) => {
  return api.post<ApiResponse<PaymentMethodResponse>>('/payment-methods', { paymentMethodId })
}
export const updatePaymentId = async (id:string, paymentMethodId:string) => {
  return api.patch<ApiResponse<PaymentMethodResponse>>(`/payment-methods/${id}`, { paymentMethodId })
}
export const updatePaymentMethod = async (paymentMethodId:string) => {
  return api.patch<ApiResponse<PaymentMethodResponse>>(`/payment-methods/${paymentMethodId}`)
}
export const getPaymentDetails = async () => {
  return api.get<ApiResponse<PaymentMethodResponse>>('/payment-methods')
}
export const createSubscription = async (couponCode?:string) => {
  return api.post<ApiResponse<Subscription>>('/subscriptions', { couponCode })
}
export const getSubscriptionDetails = async () => {
  return api.get<ApiResponse<PaymentMethodResponse>>('/subscriptions/details')
}
export const cancelSubscription = async (cancelType: string) => {
  return api.post<ApiResponse<Subscription>>('/subscriptions/cancel', { cancelType })
}
export const subscriptionStatus = async () => {
  return api.get<ApiResponse<UserSubscriptionStatus>>('/subscriptions/status')
}
export const subscriptionRenew = async (couponCode?:string) => {
  return api.post<ApiResponse<Subscription>>('/subscriptions/renew', couponCode && { couponCode })
}
export const deletePayment = async (paymentMethodId:string) => {
  return api.delete<ApiResponse<PaymentMethodDeleteResponse>>(`/payment-methods/${paymentMethodId}`)
}
