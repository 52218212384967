import React from 'react'

import Video from '../../components/video/7C/video'

const Cues = () => {
  // const body = 'Once you are clear on your dreams, you will then create create two CUES for each dream--one that you hear and one that you see. You will do this by creating an audio recording of yourself saying each manifest and finding a picture that represents each dream.'
  return (
    <Video name='cues.mp4' title='Cues' body={''} />
  )
}

export default Cues
