export const useAuth = (data: any) => {
  if (!data?.is_subscribe) {
    return {
      unsubscribed: true
    }
  }
  if (data?.is_subscribe && !data?.is_training_completed) {
    return {
      notTrained: true
    }
  }

  return {
    unsubscribed: false,
    notTrained: false
  }
}
