import { Button, Card, CardContent, Grid } from '@mui/material'
import React from 'react'

import { setStorageData } from '../../utils/localStorage'
import Text from '../Text/Text'

interface PaymentSetUpProps {
  handleStepChange: (step: string, inputs?: any) => void;
}

const PaymentSetUp: React.FC<PaymentSetUpProps> = ({ handleStepChange }) => (
  <>
    <Text preset='eyebrow' text="Last Step" style={{ textAlign: 'center', marginBottom: 0 }} />
    <Text preset="h1" text="Join Our Community" style={{ textAlign: 'center', marginTop: '0' }} />
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={5}>
        <Card className="dreambig-card">
          <CardContent style={{ textAlign: 'center', paddingBottom: '0px', color: '#FFF' }}>
            <Text preset="paragraph" text="DreamBig Online Platform" style={{ maxWidth: '120px', textAlign: 'center', color: '#FFF', lineHeight: '20px', margin: '0 auto' }}/>
            <Text preset="h2" text="10$" style={{ color: '#FFF', margin: '10px 0 0 0' }} />
            <Text preset="paragraph" text="/per month" style={{ marginTop: 0, color: '#FFF' }}/>
            <hr className="dreambig-hr" />
          </CardContent>
          <CardContent style={{ paddingTop: '0px' }}>
            <Text preset="paragraph" text="This powerful proprietary platform gives you a place to create, store, edit and review your dreams" style={{ color: '#FFF' }} />
            <Button
              size="large"
              className="dreambig-button"
              onClick={() => {
                setStorageData('step', 'payment_form')
                handleStepChange('payment_form')
              }
              }
            >Sign Up</Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={5}>
        <Card className="online-community-card">
          <CardContent style={{ paddingBottom: '0px' }}>
            <div className="online-community-heading">
              <Text preset="h4" text="No Thanks, I’ll Just Join the Online Community For Now" style={{
                fontSize: '18px',
                lineHeight: '26px',
                margin: '5px 0'
              }}/>
            </div>
            <hr className="online-community-hr" />
          </CardContent>
          <CardContent style={{ paddingTop: '0px' }}>
            <Text preset="paragraph" text="An online crew of passionate creators and dreamers inside a private, interactive social platform." style={{ lineHeight: '28px', margin: '0 auto', maxWidth: '215px', color: '#535353' }}/>
            <Button
              size="large"
              className="online-community-button"
              onClick={() => handleStepChange('payment_form')}
            >Continue</Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>
)

export default PaymentSetUp
