import { Box, Grid } from '@mui/material'
import React, { useContext, useState } from 'react'

// import { EditIcon } from '../../assets/images/icons'
import './ProfileAccount.css'

import { useNavigate } from 'react-router-dom'

import visa from '../../assets/images/icons/visa.svg'
import BasicModal from '../../components/BasicModal/BasicModal'
import CustomButton from '../../components/Button'
import ButtonComponent from '../../components/FormComponent/ButtonComponent'
import CommonSkeleton from '../../components/Skeleton'
import Text from '../../components/Text/Text'
import { AccountContext } from '../../context/AccountContext'
import { AuthContext } from '../../context/AuthContext'
import { SubscriptionContext } from '../../context/SubscriptionContext'
import { formatDate } from '../../utils/utils'
import PaymentTable from './PaymentTable'

const ProfileAccount = () => {
  const [paymentMethodCancelModal, setPaymentMethodCancelModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { accountData, loadingData } = useContext(AccountContext)
  const { subscriptionData, cancelSubscriptionAtPeriodEnd, cancelSubscriptionAtImmediately, deletePaymentMethod, userSubscriptionStatus } = useContext(SubscriptionContext)
  const { user } = useContext(AuthContext)

  const handleAccountEdit = async () => {
    setIsModalOpen(true)
    setPaymentMethodCancelModal(true)
  }

  const handleSubscribeModal = async (e: React.MouseEvent) => {
    e.preventDefault()
    setIsModalOpen(true)
    setPaymentMethodCancelModal(false)
  }

  const handleCancel = async () => {
    if (!paymentMethodCancelModal) {
      await cancelSubscriptionAtImmediately()
    } else {
      await deletePaymentMethod()
      setIsModalOpen(false)
    }
  }

  const handlePeriodEnd = async () => {
    await cancelSubscriptionAtPeriodEnd()
    setIsModalOpen(false)
  }

  const handleUpdate = () => {
    navigate('/renew-paymentMethod')
  }
  if (loadingData) {
    return (
      <>
        <CommonSkeleton count={2} height={70} style={{ marginBottom: '5px', width: '800px' }} />
      </>
    )
  }

  return (
    <Box sx={{ maxWidth: '800px', padding: '30px' }}>
      <form >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} xl={12} md={12} >
            {/* <div className='account__wrapper edit-btn' onClick={() => { handleAccountEdit() }}><img src={EditIcon} /> <Text preset='paragraph' text="Edit" style={{ lineHeight: '10px', color: '#5E7689' }} /></div> */}
          </Grid>
          {/* <Grid item xs={12} sm={12} xl={12} md={12}>
              <Text preset='paragraph' text="PLAN INFO" style={{ lineHeight: '10px', color: '#132235', letterSpacing: '0.5em' }} />
              </Grid> */}

          <Grid item xs={12} sm={4} xl={4} md={4}>

            <div className='account__wrapper'><Text preset='paragraph' text="Billing Frequency" style={{ lineHeight: '10px', color: '#5E7689' }} /><Text preset='paragraph' text={accountData?.data?.subscription?.interval as string} style={{ lineHeight: '10px', color: '#132235' }} /></div>
          </Grid>
          <Grid item xs={12} sm={4} xl={4} md={4}>
            <div className='account__wrapper'><Text preset='paragraph' text="Account Coupon" style={{ lineHeight: '10px', color: '#5E7689' }} /><Text preset='paragraph' text={accountData?.data?.subscription?.coupon as string} style={{ lineHeight: '10px', color: '#132235' }} /></div>
          </Grid>
          <Grid item xs={12} sm={4} xl={4} md={4}>
            {
              subscriptionData?.status ===
                'cancelling'
                ? <>{`Your Subscription Will Cancelled  at ${formatDate(subscriptionData?.cancelled_at!)}`} </>
                : <CustomButton eventHandle={(e) => handleSubscribeModal(e)} buttonText='Cancel' />
            }

          </Grid>

          <BasicModal
            isOpen={isModalOpen}
            closeHandler={() => setIsModalOpen(false)}
            okHandler={() => {
              handleCancel()
            }}
            cancelHandler={() => setIsModalOpen(false)}
            header="Cancel Your Subscription"
            body="Confirm this action before proceeding"
            labelOk="Cancel Now"
            {...((!paymentMethodCancelModal && subscriptionData?.status !==
              'cancelling') && {
              okHandler2: () => handlePeriodEnd(),
              labelOk2: 'Cancel Period End'
            })}
            labelCancel='Back'
          />
          {
            (!userSubscriptionStatus?.isCouponApplied && user?.hasPaymentMethod)
              ? <>
                <Grid item xs={12} sm={12} xl={12} md={12}>
                  <Text preset='paragraph' text="PAYMENT" style={{ lineHeight: '10px', color: '#5E7689' }} />
                </Grid>
                <Grid item xs={12} sm={6} xl={6} md={6} marginBottom={5}>
                  <img src={visa} alt="" />
                  {/* <div className='account__wrapper' onClick={() => { handleAccountEdit() }}><Text preset='paragraph' text="Visa Ending in 6679" style={{ lineHeight: '10px', color: '#132235' }} /></div> */}
                </Grid>
                <Grid item xs={12} sm={6} xl={6} md={6}>
                  <div className='account__wrapper' ><ButtonComponent eventHandle={handleAccountEdit} successHandler={handleUpdate} titleSuccess='UPDATE' titleUnsuccess='REMOVE' /></div>
                </Grid>
              </>
              : <>   <CustomButton eventHandle={() => navigate('/renew-subscription')} buttonText='Add Payment Method' /></>
          }

          <Grid item xs={12} sm={12} xl={12} md={12}>
            <Text preset='label' text="History" style={{ lineHeight: '10px', color: '#132235', fontWeight: 'bold' }} />
          </Grid>
          <Grid item xs={12} sm={12} xl={12} md={12}>
            <div className='account__wrapper'>

              <PaymentTable data={accountData?.data} />
            </div>
          </Grid>
        </Grid>
      </form>
    </Box>

  )
}

export default ProfileAccount
