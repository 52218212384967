import { Box, Typography } from '@mui/material'
import React from 'react'

import inspIcon from '../../assets/images/icons/insprationIcon.png'
import { sparkWordexample, sparkWordText } from '../../constant/TrainingConstant'
import { TrainingProps } from '../../types/trainingTypes'
import { setStorageData } from '../../utils/localStorage'
import CustomButton from '../Button'

const SparkWord: React.FC<TrainingProps> = ({ handleFormCallback, onChangeCallback, data, order, indexedDBdata }) => {
  const [isDisable, setIsDiasble] = React.useState(indexedDBdata?.title.length === 0)

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.target?.value.length > 0 ? setIsDiasble(false) : setIsDiasble(true)
    if (handleFormCallback) {
      handleFormCallback(e)
    }
  }
  const handleForm: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const val = e.currentTarget.id
    const backStep = e.currentTarget.dataset.backstep === 'true'
    setStorageData('step', val)
    onChangeCallback(val, false, false, backStep)
  }

  return (
    <>
      <Typography color="white">
        {sparkWordText(order)}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', margin: '35px 0' }}>
        <div>
          <img src={inspIcon} alt="" />
        </div>
        <Typography sx={{ color: '#fff', marginLeft: '25px' }}>{`This could be a word like ${sparkWordexample.find((d) => d.order === order)?.text}`}</Typography>
      </Box>
      <div >
        <textarea name="" className="text-area manifest-show" placeholder='Manifeast Statement' value={indexedDBdata?.manifest} disabled></textarea>
        <div className='spark-work-inputs' style={{ display: 'flex', width: '100%', marginTop: '30px' }}>
          <input type="text" className='custom-input' onChange={handleChange} placeholder="Spark Word" id="title" value={data?.title} />

          <div className='mbo-center'>
            <CustomButton eventHandle={handleForm} buttonText="BACK" isTransparent={true} formStage='start' isBackStep={true} />
            <CustomButton eventHandle={handleForm} buttonText="SUBMIT" formStage='auditory_cue' isDisable={isDisable} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SparkWord
