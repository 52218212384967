import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import { SkeletonProps } from '../../types/Skeleton'

const CommonSkeleton = (props:SkeletonProps) => {
  const { count, baseColor, highlightColor, circle, width, height, style } = props
  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
         <Skeleton count={count} circle={circle} width={width} height={height} style={style} />
    </SkeletonTheme>
  )
}

export default CommonSkeleton
