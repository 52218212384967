import { Avatar, Grid, Button } from '@mui/material'
import { useFormik } from 'formik'
import React, { ChangeEvent } from 'react'

import { setStorageData } from '../../utils/localStorage'
import Text from '../Text/Text'
import { imageValidationSchema } from './AuthValidation'

interface InitialValues {
  files: File | null
}

interface ImageUploaderProps {
  handleStepChange: (step: string, inputs?: any) => void
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ handleStepChange }) => {
  const initialValues: InitialValues = {
    files: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: imageValidationSchema,
    onSubmit: (values) => {
      setStorageData('step', 'payment_setup')
      handleStepChange('payment_setup')
    }
  })

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      formik.setFieldValue('files', file)
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files?.[0]
    if (file) {
      formik.setFieldValue('files', file)
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Text preset='eyebrow' text="Say Cheese" style={{ textAlign: 'center', marginBottom: 0 }} />
        <Text preset="h1" text="Upload a Photo" style={{ textAlign: 'center', marginTop: '0' }} />
        <input
          accept="image/*"
          id="image-input"
          type="file"
          onChange={handleImageChange}
          hidden
        />
        <div
          className='drop-area'
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label htmlFor="image-input">
            {formik.values.files
              ? (
              <Avatar
                alt="Selected Image"
                src={URL.createObjectURL(formik.values.files)}
                className="avatar"
              />
                )
              : (
              <>
                <p className="text-center">CLICK TO UPLOAD OR DRAG PHOTO HERE</p>
              </>
                )}
          </label>
        </div>
        {formik.errors.files && (
          <div className="error light-red mt-10 text-center">{formik.errors.files}</div>
        )}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={5}>
            <Button
              size="large"
              className="skip-button"
              onClick={() => {
                setStorageData('step', 'payment_form')
                handleStepChange('payment_form')
              }
              }
            >
              Skip Upload
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              size="large"
              type="submit"
              className="continue-button"
              disabled={!formik.isValid}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default ImageUploader
