import * as React from 'react'

import Logo from '../../../assets/images/icons/pdf_logo.png'
import { ShipItemsPdfData } from '../../../types'

import './index.css'
interface ShipItemPngProps {
  manifestRequired: Boolean
  shipData: ShipItemsPdfData[]
  user: {
    first_name: string,
    last_name: string,
    image_url: string
  }
}

const PdfColor = {
  Health: {
    topColor: '#FFFFFF',
    background: '#E5F2F6',
    color: '#132235'
  },
  Self: {
    topColor: '#E5F2F6',
    background: '#c8e4ec',
    color: '#132235'
  },
  Play: {
    topColor: '#c8e4ec',
    background: '#60748c',
    color: '#FFFFFF'
  },
  Passion: {
    topColor: '#60748c',
    background: '#384c5c',
    color: '#FFFFFF'
  },
  Wealth: {
    topColor: '#384c5c',
    background: '#182434',
    color: '#FFFFFF'
  }
}

type titleType = 'Health' | 'Wealth' |'Self' | 'Passion' | 'Play'

const ShipItemPng: React.FC<ShipItemPngProps> = (props) => {
  const { manifestRequired, user, shipData } = props
  // @ts-ignore
  return (
    <div style={{ width: '98%', margin: '0 auto' }}>
      <div id="top-border"></div>
      <div id="content-header">
        <div><img id="header-logo" src={Logo} /></div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div id="header-text">
            <p id="header-subject">{manifestRequired ? 'Dream Design Overview' : 'Dream Board'}</p>
            <p id="header-user-title">{user && `${user.first_name} ${user.last_name}`}</p>
          </div>
          {
            (user && user.image_url) && (
              <img id="header-user-image" src={user.image_url} />
            )
          }
        </div>
      </div>
      {
        shipData.map((data, index) => {
          const { title, shipItems } = data
          const seaShipData = shipItems.filter(item => item.status === 'at_sea')
          return (
            <div style={{ backgroundColor: PdfColor[title as titleType].topColor }} className="ship-item-wrapper" key={index}>
              <div className="title-wrapper" style={{
                backgroundColor: PdfColor[title as titleType].background
              }}>
                <p style={{ fontWeight: 'normal', color: PdfColor[title as titleType].color, margin: '0px' }}>
                  { title }
                </p>
              </div>
              <div style={{
                backgroundColor: PdfColor[title as titleType].background
              }} className="ship-content-wrapper">
                {
                  seaShipData.filter((d: any) => d.assets.find((s: any) => s.model_name === 'SHIP-ITEM-IMAGE')).map((item: any, index: number) => {
                    return (
                      <>
                        <div
                        >
                          <div className='image-wrapper-pdf' style={{
                            height: manifestRequired ? '10px' : '20px',
                            backgroundImage: `url(${item.assets.find((d: any) => d.model_name === 'SHIP-ITEM-IMAGE').url})`
                          }}>
                            <div className="ship-item-title-wrapper">
                              <p className='ship-item-title'>{ item.title }</p>
                            </div>
                          </div>
                          {
                            manifestRequired
                              ? <p className='ship-manifest png-width-preview' style={{
                                color: PdfColor[title as titleType].color
                              }}>{ item.manifest }</p>
                              : null
                          }
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ShipItemPng
