import { Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'

import { setStorageData } from '../../utils/localStorage'
import AudioRecorder from '../AudioRecorder/AudioRecorder'
import '../EditShipPopup/EditShipPopup.css'
import CustomButton from '../Button'
import Text from '../Text/Text'
import { TrainingProps } from '../../types/trainingTypes'
import { auditoryText } from '../../constant/TrainingConstant'

import RecordPlugin from 'wavesurfer.js/dist/plugins/record.js'

const AuditoryCue : React.FC<TrainingProps> = ({ onChangeCallback, handleCustomFormCallback, order, indexedDBdata }) => {
  const [audioUrl, setAudioUrl] = useState<String>('')
  const handleForm = (e: any) => {
    const val = e.currentTarget.id
    const backStep = e.currentTarget.dataset.backstep === 'true'
    setAudioUrl('')
    setStorageData('step', val)
    onChangeCallback(val, false, false, backStep)
  }
  const updateFormDataWithAudio = (audioUrl: string, blob: Blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.addEventListener('load', () => {
      if (handleCustomFormCallback) {
        handleCustomFormCallback('audio', reader.result as string)
      }
      setAudioUrl(audioUrl)
    })
  }

  const handleRecordDestroy = (record:RecordPlugin) => {
    record.destroy()
    setAudioUrl('')
  }
  const dotAfterConcat = indexedDBdata!.title.length > 15 ? '...' : ''

  return (
    <>
      <Typography color="white">
        {auditoryText(order)}
      </Typography>
      <div className="image-upload__spark-word">
      <Tooltip title={indexedDBdata?.title}>
       <Text preset='paragraph' text={ indexedDBdata?.title.substr(0, 15) + dotAfterConcat } style={{ color: '#fff', fontWeight: '700', margin: 0 }} />
        </Tooltip>

      </div>
      <textarea name="" className="text-area text-area__disabled" placeholder='Manifeast Statement' disabled>
        { indexedDBdata?.manifest }
      </textarea>
      <div className='audio-mob-wrapper' style={{ display: 'flex', alignItems: 'center' }}>
        {
          indexedDBdata?.audio.length > 0
            ? <AudioRecorder audioUrl={indexedDBdata?.audio}
                                                                     iswhite={true}
                                                                     requireRecordDelete={true}
                                                                     height={20}
                                                                     onRecordedAudio={(audioUrl: string, audioBlob: Blob) => updateFormDataWithAudio(audioUrl, audioBlob)}
                                                                     onDestroy={
                                                                       (record) => handleRecordDestroy(record)
                                                                     }
          />
            : <AudioRecorder audioUrl={null}
                              iswhite={true}
                              requireRecordDelete={true}
                              height={20}
                              onRecordedAudio={(audioUrl: string, audioBlob: Blob) => updateFormDataWithAudio(audioUrl, audioBlob)}
                              onDestroy={
                                (record) => handleRecordDestroy(record)
                              }
          />
        }
        <div className='mbo-center'>
          <CustomButton eventHandle={handleForm} buttonText="BACK" isTransparent={true} formStage="spark_word" isBackStep={true}/>
          <CustomButton eventHandle={handleForm} buttonText="SUBMIT" size="sm" formStage="image_upload_step" isDisable={!(indexedDBdata?.audio?.length > 0 || audioUrl.length > 0)} />
        </div>
      </div>
    </>
  )
}

export default AuditoryCue
