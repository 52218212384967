import React, { useState } from 'react'

import './index.css'
import { contactUs } from '../../services/users.service/updateUser'
import { notifyUser } from '../../utils/notify'

import CircularProgress from '@material-ui/core/CircularProgress'

export interface DefaultValues {
  name: string
  email: string
  body: string
}

const defaultValues: DefaultValues = {
  name: '',
  email: '',
  body: ''
}

export interface Props {
  setOpenContact: (val: boolean) => void;
}

const ContactUs:React.FC<Props> = ({ setOpenContact }) => {
  const [values, setValues] = useState<DefaultValues>(defaultValues)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    try {
      setLoading(true)
      await contactUs(values)
      notifyUser('Your Request for Help has been sent to the DreamBig Tribe Team.', 'success')
    } catch (e: any) {
      console.log(e)
    }
    setOpenContact(false)
    setLoading(false)
  }

  const handleChange = (e: any) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      }
    })
  }

  return (
    <>
      <div className='contact-from-wrapper'>
        <form onSubmit={handleSubmit}>
          <p style={{ margin: '0' }}>Name</p>
          <input type="text" name="name" id="" className='input-area' style={{ width: '93%' }} onChange={handleChange} required/>
          <p style={{ margin: '0' }}>Email</p>
          <input type="email" name="email" id="" className='input-area' style={{ width: '93%' }} onChange={handleChange} required/>
          <p style={{ margin: '0' }}>Body</p>
          <textarea name="body" id="" className='input-area' style={{ width: '93%', height: '100px' }} onChange={handleChange} required></textarea>
          <div>
            <button className='custom-btn' style={{ borderRadius: '5px', background: '#e5e5e5e5', marginLeft: '0px' }} type='submit' disabled={loading}>{
              loading ? <CircularProgress size='15px' /> : 'Send'
            }</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ContactUs
