import React, { useState } from 'react'

import { updateUserDetails } from '../../services/users.service/updateUser'
import { CloseSVG } from '../../utils/svgs'
import CustomButton from '../Button'
import DreamDesignDownload from '../Downloads/DREAMDESIGN'

const DownloadSection = () => {
  const [open, setOpen] = useState(false)
  const handleForm = () => {
    setOpen(!open)
  }
  const handleShowModal = () => {
    setOpen(!open)
  }
  const handleNavigate = async () => {
    try {
      await updateUserDetails({
        is_training_enabled: true
      })
      window.location.replace('training-completed')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/* <Text preset="paragraph" text={trainingDesc} style={{ marginTop: '0', marginBottom: '20px', lineHeight: 'normal', color: '#ffffff', fontFamily: 'Montserrat' }} /> */}
      <div >
        <div className='download-mob-wrapper' style={{ display: 'flex', width: '100%', marginTop: '30px' }}>
          <div>
            <CustomButton eventHandle={handleForm} buttonText="Downloads" />
          </div>

          <div className='popup-download' style={{
            display: open ? 'block' : 'none',
            top: '65px'
          }}>
            <div className='popup-content'>
              <div className='edit-ship__close-btn' onClick={handleShowModal}><CloseSVG /></div>
              <div className='popup-header'>
                <div className="popup-title">
                  <div className='header-main__title'>
                    DREAM DESIGN
                  </div>
                  <div className='header-sub__title'>
                    My Files
                  </div>
                </div>
              </div>
              <div className="popup-body">
                <DreamDesignDownload audioRequired={false} />
              </div>
            </div>
          </div>
          <div>
            <CustomButton eventHandle={handleNavigate} buttonText="Complete Your Journey" />
          </div>
        </div>
      </div>
    </>
  )
}

export default DownloadSection
