import { Grid, Button, TextField, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { forgetPasswordSchema } from '../../components/Register/AuthValidation'
import Text from '../../components/Text/Text'
import { forgetPasswordConst } from '../../constant/ForgetPassword'
import { forgetPassword } from '../../services/authentication.service/signup'
import { notifyUser } from '../../utils/notify'

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isMailSend, setIsMailSend] = useState(false)
  const navigate = useNavigate()
  const { values, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: (values) => {
      setIsLoading(true)
      window.scrollTo(0, 0)
      const { email } = values
      forgetPassword(email).then((response) => {
        setIsLoading(false)
        setIsMailSend(true)
      }).catch((err) => {
        setIsLoading(false)
        if (err?.response?.data?.error) {
          if (err?.response?.status === 417) {
            notifyUser(err.response.data.error.message, 'danger')
          } else {
            notifyUser('Something went Wrong', 'danger')
          }
        }
      })
    }
  })

  const handleAlreadyAcc = () => {
    navigate('/login')
  }
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    {
      isMailSend
        ? <div style={{ textAlign: 'center' }}>
        <p>{forgetPasswordConst.mailSentTitle}</p>
        <div style={{ padding: '10px 15px', backgroundColor: '#2D3C50', borderRadius: '5px', color: '#fff', fontWeight: 'bold' }}>{forgetPasswordConst.mailSent}</div>
         </div>
        : <form onSubmit={handleSubmit}>
      <Text preset="h1" text="Forgot Password" style={{ textAlign: 'center', marginTop: '0' }} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Text preset='label' text="Email" />
          <TextField fullWidth value={values.email} onChange={handleChange} name="email" error={!!(touched.email && errors.email)}
            helperText={touched.email && errors.email} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" className="mt-10">
        <Button
          size="large"
          type="submit"
          className="form-submit-button"
          disabled={isLoading}
        >
          {
            isLoading ? <CircularProgress /> : 'Submit'
          }
        </Button>
      </Grid>
      <Grid container justifyContent="center" className="mt-10">
        <span onClick={handleAlreadyAcc}>
          <Text preset='paragraph' text="Already have account? " style={{ cursor: 'pointer' }} />
        </span>
      </Grid>
    </form>
    }

    </>
  )
}

export default ForgetPassword
