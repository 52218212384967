import React from 'react'

const OnlinePlatform = () => {
  return (
    <div>
      <h2>Online Platform</h2>
    </div>
  )
}

export default OnlinePlatform
