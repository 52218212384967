import { Box, Typography } from '@mui/material'
import React from 'react'

import inspIcon from '../../assets/images/icons/insprationIcon.png'
import { manifestDesc } from '../../constant/TrainingConstant'
import { TrainingProps } from '../../types/trainingTypes'
import { setStorageData } from '../../utils/localStorage'
import CustomButton from '../Button'

const ManifeastStatement: React.FC<TrainingProps> = ({ handleFormCallback, onChangeCallback, data, order, shipData, handleInspiration, indexedDBdata }) => {
  const handleForm:React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const val = e.currentTarget.id
    const backStep = e.currentTarget.dataset.backstep === 'true'
    setStorageData('step', val)
    onChangeCallback(val, false, false, backStep)
  }

  const handleShowInspiration = () => {
    handleInspiration()
  }

  return (
        <>
            <Typography color="white">
              {manifestDesc(order, shipData)}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', margin: '35px 0' }}>
                <div>
                    <img src={inspIcon} alt="" />
                </div>
                <button style={{ textDecoration: 'underline', color: '#fff', marginLeft: '25px', background: 'transparent', border: 'none', cursor: 'pointer' }} onClick={handleShowInspiration} >Need Some Inspiration?</button>
                {/* <Typography sx={{ textDecoration: 'underline', color: '#fff', marginLeft: '25px' }}>Need Some Inspiration?</Typography> */}
            </Box>
            <div className='manifest-statement-text-area-wrapper' >
                <textarea name="" className="text-area manifest-show" placeholder="Manifest Statement" value={indexedDBdata?.manifest} disabled id='manifest' maxLength={100} />
                <div className="manifest-statement-text-area-wrapper-btns">
                <CustomButton eventHandle={handleForm} buttonText="BACK" isTransparent={true} formStage="start" isBackStep={true}/>
                <CustomButton eventHandle={handleForm} buttonText="SUBMIT" formStage="spark_word" />
                </div>

            </div>
        </>
  )
}

export default ManifeastStatement
