import { Typography, Button, CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import { manifestReviewDesc, ShipItemLimit } from '../../constant/TrainingConstant'
import { AuthContext } from '../../context/AuthContext'
import { ShipContext } from '../../context/ShipContext'
import { updateTrainingCompleted } from '../../services/users.service/getUser'
import { TrainingProps } from '../../types/trainingTypes'
import { deleteStorage, getStorageDate, setStorageData } from '../../utils/localStorage'
import AudioRecorder from '../AudioRecorder/AudioRecorder'
import Text from '../Text/Text'

const ManifestReview : React.FC<TrainingProps> = ({ onChangeCallback, setTrainingFormData, order, setOrder, setShowModal, indexedDBdata }) => {
  const [audio, setAudio] = useState<Blob>()
  const [image, setImage] = useState<File | string>()

  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const { setUser } = useContext(AuthContext)
  const { createShipItem, getShipItemsByCategory, shipCategories } = useContext(ShipContext)
  const handleDataSubmit = async () => {
    setIsLoading(true)
    const { title, manifest, depositURL } = indexedDBdata!
    const data = {
      title,
      manifest,
      image,
      audio,
      is_training_ship: true,
      depositURL
    }
    const newOrder = order + 1
    const shipId = shipCategories.find(d => d.order === order)?.id
    const response = await createShipItem(shipId as string, data, true)
    if (response) {
      const response = await getShipItemsByCategory(shipId as string, 'at_sea', true)
      if (response!.length < ShipItemLimit) {
        onChangeCallback('start', true)
        setStorageData('step', 'start')
      } else if (response!.length < 5) {
        const shipLimit = Number(getStorageDate('ship_limit')) + 1
        setStorageData('ship_limit', `${shipLimit}`)
        setStorageData('step', 'start')
        onChangeCallback('start', true)
        if (setShowModal) {
          setShowModal(true)
        }
      } else {
        if (newOrder < 6) {
          setStorageData('ship_limit', '3')
          setStorageData('step', 'start')
          onChangeCallback('start', true, true)
        } else {
          const resp = await updateTrainingCompleted()
          setUser(resp?.data?.data)
          deleteStorage('step')
          deleteStorage('ship_limit')
          onChangeCallback('audio_list', true, true)
        }
      }
    }
    setIsLoading(false)
  }

  const getFile = () => {
    const image = indexedDBdata?.image
    if (image.includes('depositphotos.com')) {
      return image
    }

    const base64Data = image.replace(/^data:image\/\w+;base64,/, '')

    // Convert the base64 data to a Blob
    const byteCharacters = atob(base64Data)
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    const blob = new Blob(byteArrays, { type: 'image/jpeg' })

    // Create a File object with the Blob and filename
    const file = new File([blob], 'image.jpg', { type: 'image/jpeg' })
    return file
  }

  useEffect(() => {
    const audio = indexedDBdata?.audio
    fetch(audio).then(res => res.blob())
      .then(audio => setAudio(audio))
    setImage(getFile())
  }, [indexedDBdata])

  const dotAfterConcat = indexedDBdata!.title.length > 20 ? '...' : ''

  return (
    <>
      <Typography color="white">
        {manifestReviewDesc(order)}
      </Typography>
      <div className="image-upload__spark-word">
      <Text preset='paragraph' text={ indexedDBdata?.title.substr(0, 20) + dotAfterConcat } style={{ color: '#fff', fontWeight: '700', margin: 0 }} />
      </div>
      <div className='review-mob-wrapper' style={{ display: 'flex', alignItems: 'end' }}>
        <div className="image-upload__manifest-wrapper final">
          <div style={{ borderRight: '2px solid #ffffff' }} className="image-upload__manifest-wrapper-text final">
            <p style={{
              width: '100%',
              color: '#FFFFFF',
              fontStyle: 'italic',
              fontWeight: '700',
              wordBreak: 'break-word'
            }} > {indexedDBdata!.manifest}</p>
            <AudioRecorder audioUrl={indexedDBdata?.audio}
                           iswhite={true}
                           requireRecordDelete={false}
                           height={20}
                           barWidth={1.5}
                           width={'165px'}
            />
          </div>
          <div>
          <div className="image-upload__manifest-wrapper-background final" style={{ backgroundImage: `url(${indexedDBdata?.image})` }}></div>
          </div>
        </div>
        <div className='mbo-center'>
          {order !== undefined && <Button onClick={handleDataSubmit} className="custom-btn__important" disabled={!!isLoading} sx={{ fontSize: '10px' }}>
            {
              isLoading ? <>Please Wait <CircularProgress size='10px'/></> : 'Submit'
            }
          </Button>
          }
        </div>
      </div>
    </>
  )
}

export default ManifestReview
