import { Avatar, Button } from '@mui/material'
import React from 'react'

interface Props {
  src: any,
  isEdit?: boolean,
  onChange: any
}
const AvatarComponent: React.FC<Props> = (props) => {
  const { src, isEdit, ...other } = props

  return (
    <div>
      {
        isEdit ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-start' }}> <Avatar alt="Remy Sharp" src={src} sx={{ width: 120, height: 120 }} /> <label htmlFor="upload-photo"> <input {...other} style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" /> <Button color="primary" variant="contained" component="span" sx={{ marginLeft: 2 }} > Upload New Photo </Button> </label> </div> : <Avatar alt="Remy Sharp" src={src} sx={{ width: 120, height: 120 }} />
      }
    </div>
  )
}

export default AvatarComponent
