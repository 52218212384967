import React from 'react'

import Text from '../../Text/Text'
interface sidebarProps {
  onClick: React.MouseEventHandler<HTMLAnchorElement>
  location?: string,
  to?: string,
  icon: React.ReactElement,
  title: string
  active: boolean
}

const SidebarTabs = (props: sidebarProps) => {
  const { onClick, location, to, icon, title, active } = props
  return (
    <div>
      <li >
        <a onClick={onClick}
          className={`sidebar__navitem ${location === to ? 'active' : ''} ${!active ? 'inactive__nav-item' : ''}`}>
          <span>
            {icon}
          </span><Text preset='paragraph' text={title} style={{ lineHeight: '10px', color: `${location === to ? '#000000' : '#ffffff'}` }} />
        </a>
      </li>
    </div>
  )
}

export default SidebarTabs
