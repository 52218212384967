import { Box, Grid } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'

import { EditIcon } from '../../../assets/images/icons'
import BasicModal from '../../../components/BasicModal/BasicModal'
import FormComponent from '../../../components/FormComponent'
import AvatarComponent from '../../../components/FormComponent/Avatar'
import ButtonComponent from '../../../components/FormComponent/ButtonComponent'
import { profileValidation } from '../../../components/Register/AuthValidation'
import CommonSkeleton from '../../../components/Skeleton'
import Text from '../../../components/Text/Text'
import { ProfileContext } from '../../../context/profileContext'

interface FormProps {
  setForm: Function
}

const ViewForm: React.FC<FormProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [imageToUpload, setImageToUpload] = useState<string | null>('')
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { profileData, loadingProfile, updateProfile, updateProfilePhoto } = useContext(ProfileContext)
  const [imgfile, setFile] = useState<{image: File}>()
  const userProfileData = {
    first_name: profileData?.first_name || '',
    last_name: profileData?.last_name || '',
    mobile_no: profileData?.mobile_no || ''
  }

  const { values, handleChange, handleSubmit, setValues, errors } = useFormik({
    initialValues: userProfileData,
    validationSchema: profileValidation,
    onSubmit: values => {
      updateProfile(values)
      setIsEdit(false)
    }
  })

  useEffect(() => {
    setValues(userProfileData)
  }, [profileData])

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onloadend = () => {
        setImageToUpload(reader.result as string)
      }
      if (file) {
        reader.readAsDataURL(file)
      }

      setIsModalOpen(true)

      const imageValue = {
        image: file
      }

      setFile(imageValue)
    }
    setIsLoading(false)
  }

  const handleUploadImage = () => {
    if (imgfile) {
      updateProfilePhoto(imgfile)
      setIsModalOpen(false)
    }
    setIsEdit(false)
    setIsLoading(false)
  }

  const handleEdit = () => {
    setIsEdit(true)
  }

  const eventHandle = () => {
    setIsEdit(false)
    setValues(userProfileData)
    setIsModalOpen(false)
  }

  if (loadingProfile) {
    return (
    <>
        <CommonSkeleton height={100} width={100} circle={true} style={{ marginBottom: '5px' }} />
        <CommonSkeleton count={2} height={70} style={{ marginBottom: '5px', width: '800px' }} />
    </>
    )
  }

  return (
    <>
    <div className='profile__info__container edit__view__form___test'>
    <BasicModal
                isOpen={isModalOpen}
                closeHandler={() => setIsModalOpen(false)}
                okHandler={() =>
                  handleUploadImage()
                }
                cancelHandler={() => eventHandle()}
                header="Change Image"
                body="Do you want to change profile image?"
                labelOk="Ok"
                labelCancel='Cancel'
            />
      <Box sx={{
        maxWidth: '800px',
        padding: '30px',
        '@media (max-width: 600px)': {
          padding: '0px'
        }
      }}>
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} xl={6} md={6}>
              {
                isLoading ? <> <CommonSkeleton height={100} width={100} circle={true} /></> : <div> <AvatarComponent src={imageToUpload || profileData?.image_url} isEdit={isEdit} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImage(e)} /> </div>
              }
            </Grid>
            <Grid item xs={12} sm={6} xl={6} md={6}>
              {!isEdit && <div className='edit__wrapper' onClick={() => { handleEdit() }}><img src={EditIcon} /> <Text preset='paragraph' text="Edit" style={{ lineHeight: '10px', color: '#5E7689' }} /></div> }
            </Grid>

            <FormComponent labels="FIRST NAME" value={values.first_name} isEdit={isEdit} name="first_name" handleChange={handleChange} errors={!!(errors.first_name)} helperText={errors.first_name} />
            <FormComponent labels="LAST NAME" value={values.last_name} isEdit={isEdit} name="last_name" errors={!!(errors.last_name)} handleChange={handleChange} helperText={ errors.last_name} />
            <FormComponent labels="PHONE" value={values.mobile_no} isEdit={isEdit} name="mobile_no" handleChange={handleChange} errors={!!(errors.mobile_no)} helperText={ errors.mobile_no}/>
            <FormComponent labels="EMAIL" value={profileData?.email || ''} isEdit={isEdit} disabled={true} name="email" />
            {isEdit ? <ButtonComponent titleSuccess='SUBMIT' titleUnsuccess='CANCEL' eventHandle={eventHandle} /> : ''}
          </Grid>
        </form>
      </Box>
    </div>
    </>
  )
}

export default ViewForm
