/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { Dialog, CircularProgress, Button } from '@mui/material'
import React, { ChangeEvent, MouseEventHandler, useContext, useState } from 'react'

import './EditShipPopup.css'
import { ShipContext } from '../../context/ShipContext'
import { Asset, EditItemDataType, ShipItem, ShipItemParams } from '../../types/ShipTypes'
import { notifyUser } from '../../utils/notify'
import { CloseSVG } from '../../utils/svgs'
import AudioRecorder from '../AudioRecorder/AudioRecorder'
import BasicModal from '../BasicModal/BasicModal'
import CustomButton from '../Button'
import DepositPhotos from '../DepositPhotos'
import Input from '../Input/Input'
import Text from '../Text/Text'

interface Props {
    closeHandler: Function
    editItemData: EditItemDataType
    shipId: string
    isForView?: boolean
    setIsForView?: Function
    isForEdit: (value: EditItemDataType) => void
    setIsOpen?: Function
    iswhite?: boolean
    shipCategoryTitle?:string

}
const EditShipPopup: React.FC<Props> = ({ closeHandler, setIsOpen, editItemData, shipId, isForView, setIsForView, isForEdit, iswhite, shipCategoryTitle }) => {
    const isEdit = !!editItemData
    // get image and to edit in case of edit
    const assetArray = editItemData?.assets
    const imageAsset: Asset | undefined = assetArray ? assetArray.find((asset: Asset) => asset.model_name === 'SHIP-ITEM-IMAGE') : undefined
    const audioAsset: Asset | undefined = assetArray ? assetArray.find((asset: Asset) => asset.model_name === 'SHIP-ITEM-AUDIO') : undefined
    const imageUrl: string | null = imageAsset ? imageAsset?.url : null
    const audioUrl: string | null = audioAsset ? audioAsset?.url : null

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false)
    const [countChar, setCountChar] = useState(false)
    const { createShipItem, editShipItem, deleteShipItem } = useContext(ShipContext)
    const [formData, setFormData] = useState<ShipItemParams>({
        title: isEdit ? editItemData?.title : '',
        manifest: isEdit ? editItemData?.manifest : '',
        image: null,
        audio: null,
        imageURL: '',
        depositURL: ''
    })

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target
        const isManifest = name === 'manifest'
        const isEmptyValue = value.length > 0
        const shouldSetValue = value.length < 100
        if (isManifest && isEmptyValue) {
            setCountChar(false)
        }

        if (isManifest && !shouldSetValue) {
            setCountChar(true)
            const truncatedValue = value.substring(0, 100) // Get the first 100 characters
            setFormData(prevData => ({
                ...prevData,
                [name]: truncatedValue
            }))
        } else {
            setCountChar(false)
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }))
        }
    }

    const updateFormDataWithAudio = (audioUrl: string, audioBlob: Blob): void => {
        setFormData((data) => ({ ...data, audio: audioBlob }))
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            if (!formData.audio && !isEdit) {
                notifyUser('Please record your audio to create a ship.', 'warning')
            } else {
                if (formData.image === null && !(imageAsset?.url)) {
                    notifyUser('Please upload image to continue.', 'danger')
                } else {
                    setIsLoading(true)
                    isEdit ? await editShipItem(shipId, formData, editItemData.id) : await createShipItem(shipId, formData)
                    setIsLoading(false)
                    closeHandler(false)
                }
            }
        } catch (e) {
            setIsLoading(false)
            closeHandler(false)
        }
    }

    const handleShipItemDelete = async () => {
        try {
            if (editItemData?.id) {
                await deleteShipItem(shipId, editItemData?.id)
                setIsModalOpen(false)
                closeHandler(false)
            }
        } catch (error) {

        }
    }
    const closeViewPopup = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        isForEdit(editItemData)
        setIsOpen && setIsOpen(false)
    }
    const editViewPopup = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        setIsForView && setIsForView(false)
        isForEdit(editItemData)
        setIsOpen && setIsOpen(true)
    }

    const handlePhotoDepositPopup = () => {
        setIsPhotoModalOpen(!isPhotoModalOpen)
    }

    return (
        <>
        <Dialog open className='edit-ship__dialog'>
            <BasicModal
                isOpen={isModalOpen}
                closeHandler={() => setIsModalOpen(false)}
                okHandler={() => {
                    handleShipItemDelete()
                }}
                cancelHandler={() => setIsModalOpen(false)}
                header="Delete ship"
                body="Confirm this action before proceeding"
                labelOk="Delete"
                labelCancel='Cancel'
            />

            <div onClick={() => closeHandler(false)} className='edit-ship__close-btn'><CloseSVG /></div>
            <div className='edit-ship__wrapper'>
                <div className='edit-ship-header'>
                    {isForView
                        ? <Text preset="h2" text={'Ship Detail'} style={{ textAlign: 'center', marginTop: '0', marginBottom: '0', lineHeight: '35px', marginRight: '50px' }} />
                        : <>
                            <Text preset="h2" text={isEdit ? 'Edit Ship' : 'Add Ship'} style={{ textAlign: 'center', marginTop: '0', marginBottom: '0', lineHeight: '35px', marginRight: '50px' }} />
                            <div onClick={() => setIsModalOpen(true)}>{isEdit
                                ? <Button
                                    className='deleteBtn'
                                >
                                    <Text preset="smallParagraph" text="Delete" style={{ textAlign: 'center', marginTop: '5px', marginBottom: '0', textTransform: 'uppercase', color: '#AC3131', fontWeight: 600, letterSpacing: '0.5em' }} />
                                </Button>
                                : ' '}</div>
                        </>}

                </div>

                <form onSubmit={handleSubmit} className='edit-ship-form-container'>
                    <div className='edit-ship-inputs-container'>
                        {!isForView
                            ? <Input
                                disabled={isLoading}
                                handler={handleInputChange}
                                name="title"
                                placeholder="Spark Word"
                                text="Spark Word"
                                value={formData.title}
                                required
                            />
                            : <><Text preset="smallParagraph" text="Spark Word" style={{ color: '#222222', letterSpacing: '0.5em', marginTop: '0', marginBottom: '0', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 600, textTransform: 'uppercase' }} />
                                <Text preset="smallParagraph" text={formData.title} style={{ color: '#222222', letterSpacing: '0.2em', marginTop: '0', marginBottom: '0', marginLeft: '10px' }} /></>}
                        {!isForView
                            ? <><Input
                                disabled={isLoading}
                                handler={handleInputChange}
                                inputType="textarea"
                                name="manifest"
                                placeholder="Ship manifest"
                                text="Ship manifest"
                                value={formData.manifest}
                                rows={3}
                                inputCustomStyle='wfill'
                                required
                            />
                   {countChar && <Text preset="smallParagraph" text="Limit Character to 100" style={{ color: '#b2a4a4', letterSpacing: '0.2em', marginTop: '0', marginBottom: '0', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 600, textTransform: 'uppercase' }} /> } </>
                            : <><Text preset="smallParagraph" text="Ship Manifest" style={{ color: '#222222', letterSpacing: '0.5em', marginTop: '0', marginBottom: '0', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 600, textTransform: 'uppercase' }} />
                                <Text preset="smallParagraph" text={formData.manifest} style={{ color: '#222222', letterSpacing: '0.2em', marginTop: '0', marginBottom: '0', marginLeft: '10px' }} /></>}

                    </div>
                    <div className='edit-ship-photo-container d-flex flex-col'>
                        {!isForView
                            ? <>
                                <Text preset="smallParagraph" text="Add/edit photo" style={{ color: '#222222', letterSpacing: '0.5em', marginTop: '0', marginBottom: '0', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 600, textTransform: 'uppercase' }} />
                                <button id='image-input' type='button' onClick={handlePhotoDepositPopup} style={{ backgroundColor: 'transparent', border: 'none' }}></button>

                                <div
                                    className='edit-ship-drop-area'
                                >
                                    <label htmlFor="image-input">
                                        {formData.image || isEdit
                                            ? (
                                                <div className='edit-ship-avatar' style={{ backgroundImage: isEdit && !formData.image ? `url(${imageUrl})` : `url('${(typeof formData.image === 'string' ? formData.image : URL.createObjectURL(formData.image))}')` }} />
                                            )
                                            : (
                                                <>
                                                    <p className="text-center">CLICK TO UPLOAD OR DRAG PHOTO HERE</p>
                                                </>
                                            )}
                                    </label>

                                </div>
                                <Text preset="smallParagraph" text="Supported format: JPG / PNG" style={{ color: '#b2a4a4', letterSpacing: '0.2em', marginTop: '0', marginBottom: '0', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 600, textTransform: 'uppercase' }} />
                            </>
                            : <div className='edit-ship-avatar' style={{ backgroundImage: isEdit && !formData.image ? `url(${imageUrl})` : `url('${URL.createObjectURL(formData.image)}')` }} />}
                    </div>
                    <div className='edit-ship-audio-container d-flex flex-col'>
                        <Text preset="smallParagraph" text="Auditory Cue" style={{ color: '#222222', letterSpacing: '0.5em', marginTop: '0', marginBottom: '0', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 600, textTransform: 'uppercase' }} />
                        {!isForView
                            ? <AudioRecorder audioUrl={audioUrl} onRecordedAudio={(audioUrl: string, audioBlob: Blob) => updateFormDataWithAudio(audioUrl, audioBlob)} iswhite={false} />
                            : <AudioRecorder audioUrl={audioUrl} onRecordedAudio={(audioUrl: string, audioBlob: Blob) => updateFormDataWithAudio(audioUrl, audioBlob)} requireRecordDelete={false} iswhite={false} />}
                    </div>
                    <div className='d-flex jce'>
                        {!isForView
                            ? <Button
                                size="large"
                                type="submit"
                                className={`${!formData.title || !formData.manifest || !formData.image || !formData.audio ? 'edit-ship__save-button' : 'edit-ship__save-button'}`}
                            >
                                {isLoading ? <CircularProgress /> : (isEdit ? 'Update' : 'Save')}
                            </Button>
                            : <>
                                <CustomButton
                                    buttonText="EDIT"
                                    eventHandle={editViewPopup}
                                    size="lg"
                                    width="54"
                                    fontSize="12px"
                                    isTransparent={true}
                                    style={{ border: '1px solid #72b9b0', backgroundColor: 'transparent' }}

                                />
                                <CustomButton
                                    buttonText="CLOSE"
                                    eventHandle={(e) => {
                                        closeViewPopup(e)
                                    }}
                                    size="lg"
                                    width="54"
                                    fontSize="12px"
                                    style={{ backgroundColor: '#72b9b0', color: '#fff' }}
                                />

                            </>}
                    </div>
                </form>
            </div>
        </Dialog>
        <BasicModal isOpen={isPhotoModalOpen} closeHandler={(() => {})} okHandler={(() => setIsPhotoModalOpen(false))} cancelHandler={(() => {})} header={''} body={<DepositPhotos category={shipCategoryTitle!} formData={formData} setFormData={setFormData} />} labelOk={'Continue'} labelCancel={''} />
        </>

    )
}
export default EditShipPopup
