import React from 'react'

import Video from '../../components/video/7C/video'

const Calm = () => {
  // const body = 'Your thoughts are the wind that will either fill your sails or blow them off course. CALM means that you create ideal sailing conditions by finding consistent ways to quiet your internal thoughts to keep your mental environment at seven knots, ideal sailing conditions. '
  return (
    <Video name='calm.mp4' title='Calm' body={''}/>
  )
}

export default Calm
