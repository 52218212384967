import './index.css'
// import PauseCircleIcon from '@mui/icons-material/PauseCircle'
// import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import React, { useEffect, useState } from 'react'

export interface AudioCardProps {
  imageUrl: string
  audioUrl: string
  audioShowTime: boolean
  audioTotalTime?: number
  audioCurrentTime?: number
  title: string,
  playAudio?:Function,
  pauseAudio?:Function,
  isPlaying?:boolean,
  leftgap?:boolean,

}

const AudioCard: React.FC<AudioCardProps> = (props) => {
  // const roundClamp = (num: number, fractionDigits: number) => {
  //   return Math.min(Math.max(Number(num.toFixed(fractionDigits)), 0), 100)
  // }

  // const getTimelineFilledPercentage = (
  //   totalTime: number,
  //   currentTime: number
  // ) => {
  //   return roundClamp((currentTime / totalTime) * 100, 2)
  // }

  // const getTimelineNotFilledPercentage = (
  //   totalTime: number,
  //   currentTime: number
  // ) => {
  //   return roundClamp((1 - currentTime / totalTime) * 100, 2)
  // }

  const getAudioCardStyle = (imageUrl: string) => ({
    background: `url(${imageUrl}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: props?.leftgap ? '200px' : '0'

  })

  // const getTimelineStyle = (totalTime: number, currentTime: number) => {
  //   let filledPercentage = getTimelineFilledPercentage(totalTime, currentTime)
  //   if (filledPercentage > 95) {
  //     filledPercentage = 100
  //   }
  //   if (filledPercentage <= 5) {
  //     filledPercentage = 0
  //   }
  //   const filledRadius = filledPercentage > 95 ? '10px' : 0
  //   let notFilledPercentage = getTimelineNotFilledPercentage(
  //     totalTime,
  //     currentTime
  //   )
  //   if (notFilledPercentage > 95) {
  //     notFilledPercentage = 100
  //   }
  //   if (notFilledPercentage <= 5) {
  //     notFilledPercentage = 0
  //   }
  //   const notFilledRadius = notFilledPercentage > 95 ? '10px' : 0

  //   return {
  //     filled: {
  //       width: `${filledPercentage}%`,
  //       borderTopRightRadius: filledRadius,
  //       borderBottomRightRadius: filledRadius
  //     },
  //     notFilled: {
  //       width: `${notFilledPercentage}%`,
  //       borderTopLeftRadius: notFilledRadius,
  //       borderBottomLeftRadius: notFilledRadius
  //     }
  //   }
  // }

  // const formatTime = (time: number) => {
  //   const padLeft = (value: number, pad: string, length: number) => {
  //     return (new Array(length + 1).join(pad) + value).slice(-length)
  //   }
  //   const minutes = Math.floor(time / 60)
  //   let seconds = Math.floor(time - minutes * 60)
  //   if (seconds === 0) {
  //     seconds = 1
  //   }
  //   return padLeft(minutes, '0', 2) + ':' + padLeft(seconds, '0', 2)
  // }

  const [audioCardStyle, setAudioCardStyle] = useState(
    getAudioCardStyle(props.imageUrl)
  )
  // const [timelineStyle, setTimelineStyle] = useState(
  //   getTimelineStyle(props.audioTotalTime ?? 1, props.audioCurrentTime ?? 1)
  // )

  // useEffect(() => {
  //   setTimelineStyle(
  //     getTimelineStyle(props.audioTotalTime ?? 1, props.audioCurrentTime ?? 1)
  //   )
  // }, [props.audioTotalTime, props.audioCurrentTime])

  useEffect(() => {
    setAudioCardStyle(getAudioCardStyle(props.imageUrl))
  }, [props.imageUrl])

  return (
    <div className={props.audioShowTime ? 'audio-card-long' : 'audio-card'} style={audioCardStyle}>
      <div style={{ display: 'flex', alignItems: 'start', padding: '15px' }}>
        <div style={{ width: '40px' }}>
          {/* {
            props?.isPlaying ? <PauseCircleIcon style={{ width: '50px', height: '50px', color: '#fff' }} onClick={() => props.pauseAudio && props.pauseAudio()} /> : <PlayCircleIcon style={{ width: '50px', height: '50px', color: '#fff' }} onClick={() => props.playAudio && props.playAudio()} />
          } */}

        </div>
        {/* {props.audioShowTime && (
          <div className='audio-card-playback' style={{ width: '100%' }}>
            <div className='audio-card-playback-timeline'>
              <div
                className='audio-card-playback-timeline-filled'
                style={timelineStyle.filled}
              ></div>
              <div
                className='audio-card-playback-timeline-not-filled'
                style={timelineStyle.notFilled}
              ></div>
            </div>
            <div className='audio-card-playback-timers'>
              <div className='audio-card-playback-timer'>
                {formatTime(props.audioCurrentTime ?? 1)}
              </div>
              <div className='audio-card-playback-timer'>
                {formatTime(props.audioTotalTime ?? 1)}
              </div>
            </div>
          </div>
        )} */}
      </div>

      <div className='audio-card-title' style={{ paddingLeft: `${props.leftgap ? '200px' : '0'}` }}>{props.title}</div>
    </div>
  )
}

export default AudioCard
