import { Page, View, Document, Text, Image, Font } from '@react-pdf/renderer'
import React from 'react'

import Logo from '../../assets/images/icons/pdf_logo.png'
// @ts-ignore
import BoldMonteserrat from '../../fonts/Montserrat-Bold.ttf'
// @ts-ignore
import MediumMonteserrat from '../../fonts/Montserrat-Medium.ttf'
// @ts-ignore
import ThinMonteserrat from '../../fonts/Montserrat-Thin.ttf'
import { ShipItemsPdfData } from '../../types'

Font.register({
  family: 'Monteserrat',
  fonts: [
    { src: BoldMonteserrat, fontWeight: 'bold' },
    { src: MediumMonteserrat, fontWeight: 'normal' },
    { src: ThinMonteserrat, fontWeight: 'normal' }
  ]
})

const PdfColor = {
  Health: {
    topColor: '#f4fafc',
    background: '#E5F2F6',
    color: '#132235'
  },
  Self: {
    topColor: '#E5F2F6',
    background: '#c8e4ec',
    color: '#132235'
  },
  Play: {
    topColor: '#c8e4ec',
    background: '#60748c',
    color: '#FFFFFF'
  },
  Passion: {
    topColor: '#60748c',
    background: '#384c5c',
    color: '#FFFFFF'
  },
  Wealth: {
    topColor: '#384c5c',
    background: '#182434',
    color: '#FFFFFF'
  }
}

type titleType = 'Health' | 'Wealth' | 'Self' | 'Passion' | 'Play'

interface PdfDocProps {
  shipData: ShipItemsPdfData[],
  user: {
    first_name: string,
    last_name: string,
    image_url: string
  },
  boardRequired: Boolean,
  overviewRequired: Boolean
}

const PdfDoc: React.FC<PdfDocProps> = (props) => {
  const { shipData, user, overviewRequired, boardRequired } = props

  const renderHeader = (firstPdf: Boolean) => {
    return (
      <>
        <View style={{ height: '5px', backgroundColor: '#2C4B64', margin: '5px 0 0 5px' }}></View>
        <View style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', padding: '20px' }}>
          <View><Image src={Logo} style={{ maxWidth: '130px', marginLeft: '40px' }}></Image></View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ textAlign: 'right', color: '#132235', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Text style={{ fontSize: '15px', marginRight: '10px' }}>{firstPdf ? 'Dream Design Overview' : 'Dream Board'}</Text>
              <Text style={{ fontSize: '10px', color: '#132235', marginRight: '10px' }}>{user !== undefined && `${user.first_name} ${user.last_name}`}</Text>
            </View>
            <View>{
              (user !== undefined && user.image_url) && (
                <Image src={`${user.image_url}?not-from-cache-please`} style={{ width: '35px', height: '35px', borderRadius: '50%' }} />
              )
            }
            </View>
          </View>
        </View>
      </>
    )
  }

  const renderBody = (firstPdf: Boolean) => {
    return (
      shipData.map((data, shipIndex) => {
        const { title, shipItems } = data
        const shipItemsAtSea = shipItems.filter((item) => item.status === 'at_sea')
        // Define the maximum number of items per subarray
        const itemsPerSubarray = 5

        // Create an array to store the nested subarrays
        const groupedArrays = []

        // Loop through the original array and create subarrays
        for (let i = 0; i < shipItemsAtSea.length; i += itemsPerSubarray) {
          const subarray = shipItemsAtSea.slice(i, i + itemsPerSubarray)
          groupedArrays.push(subarray)
        }

        return groupedArrays.map((item, index) => {
          return (
            <View key={index} wrap={false} style={{ marginTop: '-6px' }}>
              {index === 0 && <View style={{ backgroundColor: PdfColor[title as titleType].topColor, margin: '0' }}>
                <View style={{
                  padding: '10px 0px 5px 30px',
                  backgroundColor: PdfColor[title as titleType].background,
                  width: '90px',
                  margin: '0px',
                  borderTopRightRadius: '20px',
                  fontStyle: 'normal',
                  fontSize: '12px'
                }}>
                  <Text style={{ fontFamily: 'Monteserrat', fontWeight: 'normal', color: PdfColor[title as titleType].color }}>
                    {title}
                  </Text>
                </View>
              </View>}

              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: PdfColor[title as titleType].background, width: 'calc(100% - 40px)', margin: '0 0', padding: '10px 20px 20px 20px', maxHeight: 'auto', height: 'auto', top: `-${index * 2 + 2}` }} >

              {
                item.map((item: any, index: number) => {
                  const uploads = item.assets.find((d: any) => d.model_name === 'SHIP-ITEM-IMAGE')
                  const url = uploads?.deposit_url ? uploads?.deposit_url : uploads?.url
                  console.log(url)
                  return (
                    <View
                      key={String(index)}
                      style={{
                        maxWidth: '20%',
                        width: 'calc(18% - 10px)',
                        margin: firstPdf ? '0 5px ' : '5px 5px',
                        height: 'auto',
                        minHeight: '50px',
                        borderRadius: '30px'
                      }}>
                      <View style={{
                        position: 'relative'
                      }}>
                        <Image src={`${url}?not-from-cache`}
                          style={{ width: '100%', height: firstPdf ? '50px' : '80px', objectFit: 'cover', backgroundColor: 'white', borderRadius: firstPdf ? '10px' : '10px' }} />
                        <View style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '20px', backgroundColor: 'rgba(0, 0, 0, 0.4)', borderRadius: firstPdf ? '0px' : '0px' }}>
                        </View>
                        <View style={{ position: 'absolute', bottom: '5px', left: '10px' }}>
                          <Text style={{ fontSize: '8px', color: 'white', zIndex: 9 }}>{item.title}</Text>
                        </View>
                      </View>
                      {firstPdf &&
                        <Text style={{ fontSize: '7px', margin: '5px 0 10px', textAlign: 'justify', color: PdfColor[title as titleType].color }}>{item.manifest}</Text>
                      }
                    </View>
                  )
                })
              }
            </View>
            </View>
          )
        })
      })
    )
  }

  return (

    <Document>
      {
        overviewRequired
          ? <Page size='A4' wrap style={{ padding: '15px 10px', backgroundColor: '#f4fafc' }} >
              {renderHeader(true)}
              {renderBody(true)}
          </Page>
          : null
      }
      {
        boardRequired
          ? <Page size='A4' wrap style={{ padding: '15px 10px', backgroundColor: '#f4fafc' }}>

              {renderHeader(false)}
              {renderBody(false)}

          </Page>
          : null
      }
    </Document>

  )
}

export default PdfDoc
