import { ApiResponse } from '../../types'
import { AudioDetails, AudioMergePayload, AudioPayload, AudioUploadResponse } from '../../types/audioTypes'
import { api } from '../api'

export const getAudioList = async () => {
  return api.get<ApiResponse<AudioDetails[]>>('/audio')
}

export const createAudio = async (data:AudioPayload) => {
  return api.post<AudioUploadResponse>('/audio', data, { headers: { 'Content-Type': 'multipart/form-data' } })
}
export const audioMerge = async (data:AudioMergePayload) => {
  return api.post<AudioUploadResponse>('/audio/merge', data)
}

export const deleteAudioList = async (id: string) => {
  return api.delete<ApiResponse<AudioDetails[]>>(`/audio/${id}`)
}
