import React, { createContext, useState } from 'react'

import { getPhotosFromDepositPhotos } from '../../services/depositPhoto.services/depositPhoto'

interface DepositPhotoProvderProps extends React.PropsWithChildren {

}
export const DepositPhotoContext = createContext({} as any)
const DepositPhotoProvder = ({ children }:DepositPhotoProvderProps) => {
  const [currentQuery, setCurrentQuery] = useState('')
  const [depositPhotos, setDepositPhotos] = useState<any[]>([])
  const [photoLoading, setPhotoLoading] = useState(false)

  const fetchDepositPhotos = async (query:string = 'health', offset:number) => {
    if (!query) return null
    setPhotoLoading(true)
    try {
      const resp = await getPhotosFromDepositPhotos(query, offset)
      if (query !== currentQuery) {
        setCurrentQuery(query)
        setDepositPhotos([])
      }
      setDepositPhotos((prev) => [...prev, ...resp.data.result])
      setPhotoLoading(false)
    } catch (error:any) {
      setPhotoLoading(false)
    }
  }

  return (
    <DepositPhotoContext.Provider value={{ depositPhotos, setDepositPhotos, fetchDepositPhotos, photoLoading }}>{children}</DepositPhotoContext.Provider>
  )
}

export default DepositPhotoProvder
