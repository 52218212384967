import React from 'react'

import Video from '../../components/video/7C/video'

const Calibrate = () => {
  // const body = 'To CALIBRATE means consistently aligning your body and mind to the frequency of the ship you are bringing to shore. You do this by creating a time where you calm your mind and then visualize your ships coming to shore.'
  return (
    <Video name='calibrate.mp4' title='Calibrate' body={''} />
  )
}

export default Calibrate
