import { Typography, Button, Box } from '@mui/material'
// import { Typography, Button, Grid, Box } from '@mui/material'
import React, { useState } from 'react'
// import React, { ChangeEvent, useState } from 'react'

// TrainingImageUploadIcon
// import { TrainingImageUploadIcon, ImagePreview, ImageEdit, PlaceholderIcon } from '../../assets/images/icons'
// import { ImagePreview, ImageEdit } from '../../assets/images/icons'
import { ImageEdit, PlaceholderIcon } from '../../assets/images/icons'
import '../EditShipPopup/EditShipPopup.css'
import inspIcon from '../../assets/images/icons/insprationIcon.png'
import { uploadText } from '../../constant/TrainingConstant'
import { TrainingProps } from '../../types/trainingTypes'
import { setStorageData } from '../../utils/localStorage'
// import { notifyUser } from '../../utils/notify'
// import { CloseSVG } from '../../utils/svgs'

// import { useFormik } from 'formik'

import BasicModal from '../BasicModal/BasicModal'
import CustomButton from '../Button'
import DepositPhotos from '../DepositPhotos'
// import { imageValidationSchema } from '../Register/AuthValidation'
import Text from '../Text/Text'
// import { shipName } from './utils'
// interface InitialValues {
//   files: any
// }
const ImageUpload: React.FC<TrainingProps> = ({ onChangeCallback, handleCustomFormCallback, order, indexedDBdata }) => {
  // const [open, setOpen] = useState(false)
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [prevDropzone, setPrevDropzone] = useState<any>('')
  const [isDisable, setIsDisable] = useState(indexedDBdata?.image.length === 0)
  // const [errorImageMessage, setErrorImageMessage] = useState('')
  const handleForm = (e: any) => {
    const val = e.currentTarget.id
    const backStep = e.currentTarget.dataset.backstep === 'true'
    setStorageData('step', val)
    onChangeCallback(val, false, false, backStep)
  }

  // const initialValues: InitialValues = {
  //   files: null
  // }

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: imageValidationSchema,
  //   onSubmit: (values) => {
  //     if (values.files && typeof values.files !== 'string') {
  //       const reader = new FileReader()
  //       reader.readAsDataURL(formik?.values?.files as File)
  //       reader.addEventListener('load', () => {
  //         if (handleCustomFormCallback) {
  //           handleCustomFormCallback('image', reader.result as string)
  //         }
  //         setOpen(false)
  //         setIsDisable(false)
  //       })
  //     } else {
  //       if (handleCustomFormCallback) {
  //         handleCustomFormCallback('image', values.files)
  //       }
  //       setOpen(false)
  //       setIsDisable(false)
  //     }
  //   }
  // })

  // const MAX_SIZE_BYTES = (1024 * 1024) * 5

  // const validateImageFile = (file: File) => {
  //   const fileType = file.type
  //   const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']

  //   if (file.size > MAX_SIZE_BYTES) {
  //     return 'Please upload an image that is less than 5MB in size.'
  //   }

  //   if (!allowedTypes.includes(fileType)) {
  //     return 'Please upload a PNG or JPG image.'
  //   }

  //   return null
  // }

  // const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0]
  //   if (file) {
  //     const validationError = validateImageFile(file)
  //     if (!validationError) {
  //       setErrorImageMessage('')
  //       formik.setFieldValue('files', file)
  //     } else {
  //       event.target.value = ''
  //       setErrorImageMessage(validationError)
  //       notifyUser(validationError, 'warning')
  //     }
  //   }
  // }

  // const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.preventDefault()
  // }

  // const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.preventDefault()
  // }

  // const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.preventDefault()
  //   const file = event.dataTransfer.files?.[0]

  //   if (file) {
  //     formik.setFieldValue('files', URL.createObjectURL(file))
  //   }
  // }

  // const backgroundImageURL = formik.values.files
  //   ? typeof formik.values.files === 'object'
  //     ? URL.createObjectURL(formik.values.files) // Use object URL for file
  //     : formik.values.files // Use provided URL
  //   : TrainingImageUploadIcon // Use demoimg if files are null

  const bgFormImage = prevDropzone.image
    ? (
        typeof prevDropzone.image === 'object' ? URL.createObjectURL(prevDropzone.image) : prevDropzone.image
      )
    : indexedDBdata?.image ? indexedDBdata?.image : PlaceholderIcon

  // const bgStyle = {
  //   backgroundImage: `url(${backgroundImageURL})`,
  //   borderRadius: formik.values.files ? '20px' : TrainingImageUploadIcon // Adjust based on formik.values.files
  // }

  const dotAfterConcat = indexedDBdata!.title.length > 15 ? '...' : ''

  const handleDeposit2 = ({ image, depositURL }: any) => {
    setPrevDropzone({
      image, depositURL
    })
    // formik.setFieldValue('files', image)
  }

  const handleImage = () => {
    if (prevDropzone.image && typeof prevDropzone.image !== 'string') {
      const reader = new FileReader()
      reader.readAsDataURL(prevDropzone.image as File)
      reader.addEventListener('load', () => {
        if (handleCustomFormCallback) {
          handleCustomFormCallback('image', reader.result as string)
          handleCustomFormCallback('depositURL', '')
        }
        setShowDepositModal(false)
        setIsDisable(false)
      })
    } else {
      if (handleCustomFormCallback) {
        handleCustomFormCallback('image', prevDropzone.image)
        handleCustomFormCallback('depositURL', prevDropzone.depositURL)
        setShowDepositModal(false)
        setIsDisable(false)
      }
    }
  }

  return (
    <>
      <BasicModal body={<DepositPhotos showDropzone={true} category='health' formData={prevDropzone} setFormData={handleDeposit2} />} cancelHandler={() => setShowDepositModal(false)} closeHandler={() => setShowDepositModal(true)} header="Choose a Photo" isOpen={showDepositModal} labelCancel={'Cancel'} labelOk="Continue" okHandler={handleImage} />
      <Box sx={{ display: 'flex', alignItems: 'center', margin: ' 0', maxWidth: '600px' }}>
        <Typography sx={{ textDecoration: 'none', color: '#fff' }}>{uploadText(order)}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '35px 0' }}>
        <div>
          <img src={inspIcon} alt="" />
        </div>
        <Typography sx={{ textDecoration: 'none', color: '#fff', marginLeft: '25px' }}>Open a new browser tab to search for images by clicking on the  <img src={ImageEdit} style={{ width: '20px', padding: '0 5px' }} /> button below.</Typography>
      </Box>
      <div className="image-upload__spark-word">
        <Text preset='paragraph' text={indexedDBdata?.title.substr(0, 15) + dotAfterConcat} style={{ color: '#fff', fontWeight: '700', margin: 0 }} />
      </div>
      <div className='image-upload-mob-wrapper' style={{ display: 'flex', alignItems: 'end' }}>
        <div className="image-upload__manifest-wrapper">
          <Text preset="paragraph" text={indexedDBdata!.manifest} style={{
            width: '299px',
            textAlign: 'justify',
            color: '#FFFFFF',
            fontStyle: 'italic',
            fontWeight: '700'
          }} />
          <div className="image-upload__manifest-wrapper-background upload" style={{ backgroundImage: `url(${bgFormImage})` }}><img src={ImageEdit} onClick={() => setShowDepositModal(true)} style={{ zIndex: '10' }} /></div>
        </div>
        <div className='mbo-center'>
          <CustomButton eventHandle={handleForm} buttonText="BACK" isTransparent={true} formStage="auditory_cue" isBackStep={true}/>
          <CustomButton eventHandle={handleForm} buttonText={'Next'} size="sm" isDisable={isDisable} formStage="review_ship" />
        </div>
      </div>
      {/* <div className='popup-download' style={{
        display: open ? 'block' : 'none'
      }}>
        <div className='popup-content'>
          <div className='edit-ship__close-btn' onClick={() => setOpen(!open)}><CloseSVG /></div>

          <div className='popup-header'>
            <div className="popup-title">
              <div className='header-main__title'>
                {shipName(order)}
              </div>
              <div className='header-sub__title'>
                Visual Imagery Photo Upload
              </div>
            </div>
          </div>
          <div className="popup-body">

            <div className="body-content">
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: '15px' }}>
                  <Grid item xs={12} style={{ marginTop: '72px', textAlign: 'center' }}>
                    <button className="image_input2-label" onClick={() =>
                      setShowDepositModal(true)}>
                      Upload Photo
                    </button>
                  </Grid>

                </Grid>
                <input
                  accept="image/*"
                  id="image-input"
                  type="file"
                  onChange={handleImageChange}
                  hidden
                />
                <input
                  accept="image/*"
                  id="image-input2"
                  type="file"
                  onChange={handleImageChange}
                  hidden
                />
                <div style={{ height: '100%', width: '100%' }}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <label htmlFor="image-input">
                    <div className="training__image-wrapper" style={bgStyle}>
                      {
                        !formik.values.files ? <img src={ImagePreview} style={{ margin: '125px auto' }} /> : null
                      }
                    </div>
                  </label>

                </div>

                {errorImageMessage && <div className="error-message" style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>{errorImageMessage}</div>}

              <div style={{ textAlign: 'center', marginTop: '20px' }}>
 <Button
                      size="small"
                      type="submit"
                      disabled={!formik.isValid}
                      className="image-upload__submit-btn"
                    >
                      Save
                    </Button>
              </div>

              </form>
            </div>

          </div>

        </div >
      </div > */}
      <Button onClick={handleForm}></Button>
    </>
  )
}

export default ImageUpload
