import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import './index.css'
import CustomError from './components/CustomError'
import ErrorBoundary from './ErrorBoundary'

const root = createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<CustomError />}>
      <App />
    </ErrorBoundary>
  </React.StrictMode >
)
