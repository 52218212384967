import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'

import RenewPaymentForm from '../../components/Register/RenewPaymentFrom'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!)

const RenewPaymentMethod = () => {
  return (
        <div>

            <Elements stripe={stripePromise}>
                <RenewPaymentForm />
            </Elements>
        </div>
  )
}

export default RenewPaymentMethod
