import React from 'react'
import './Text.css'
type TextPresets = 'h1' | 'h2' | 'h3' | 'h4' | 'paragraph' | 'smallParagraph' | 'eyebrow' | 'label'

interface TextProps {
    text: string
    preset?: TextPresets
    style?:React.CSSProperties
}

const Text: React.FC<TextProps> = (props) => {
  const { preset, text, style } = props

  const renderText = () => {
    if (preset === 'h1') {
      return <h1 className='texth1' style={style}>{text}</h1>
    }
    if (preset === 'h2') {
      return <h2 className='texth2' style={style}>{text}</h2>
    }
    if (preset === 'h3') {
      return <h3 className='texth3' style={style}>{text}</h3>
    }
    if (preset === 'h4') {
      return <h4 className='texth4' style={style}>{text}</h4>
    }
    if (preset === 'paragraph') {
      return <p className='textParagraph' style={style}>{text}</p>
    }
    if (preset === 'smallParagraph') {
      return <p className='textSmallParagraph' style={style}>{text}</p>
    }
    if (preset === 'eyebrow') {
      return <p className='textEyebrow' style={style}>{text}</p>
    }
    if (preset === 'label') {
      return <p className='textLabel' style={style}>{text}</p>
    }
  }

  return (
    <>
    {renderText()}
    </>
  )
}

export default Text
