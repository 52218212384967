import { Grid, TextField, Button, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { userInfoSchema } from '../../components/Register/AuthValidation'
import Text from '../../components/Text/Text'
import { AuthContext } from '../../context/AuthContext'
import { api } from '../../services/api'
import { signup } from '../../services/authentication.service/signup'
import { setAuthorizationHeader } from '../../services/interceptors'
import { setStorageData } from '../../utils/localStorage'
import { notifyUser } from '../../utils/notify'

const Form = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { setUser } = useContext(AuthContext)

  const scrollToErrorField = (errors: { [key: string]: string }) => {
    const firstErrorField = Object.keys(errors)[0]
    const firstErrorInput = formRef.current?.querySelector(`[name="${firstErrorField}"]`) as HTMLInputElement | null

    if (firstErrorInput) {
      firstErrorInput.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: userInfoSchema,
    onSubmit: (values) => {
      setIsLoading(true)
      const { firstName, lastName, phoneNumber, email, password, confirmPassword } = values
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email,
        mobile_no: phoneNumber,
        password,
        confirm_password: confirmPassword
      }
      signup(formData).then((response) => {
        // eslint-disable-next-line camelcase
        const { token, refresh_token } = response.data

        setStorageData('TOKEN', token)
        setStorageData('REFRESH_TOKEN', refresh_token)
        setUser(response?.data?.user)
        setAuthorizationHeader(api.defaults, token)
        setIsLoading(false)
        navigate('/profile-image')
      }).catch((error: any) => {
        setIsLoading(false)
        if (error.response.data.error.message === 'Email/Phone already exist.') {
          notifyUser('Email/Phone exists. Please login. Forgot password? Reset it.', 'danger')
          navigate('/login')
        } else {
          notifyUser(error.response.data.error.message, 'danger')
        }
      })
    }
  })

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '')
  }

  const handleInputFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const sanitizedValue = value.trimStart()
    event.target.value = sanitizedValue
  }
  const handleAlreadyAcc = () => {
    navigate('/login')
  }

  const customHandleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (Object.keys(errors).length > 0) {
      scrollToErrorField(errors)
      return
    }
    handleSubmit(e)
  }

  return (
    <>
      <form ref={formRef} onSubmit={customHandleSubmit}>
        <Text preset='eyebrow' text="Get Started" style={{ textAlign: 'center', marginBottom: 0 }} />
        <Text preset="h1" text="Create a profile" style={{ textAlign: 'center', marginTop: '0' }} />
        <Grid container spacing={6}>
          <Grid item xs={12} lg={6}>
            <Text preset='label' text="First Name" />
            <TextField fullWidth value={values.firstName} onChange={handleChange} name="firstName" error={!!(values.firstName && errors.firstName)}
              helperText={values.firstName && errors.firstName} onInput={handleInputFirst} required />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Text preset='label' text="Last Name" />
            <TextField fullWidth value={values.lastName} onChange={handleChange} name="lastName" error={!!(values.lastName && errors.lastName)}
              helperText={values.lastName && errors.lastName} onInput={handleInputFirst} required />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Text preset='label' text="Phone" />
            <TextField fullWidth value={values.phoneNumber} onChange={handleChange} name="phoneNumber" type="tel" error={!!(values.phoneNumber && errors.phoneNumber)}
              helperText={values.phoneNumber && errors.phoneNumber} onInput={handleInput} required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Text preset='label' text="Email" />
            <TextField fullWidth value={values.email} onChange={handleChange} name="email" type="email" error={!!(values.email && errors.email)}
              helperText={values.email && errors.email} onInput={handleInputFirst} required />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Text preset='label' text="Password" />
            <TextField fullWidth value={values.password} onChange={handleChange} type="password" name="password" error={!!(values.password && errors.password)}
              helperText={values.password && errors.password} required onInput={handleInputFirst} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Text preset='label' text="Confirm Password" />
            <TextField fullWidth value={values.confirmPassword} onChange={handleChange} name="confirmPassword" type="password" error={!!(values.confirmPassword && errors.confirmPassword)}
              helperText={values.confirmPassword && errors.confirmPassword} onInput={handleInputFirst} required />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className="mt-10">
          <Button
            size="large"
            type="submit"
            className="form-submit-button"
            disabled={isLoading}
          >

            {
              isLoading ? <CircularProgress /> : 'Lets Go'
            }
          </Button>
        </Grid>
        <Grid container justifyContent="center" className="mt-10">
          <span onClick={handleAlreadyAcc}>
            <Text preset='paragraph' text="Already have account? " style={{ cursor: 'pointer' }} />
          </span>
        </Grid>
      </form>
    </>
  )
}

export default Form
