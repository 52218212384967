import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'

import PaymentForm from './paymentForm'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!)
interface PaymentFormProps {
  // handleStepChange: (step: string, inputs?: any) => void
}
const wrappedForm: React.FC<PaymentFormProps> = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  )
}
export default wrappedForm
