import { Box, Button, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'

import Text from '../../../components/Text/Text'
import RoundedImageUploader from '../UploadImage'

interface FormData {
    profilePicture: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    location: string;
  }

interface FormProps {
    setShowForm: Function,
    showForm: boolean
}

const btnStyle = {
  cancelBtnStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    borderRadius: '29px',
    marginRight: '30px',
    border: '1px solid #AC3131',
    textAlign: 'center',
    letterSpacing: '0.2em',
    color: '#0D111A',
    padding: '10px 23px'
  },

  submitBtnStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    borderRadius: '29px',
    marginRight: '30px',
    // border: '1px solid #AC3131',
    textAlign: 'center',
    letterSpacing: '0.2em',
    color: '#0D111A',
    padding: '10px 23px',
    background: '#72B9B0;',
    border: 'none'
  }

}

const EditForm: React.FC <FormProps> = (props) => {
  const { setShowForm, showForm } = props
  const [formData, setFormData] = useState<FormData>({
    profilePicture: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    location: ''
  })
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const handleCancel = () => {
    setShowForm(!showForm)
  }
  return (
    <div>
    <Box>
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={6} xl={6} md={6}>
          <div>
            <RoundedImageUploader />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} xl={6} md={6}>
          <div className='edit__wrapper' > <Text preset='paragraph' text="Editing" style={{ lineHeight: '10px', color: '#5E7689' }}/></div>
        </Grid>
        <Grid item xs={12} sm={6} xl={6} md={6}>
          <div className='first__name'>
            <Text preset='paragraph' text="FIRST NAME" style={{ lineHeight: '10px', color: '#5E7689', letterSpacing: '0.5em' }}/>
            <TextField
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} xl={6} md={6}>
          <div className='last__name'>
          <Text preset='paragraph' text="LAST NAME" style={{ lineHeight: '10px', color: '#5E7689', letterSpacing: '0.5em' }}/>
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
          />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} xl={6} md={6}>
          <div className='phone__number'>
          <Text preset='paragraph' text="PHONE" style={{ lineHeight: '10px', color: '#5E7689', letterSpacing: '0.5em' }}/>
          <TextField
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
          />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} xl={6} md={6}>
          <div className='email'>
          <Text preset='paragraph' text="EMAIL" style={{ lineHeight: '10px', color: '#5E7689', letterSpacing: '0.5em' }}/>
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
          />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} xl={6} md={6}>
          <div className='location'>
          <Text preset='paragraph' text="LOCATION" style={{ lineHeight: '10px', color: '#5E7689', letterSpacing: '0.5em' }}/>
          <TextField
            label="Location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
          />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} xl={12} md={12}>
        <Button variant="outlined" sx={btnStyle.cancelBtnStyle} onClick={() => handleCancel()}>
        Cancel
      </Button>
      <Button type="submit" variant="contained" sx={btnStyle.submitBtnStyle} >
        Submit
      </Button>
        </Grid>
        </Grid>
      </form>
    </Box>
    </Box>
    </div>
  )
}

export default EditForm
