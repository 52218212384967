import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Grid, Button, FormControl, InputAdornment, IconButton, TextField, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import { useState, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { resetPasswordSchema } from '../../components/Register/AuthValidation'
import Text from '../../components/Text/Text'
import { resetPassword } from '../../services/./authentication.service/signup'
import { notifyUser } from '../../utils/notify'

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const resetToken = params.get('reset_token')
  const uid = params.get('uid')
  const navigate = useNavigate()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const { values, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      setIsLoading(true)
      const { password, confirmPassword } = values
      const formData = {
        password,
        confirmPassword,
        resetToken,
        uid
      }

      resetPassword(formData).then((res) => {
        const { message } = res.data
        notifyUser(message, 'success')
        navigate('/login')
        setIsLoading(false)
      }).catch((err) => {
        // case when token has already been used
        setIsLoading(false)
        if (err?.response?.data?.error) {
          if (err?.response?.status === 404) {
            notifyUser('Reset token has already been used', 'danger')
          }
        }
      })
    }
  })

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Text preset="h1" text="Reset your password" style={{ textAlign: 'center', marginTop: '0' }} />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Text preset='label' text="New Password"/>
            <FormControl sx={{ m: 1, width: '100%', position: 'relative' }} variant="filled">
              <TextField fullWidth name="password" value={values.password} onChange={handleChange} type={showPassword ? 'text' : 'password'} id="filled-adornment-password" error={!!(touched.password && errors.password)}
                         helperText={touched.password && errors.password}/>
              <InputAdornment position="end" sx={{ position: 'absolute', top: '50%', right: '5%' }}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Text preset='label' text="Confirm Password" />
            <FormControl sx={{ m: 1, width: '100%', position: 'relative' }} variant="filled">
              {/* <InputLabel htmlFor="filled-adornment-password">Password</InputLabel> */}
              <TextField fullWidth name="confirmPassword" value={values.confirmPassword} onChange={handleChange} type={showConfirmPassword ? 'text' : 'password'} id="filled-adornment-confirm-password" error={!!(touched.confirmPassword && errors.confirmPassword)}
                         helperText={touched.confirmPassword && errors.confirmPassword} />
              <InputAdornment position="end" sx={{ position: 'absolute', top: '50%', right: '5%' }}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className="mt-10">
          <Button
            size="large"
            type="submit"
            className="form-submit-button"
          >
            {
              isLoading ? <CircularProgress /> : 'Reset your Password'
            }
          </Button>
        </Grid>
      </form>
    </>
  )
}

export default ResetPassword
