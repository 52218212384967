import * as yup from 'yup'

export const userInfoSchema = yup.object({
  firstName: yup.string().required('First Name is required').matches(/^(?=.*[a-zA-Z ])[a-zA-Z ]+$/, 'Name cannot contain number or special character'),
  lastName: yup.string().required('Last Name is required').matches(/^(?=.*[a-zA-Z ])[a-zA-Z ]+$/, 'Lastname cannot contain number or special character'),
  email: yup.string().matches(/^(([^<>()[\][\]\\.,;:\s@"]+(\.[^<>()[\][\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Invalid Email Address').required('Email is required'),
  phoneNumber: yup.string().required('Phone Number is required').matches(/^\d{10}$/, 'Phone Number must be exactly 10 digits'),
  password: yup.string().required('Password is required.').matches(/^(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])(?=.{8,})[a-zA-Z0-9!@#$%^&*()_+[\]{};':"\\|,.<>/?-]*$/, 'Password must contain at least 1 special character and be 8 characters long'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), ''], 'Passwords must match').required('Confirm Password is required')
})

export const profileValidation = yup.object({
  first_name: yup.string()
    .required('Firstname is required')
    .matches(/^(?=.*[a-zA-Z ])[a-zA-Z ]+$/, 'Name cannot contain number or special character'),
  last_name: yup.string()
    .required('Lastname is required')
    .matches(/^(?=.*[a-zA-Z ])[a-zA-Z ]+$/, 'Name cannot contain number or special character'),
  mobile_no: yup.string()
    .required('mobile no is required')
    .matches(/^\d{10}$/, 'Phone Number must be exactly 10 digits')
})

export const imageValidationSchema = yup.object({
  files: yup
    .mixed()
    .required('Please upload an image')
    .test('fileType', 'Unsupported File Format', function (value) {
      if (value instanceof File) {
        const supportedFormats = ['image/jpeg', 'image/png', 'image/gif']
        return supportedFormats.includes(value.type)
      }
      return true
    })
})

export const paymentFormSchema = yup.object({
  fullName: yup.string().required('First Name is required'),
  billingAddress: yup.string().required('Billing Address is required')
})

export const forgetPasswordSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Email is required')
})

export const loginFormSchema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required')
})

export const resetPasswordSchema = yup.object({
  password: yup.string().matches(/^(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])(?=.{8,})[a-zA-Z0-9!@#$%^&*()_+[\]{};':"\\|,.<>/?-]*$/, 'Password must contain atleast 1 special character and be 8 character long').required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), ''], 'Passwords must match').required('Confirm Password is required')
})
