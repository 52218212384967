/**
 * Composing <Route> in React Router v6
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 *
 * Upgrading from v5
 * https://reactrouter.com/docs/en/v6/upgrading/v5
 */
import { Routes, Route, Navigate } from 'react-router-dom'

// import SwipeableEdgeDrawer from '../pages/Home'
import PageNotFound from '../components/PageNotFound'
import WrappedPaymentForm from '../components/Register/wrapPaymentForm'
// import ShipManifests from '../components/ShipManifests'
import ShipManifests from '../components/ShipManifests'
import DashboardLayout from '../Layout/DashboardLayout'
import Calibrate from '../pages/7c/calibrate'
import Calm from '../pages/7c/calm'
import Claim from '../pages/7c/claim'
import Clear from '../pages/7c/clear'
import Clues from '../pages/7c/clues'
import Crew from '../pages/7c/crew'
import Cues from '../pages/7c/cues'
import SevenSea from '../pages/7c/sevenSea'
import TrainingCompletion from '../pages/Account/ TrainingCompletion'
import GetStarted from '../pages/Account/GetStarted'
import RenewPaymentMethod from '../pages/Account/RenewPaymentMethod'
import RenewSubscription from '../pages/Account/RenewSubscription'
import { AuthenticationPage } from '../pages/AuthenticationPage'
import CalibrateBanner from '../pages/CalibratePage'
import ForgetPassword from '../pages/ForgetPassword/forgetPassword'
import IslandVisualization from '../pages/IslandVisualization'
import Login from '../pages/Login'
// import { Metrics } from '../pages/Metrics'
import OnlinePlatform from '../pages/OnlinePlatform/OnlinePlatform'
import ProfilePanel from '../pages/Profile'
import { Register } from '../pages/Register'
// import Form from '../pages/Register/form'
import ImageUploader from '../pages/Register/imageuploader'
import PaymentSetUp from '../pages/Register/paymentSetUp'
import ResetPassword from '../pages/ResetPassword/resetPassword'
import TabPanel from '../pages/ShipLists/'
import ShipTraining from '../pages/training'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
export const RouteList = () => (
  <Routes>
    <Route path="/" element={<PublicRoute><AuthenticationPage /></PublicRoute>}>
      <Route path='/' element={<Navigate replace to={'/login'} />} />
      <Route
        path="/register"
        element={<Register />}
      />
      <Route
        path="/login"
        element={<Login />}
      />
      <Route
        path="/change-password"
        element={<ForgetPassword />}
      />
      <Route
        path="/reset-password"
        element={<ResetPassword />}
      />
      <Route
        path='/payment-setup'
        element={<PaymentSetUp />}
      />
    </Route>
    <Route path='/' element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
      <Route
        path="/daily-calibrate"
        element={<CalibrateBanner />}
      />
      <Route
        path="/ship-sea"
        element={<TabPanel />}
      />
      <Route
        path="/ship-arrived"
        element={<TabPanel />}
      />
      <Route
        path="/profile"
        element={<ProfilePanel />}
      />
      <Route
        path="/online-platform"
        element={<OnlinePlatform />}
      />
      <Route
        path="/audio-cues"
        element={<ShipManifests />}
      />
      <Route
        path="/training"
        element={<ShipTraining />}
      />

      <Route path='/island-visualization' element={<IslandVisualization />} />
      <Route path='/seven-sea' element={<SevenSea />} />
      <Route path='/calm' element={<Calm />} />
      <Route path='/claim' element={<Claim />} />
      <Route path='/clues' element={<Clues />} />
      <Route path='/crew' element={<Crew />} />
      <Route path='/cues' element={<Cues />} />
      <Route path='/clear' element={<Clear />} />
      <Route path='/calibrate' element={<Calibrate />} />

    </Route>
    <Route path='/' element={<PrivateRoute><AuthenticationPage /></PrivateRoute>}>

      <Route
        path="/card-setup"
        element={<WrappedPaymentForm />}
      />
      <Route
        path="/renew-subscription"
        element={<RenewSubscription />}
      />
      <Route
        path="/renew-paymentMethod"
        element={<RenewPaymentMethod />}
      />
      <Route
        path="/profile-image"
        element={<ImageUploader />}
      />
      <Route
        path="/get-started"
        element={<GetStarted />}
      />
      <Route
        path="/training-completed"
        element={<TrainingCompletion />}
      />
    </Route>

    <Route path="*" element={<PageNotFound />} />

  </Routes>
)
