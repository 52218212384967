import { Button, Grid } from '@mui/material'
import React from 'react'

interface Props{
    eventHandle: Function,
    successHandler?: Function,
    titleSuccess: string,
    titleUnsuccess: string,
}
const btnStyle = {
  cancelBtnStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    borderRadius: '29px',
    marginRight: '30px',
    border: '1px solid #AC3131',
    textAlign: 'center',
    letterSpacing: '0.2em',
    color: '#0D111A',
    padding: '10px 23px'
  },

  submitBtnStyle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    borderRadius: '29px',
    marginRight: '30px',
    // border: '1px solid #AC3131',
    textAlign: 'center',
    letterSpacing: '0.2em',
    color: '#0D111A',
    padding: '10px 23px',
    background: '#72B9B0;',
    border: 'none'
  }
}

const ButtonComponent:React.FC<Props> = (props) => {
  const { eventHandle, titleUnsuccess, titleSuccess, successHandler } = props

  return (
    <Grid item xs={12} sm={12} xl={12} md={12} sx={{
      '@media (max-width: 600px)': {
        gap: '20px',
        marginTop: '20px'
      }
    }} >
    <Button variant="outlined" sx={btnStyle.cancelBtnStyle} onClick={() => { eventHandle() }}>
    {titleUnsuccess}
  </Button>
  <Button type={successHandler ? 'button' : 'submit'} variant="contained" sx={btnStyle.submitBtnStyle} onClick={() => { successHandler && successHandler() }}>
    {titleSuccess}
  </Button>
    </Grid>
  )
}

export default ButtonComponent
