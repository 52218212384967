import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined'
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined'
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined'
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import { Button, ButtonBase, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.js'

import './AudioRecorder.css'

export interface AudioRecorderProps {
  audioUrl: string | null;
  onRecordedAudio?: (audioUrl: string, audioBlob: Blob) => void;
  requireRecordDelete?: Boolean;
  onDestroy?: (record: RecordPlugin) => void;
  height?: number;
  width?: string;
  audioTitle?: string;
  iswhite?: boolean;
  barWidth?: number;
  indexId?:number;
  setcurrentPlayingIndex?:React.Dispatch<React.SetStateAction<number | undefined>>;
  currentStatus?:boolean

}

// forcefully set any because of typescript issues in package
const recordOptions:any = {
  lineWidth: 3.18,
  realtimeWaveColor: '#AC3131'
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({ indexId, setcurrentPlayingIndex, currentStatus, requireRecordDelete = true, barWidth, iswhite = false, ...props }) => {
  const wavesurferBaseOptions = {
    container: '',
    height: props.height || 47,
    waveColor: `${iswhite ? '#ffffff' : '#355167'}`,
    progressColor: 'rgba(133, 147, 159, 0.37)',
    barWidth: barWidth || 3.18,
    barHeight: 1,
    barGap: 5.1,
    barRadius: 2,
    interact: false,
    autoCenter: false,
    autoScroll: true,
    hideScrollbar: true
  }

  const wavesurferRecordingOptions = {
    ...wavesurferBaseOptions,
    progressColor: 'rgba(53, 81, 103, 0.5)'
  }

  const wavesurferRef = useRef<HTMLDivElement | null>(null)
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null)
  const [wavesurferOptions, setWavesurferOptions] = useState<WaveSurferOptions>({ ...wavesurferBaseOptions, url: props?.audioUrl! })
  const [record, setRecord] = useState<RecordPlugin | null>()
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isRecording, setIsRecording] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState('00:00')
  const [hasAudio, setHasAudio] = useState<boolean>(props.audioUrl !== null)
  const [hasError, setHasError] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [currentRecordingTime, setCurrentRecordingTime] = useState<number>(0)
  // eslint-disable-next-line no-undef
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

  const formatTime = (time: number) => {
    const padLeft = (value: number, pad: string, length: number) => {
      return (new Array(length + 1).join(pad) + value).slice(-length)
    }
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time - minutes * 60)
    return padLeft(minutes, '0', 2) + ':' + padLeft(seconds, '0', 2)
  }

  const handleRecordedAudio = async (blob: Blob, recordedUrl: string) => {
    props.onRecordedAudio?.(recordedUrl, blob)
  }
  useEffect(() => {
    if (isRecording) {
      const id = setInterval(() => {
        setCurrentRecordingTime((prevTime) => prevTime + 1)
      }, 1000)
      setIntervalId(id)
    } else {
      setCurrentRecordingTime(0)
      if (intervalId !== null) {
        clearInterval(intervalId)
      }
      setIntervalId(null)
    }

    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId)
      }
    }
  }, [isRecording])
  useEffect(() => {
    if (!wavesurferRef || !wavesurferRef.current) {
      return
    }

    const wavesurfer = WaveSurfer.create({
      ...wavesurferOptions,
      container: wavesurferRef.current
    })

    setWavesurfer(wavesurfer)
    setHasAudio(wavesurferOptions.url !== null)

    return () => {
      wavesurfer.destroy()
    }
  }, [wavesurferRef])

  useEffect(() => {
    if (!wavesurfer) {
      return
    }

    const record = wavesurfer.registerPlugin(RecordPlugin.create(recordOptions))
    setRecord(record)
    setIsPlaying(false)
    setCurrentTime(formatTime(0))

    const subscriptions = [
      wavesurfer.on('ready', (duration) => {
        setCurrentTime(formatTime(duration))
      }),
      wavesurfer.on('play', () => setIsPlaying(true)),
      wavesurfer.on('pause', () => setIsPlaying(false)),
      wavesurfer.on('audioprocess', (curretnTime) => {
        setCurrentTime(formatTime(curretnTime))
      })

    ]
    return () => {
      subscriptions.forEach((unsub) => unsub())
    }
  }, [wavesurfer])

  useEffect(() => {
    if (!record) {
      return
    }

    // eslint-disable-next-line no-undef
    let timerSub: NodeJS.Timeout
    let crTime = 0

    const subscriptions = [
      record.on('record-start', () => {
        if (wavesurferRef.current) {
          const progressBarList = wavesurferRef.current.children
          if (progressBarList.length > 1) {
            const progressBar = Array.from(progressBarList)[1] as HTMLElement
            progressBar.style.display = 'block'
          }
          setWavesurferOptions(wavesurferRecordingOptions)
        }
        setIsRecording(true)
        timerSub = setInterval(() => {
          crTime = crTime + 1
          setCurrentTime(formatTime(crTime))
        }, 1000)
      }),
      record.on('record-end', async (blob: Blob) => {
        clearInterval(timerSub)
        record.stopMic()
        wavesurfer && wavesurfer.destroy()
        setIsRecording(false)
        if (!wavesurferRef) return
        const recordedURL = URL.createObjectURL(blob)
        handleRecordedAudio(blob, recordedURL)
        if (wavesurferRef && wavesurferRef.current) {
          const wavesurfer = WaveSurfer.create({
            ...wavesurferRecordingOptions,
            container: wavesurferRef.current,
            url: recordedURL
          })
          setWavesurfer(wavesurfer)
        }
      })
    ]
    return () => {
      subscriptions.forEach((unsub) => unsub())
    }
  }, [record])

  const playAudio = async () => {
    if (!wavesurfer || !hasAudio) {
      return
    }
    setcurrentPlayingIndex && setcurrentPlayingIndex(indexId)
    await wavesurfer.play()
  }
  const pauseAudio = () => {
    if (!wavesurfer || !hasAudio) {
      return
    }
    wavesurfer.pause()
  }
  const startRecording = async () => {
    if (!record) {
      return
    }
    try {
      await record.startMic()
      await record.startRecording()
      setHasAudio(false)
      setHasError(false)
    } catch {
      setHasError(true)
    }
  }

  const stopRecording = async () => {
    if (!record) {
      return
    }

    if (record) {
      record.stopRecording()
      setHasAudio(true)
    }
  }

  const destroyRecording = () => {
    if (!wavesurfer || !record) {
      return
    }

    if (wavesurfer.isPlaying()) {
      wavesurfer.pause()
    }

    setTimeout(() => {
      record.destroy()
      wavesurfer.destroy()
      wavesurfer.unAll()
      if (props.onDestroy) {
        props.onDestroy(record)
      }
      setCurrentTime('00:00')
      setHasAudio(false)
    }, 100)

    if (wavesurferRef && wavesurferRef.current) {
      const wavesurfer = WaveSurfer.create({
        ...wavesurferRecordingOptions,
        container: wavesurferRef.current
      })
      setWavesurfer(wavesurfer)
    }
  }
  const audioRecorderTextStyle = requireRecordDelete ? 'audio-recorder__text' : 'audio-recorder__text list'

  useEffect(() => {
    if (!currentStatus) {
      wavesurfer?.pause()
    }
  }, [currentStatus])

  return (
    <>
      <div className='audio-recorder'>
        <div className='audio-recorder__main'>
          <Stack
            className='audio-recorder__buttons'
            direction='row'
            spacing={2}
            justifyContent='center'
            alignItems='center'
            gap='2px'
          >
            <div style={{ width: '35px' }}>
              <ButtonBase
                style={{ display: !isPlaying ? 'block' : 'none' }}
                disabled={!hasAudio}
                onClick={playAudio}
                aria-label='Play recording'
              >
                <PlayCircleOutlinedIcon color='primary' fontSize='large' />
              </ButtonBase>
              <ButtonBase
                style={{ display: isPlaying ? 'block' : 'none' }}
                disabled={!hasAudio}
                onClick={pauseAudio}
                aria-label='Pause recording'
              >
                <PauseCircleOutlinedIcon color='primary' fontSize='large' />
              </ButtonBase>
            </div>
            {props.audioTitle && <div className='audio__title'> {props.audioTitle} </div>}
            <label className={audioRecorderTextStyle} style={{ width: '50px', color: iswhite ? '#fff' : '#132235' }}>
            {isRecording ? formatTime(currentRecordingTime) : currentTime.toString()}
            </label>

            <div id='wavesurferId' ref={wavesurferRef} style={{ width: props.width || '235px', position: 'relative' }}>
              <label
                style={{ display: !isRecording && !hasAudio ? 'flex' : 'none', color: iswhite ? '#fff' : '' }}
                className='audio-recorder__text-no-audio'
              >
                No audio recorded yet
              </label>
            </div>
            {requireRecordDelete && (
              <div style={{ width: '35px' }}>
                <ButtonBase
                  style={{
                    display: !hasAudio && !isRecording ? 'block' : 'none'
                  }}
                  onClick={startRecording}
                  aria-label='Start recording'
                >
                  <RadioButtonCheckedOutlinedIcon color='error' fontSize='large' />
                </ButtonBase>
                <ButtonBase
                  style={{ display: !hasAudio && isRecording ? 'block' : 'none' }}
                  onClick={stopRecording}
                  aria-label='Stop recording'
                >
                  <StopCircleOutlinedIcon color='error' fontSize='large' />
                </ButtonBase>
                <ButtonBase
                  style={{ display: hasAudio && !isRecording ? 'block' : 'none' }}
                  onClick={() => setIsModalOpen(true)}
                  aria-label='Delete recording'
                >
                  <DeleteOutlinedIcon color='error' fontSize='large' />
                </ButtonBase>
              </div>
            )}
          </Stack>
        </div>

        <Stack className='audio-recorder__errors' textAlign='center'>
          {hasError && <p>You must allow access to your microphone.</p>}
        </Stack>
      </div>
      {requireRecordDelete && (
        <div className='audio-recorder-dialog'>
          <Dialog
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby='Delete recording modal'
            aria-describedby='Confirm this action before proceeding'
          >
            <DialogTitle id='alert-dialog-title'>Delete recording</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Confirm this action before proceeding
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
              <Button onClick={() => {
                setIsModalOpen(false)
                destroyRecording()
              }}
              autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  )
}

export default AudioRecorder
