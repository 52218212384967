import React, { useEffect } from 'react'

import Training from '../../components/training/Training'

const ShipTraining = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent
    console.log('User agent:', userAgent)
    if (userAgent.includes('Chrome')) {
      console.log('User is using Chrome')
    } else if (userAgent.includes('Firefox')) {
      console.log('User is using Firefox')
    } else {
      console.log('User is using a different browser')
    }
  }, [])
  return (
    <div>
      <Training />
    </div>
  )
}

export default ShipTraining
