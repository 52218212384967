import { useState } from 'react'

import CompletionPage from '../../components/Register/completionPage'
import ImageUploader from '../../components/Register/imageuploader'
import PaymentSetUp from '../../components/Register/paymentSetUp'
// import WrappedPaymentForm from '../../components/Register/wrapPaymentForm'
import './register.component.css'
import { getStorageDate } from '../../utils/localStorage'
import Form from './form'

export function Register () {
  let componentToRender
  const [step, setStep] = useState('')

  const handleStepChange = (step: string, formData: object = {}) => {
    setStep(step)
  }

  switch (getStorageDate('step') || step) {
    case 'payment_form':
      // componentToRender = <WrappedPaymentForm handleStepChange={handleStepChange} />
      break
    case 'image_uploader':
      componentToRender = <ImageUploader handleStepChange={handleStepChange} />
      break
    case 'payment_setup':
      componentToRender = <PaymentSetUp handleStepChange={handleStepChange} />
      break
    case 'completion_page':
      componentToRender = <CompletionPage />
      break
    default:
      componentToRender = <Form />
      break
  }

  return (
    <>

        {componentToRender}

    </>
  )
}
