/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { createContext, SetStateAction, useContext, useEffect, useState } from 'react'

import { noAudio } from '../../constant/DailyCalibrate'
import { createAudio, getAudioList, deleteAudioList } from '../../services/audio.services/audio'
import { getShipItemsByStatusService } from '../../services/ship.services/ship'
import { AudioDetails, AudioPayload } from '../../types/audioTypes'
import { ShipCategory } from '../../types/ShipTypes'
import { notifyUser } from '../../utils/notify'
import { AuthContext } from '../AuthContext'

interface ShipManifestProps extends React.PropsWithChildren {

}

interface ShipManifestContextData {
  audioList: AudioDetails[],
  setAudioList: React.Dispatch<SetStateAction<AudioDetails[]>>,
  audioLoading: boolean,
  uploadAudio: (audio: AudioPayload) => Promise<void>,
  deleteAudio: (id: string) => Promise<void>
  isShipsLoading: boolean,
  shipsAtSeaLists: ShipCategory[]

}
export const ShipManifestContext = createContext({} as ShipManifestContextData)
const ShipManifestProvder = ({ children }: ShipManifestProps) => {
  const [audioLoading, setAudioLoading] = useState(true)
  const [isShipsLoading, setIsShipsLoading] = useState(true)
  const [audioList, setAudioList] = useState<AudioDetails[]>([])
  const [shipsAtSeaLists, setShipsAtSeaList] = useState<ShipCategory[]>([])
  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    if (isAuthenticated) {
      fetchAudio()
      fetchShipItemsByStatus()
    }
  }, [isAuthenticated, audioLoading, isShipsLoading])

  const uploadAudio = async (audio: AudioPayload) => {
    try {
      const response = await createAudio(audio)
      notifyUser('Audio has been uploaded successfully.', 'success')
      setAudioList((prevState: AudioDetails[]) => ([
        ...prevState,
        {
          ...response.data.data
        }
      ]

      ))
    } catch (e: any) {
      notifyUser(e.response.data.error.message, 'danger')
    }
  }

  const deleteAudio = async (id: string) => {
    try {
      await deleteAudioList(id)
      notifyUser('Audio has been deleted successfully.', 'success')
      const newAudioList = audioList.filter(list => list.id !== id)
      setAudioList(newAudioList)
    } catch (e: any) {
      notifyUser(e.response.data.error.message, 'danger')
    }
  }

  const fetchAudio = async () => {
    try {
      const resp = await getAudioList()
      setAudioLoading(false)
      const newAudioList = resp?.data?.data.concat(noAudio)
      setAudioList(newAudioList)
    } catch (error) {
      setAudioLoading(false)
    }
  }

  const fetchShipItemsByStatus = async () => {
    try {
      const res = await getShipItemsByStatusService('at_sea')
      setIsShipsLoading(false)
      setShipsAtSeaList(res.data.data)
    } catch (error) {
      setIsShipsLoading(false)
      return null
    }
  }

  return (
    <ShipManifestContext.Provider value={{ audioList, setAudioList, audioLoading, uploadAudio, isShipsLoading, shipsAtSeaLists, deleteAudio }}>{children} </ShipManifestContext.Provider>
  )
}

export default ShipManifestProvder
