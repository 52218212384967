import React, { useContext } from 'react'

import { progressData } from '../../constant/TrainingConstant'
import { ShipContext } from '../../context/ShipContext'
import { ProgressComponent, IDotProps, IDotsProps, ITitleProps, ShipItemFrame } from '../../types/progressBarTypes'
import { setStorageData } from '../../utils/localStorage'
import { getAllData } from '../IDB/idb'

const dotsStyle = {
  width: '5px',
  height: '10px',
  borderRadius: '25px',
  backgroundColor: '#fff',
  margin: '0 10px'
}

const dotsNotDoneStyle = {
  width: '5px',
  height: '10px',
  borderRadius: '25px',
  backgroundColor: '#B9B0C4',
  margin: '0 10px'
}

// eslint-disable-next-line react/prop-types
const ProgressWrapper: ProgressComponent = ({ children, style }) => {
  return (
        <div style={style}>{children}</div>
  )
}
const Dot: React.FC<IDotProps> = ({ style, progressOrder, subItemFrame, index, onChangeCallback }) => {
  // const videoPlayer = document.getElementById('trainingVideo') as HTMLVideoElement

  const { order, trainingVideoTime } = React.useContext(ShipContext)
  const handleClick = async () => {
    const indexedValues = await getAllData()
    // console.log({ trainingData })
    if (indexedValues.length > 0) {
      const trainingData: string[] = Object.values(indexedValues[0]?.trainingData)
      // @ts-ignore
      if (!trainingData.every((d) => d.length > 0) || trainingVideoTime !== progressData.find((d) => d.order === order)?.subItemsTimeFrame[4]?.time) return
      if (progressOrder === order) {
        let step = ''
        let key = ''
        if (index === 0) {
          step = 'start'
          key = 'manifest'
        } else if (index === 1) {
          step = 'spark_word'
          key = 'title'
        } else if (index === 2) {
          step = 'auditory_cue'
          key = 'audio'
        } else if (index === 3) {
          step = 'image_upload_step'
          key = 'image'
        } else {
          step = 'review_ship'
          key = 'image'
        }
        if (onChangeCallback) {
          if (indexedValues[0]?.trainingData[key].length > 0) {
            setStorageData('step', step)
            onChangeCallback(step)
          }
        }
        // setTrainingVideoTime(time)
      }
    }
  }
  return <div style={style} onClick={handleClick}></div>
}
const Dots: React.FC<IDotsProps> = ({ count, subItemFrame, title, progressOrder, onChangeCallback }) => {
  const { trainingVideoTime } = useContext(ShipContext)
  const newArray = Array.from({ length: count as number }, (_, index) => index + 1)
  return (
    <>
      {
        newArray.map((item, index) => {
          const time = subItemFrame.find((d: ShipItemFrame) => d.order === index)?.time as string
          return <Dot style={time <= trainingVideoTime ? dotsStyle : dotsNotDoneStyle} key={index} progressOrder={progressOrder} subItemFrame={subItemFrame} index={index} onChangeCallback={onChangeCallback}></Dot>
        })
      }
    </>
  )
}
const Title: React.FC<ITitleProps> = ({ title, style }) => {
  return <div style={style} className="progress-title">{title}</div>
}

ProgressWrapper.Dot = Dot
ProgressWrapper.Dots = Dots
ProgressWrapper.Title = Title

export default ProgressWrapper
