import { Stack } from '@mui/material'
import React from 'react'
import './index.css'

import { EditItemDataType } from '../../types/ShipTypes'
import Text from '../Text/Text'

const customStyle = {
  alignItems: 'center',
  justifyContent: 'space-between'
  // maxWidth: 'calc( 100% - 73px)'
}

interface ShipItemHeaderProps {
  header:string
  location: string
  shipId?: string
  setIsOpen: (isOpen: boolean) => void
  isForEdit: (val: EditItemDataType) => void
  textColor?: string
}

const ShipItemHeader:React.FC<ShipItemHeaderProps> = ({ header, location, shipId, setIsOpen, isForEdit, textColor }) => {
  const handleAddClick = () => {
    setIsOpen(true)
    isForEdit(null)
  }

  return (
    <Stack direction="row" spacing={2} sx={customStyle}>
        <div className='left__container'>
          <Text preset='h3' text={`${header} Ships | ${location === 'ship-arrived' ? 'Arrived' : 'At Sea'}`} style={{ color: textColor || '#000000' }}/>
        </div>
        {
          location !== 'ship-arrived' && (
           <div>
             <span className='right__container' onClick={() => handleAddClick()}>
               <Text preset='paragraph' text='ADD SHIP+' style={{ color: textColor || '#000000' }} />
             </span>
           </div>
          )}
    </Stack>
  )
}

export default ShipItemHeader
