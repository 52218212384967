import {
  PauseCircle,
  PlayCircle,
  SkipNext,
  SkipPrevious
} from '@mui/icons-material'
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
  Box,
  // ButtonBase,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Grid
} from '@mui/material'
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import './index.css'
// import { useNavigate } from 'react-router-dom'
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js'

import { ShipManifestContext } from '../../context/ShipManifestContext'
import { Asset } from '../../types'
import { AudioDetails } from '../../types/audioTypes'
import { ShipCategory, ShipItem } from '../../types/ShipTypes'
import { notifyUser } from '../../utils/notify'
import AudioCard from '../AudioCard'

import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
interface IProps {
  soundTrackRequired?: Boolean,
  controlInside?: boolean,
  showTitle?: boolean,
  showSubTitle?: boolean,
  leftGap?: boolean,
}
interface AudioDataProps {
  imageUrl: string
  audioUrl: string
  audioShowTime: boolean
  audioTotalTime?: number
  audioCurrentTime?: number
  title: string,
}

const ShipManifests: React.FC<IProps> = ({ soundTrackRequired = true, controlInside, showSubTitle, showTitle, leftGap = false }) => {
  const { audioList, uploadAudio, shipsAtSeaLists, deleteAudio, audioLoading, isShipsLoading } = useContext(ShipManifestContext)

  // const navigate = useNavigate()
  const inputFileRef = useRef<HTMLInputElement | null>(null)
  const [soundtrackSelectValue, setSoundtrackSelectValue] = useState(audioList[0]?.url)
  const [audioCardIndex, setAudioCardIndex] = useState(0)
  const [autoplay, setAutoplay] = useState(false)
  const [autoplaySoundtrack, setAutoplaySountrack] = useState(false)
  const [deleteId, setDeleteId] = useState<string | null>()
  const [remainingAudioCards, setRemainingAudioCards] = useState<
    AudioDataProps[]
  >([])

  // Main sound
  const wavesurferPlaybackRef = useRef<HTMLDivElement | null>(null)
  const [wavesurferPlayback, setWavesurferPlayback] =
    useState<WaveSurfer | null>(null)
  const [wavesurferPlaybackOptions, setWavesurferPlaybackOptions] =
    useState<WaveSurferOptions>({ container: '' })
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [totalTime, setTotalTime] = useState<number>(0)
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [audioUploadLoading, setAudioUploadLoading] = useState<boolean>(false)

  // Ambient soundtrack
  const soundtrackWavesurferPlaybackRef = useRef<HTMLDivElement | null>(null)
  const [soundtrackWavesurferPlayback, setSoundtrackWavesurferPlayback] =
    useState<WaveSurfer | null>(null)
  const [
    soundtrackWavesurferPlaybackOptions,
    setSoundtrackWavesurferPlaybackOptions
  ] = useState<WaveSurferOptions>({ container: '' })

  const initialAudioCardProps: AudioDataProps = {
    imageUrl: '',
    audioUrl: '',
    audioShowTime: false,
    title: ''
  }
  const [audioCardProps, setAudioCardProp] = useState([initialAudioCardProps])

  useEffect(() => {
    setSoundtrackSelectValue(audioList[0]?.url)
  }, [shipsAtSeaLists, audioList])

  useEffect(() => {
    const newDataProps: AudioDataProps[] = []
    shipsAtSeaLists.forEach((ship: ShipCategory) => {
      ship.shipItems.forEach((shipItem: ShipItem) => {
        const imageUrl = shipItem?.assets?.find((asset: Asset) => asset.model_name === 'SHIP-ITEM-IMAGE')?.url!
        const audioUrl = shipItem?.assets?.find((asset: Asset) => asset.model_name === 'SHIP-ITEM-AUDIO')?.url!
        const title = shipItem?.title

        const newData: AudioDataProps = {
          imageUrl,
          audioUrl,
          audioShowTime: false,
          title
        }
        newDataProps.push(newData)
      })
    })
    setAudioCardProp(newDataProps)
  }, [shipsAtSeaLists])

  useEffect(() => {
    if (!wavesurferPlaybackRef || !wavesurferPlaybackRef.current) {
      return
    }

    const wavesurfer = WaveSurfer.create({
      ...wavesurferPlaybackOptions,
      container: wavesurferPlaybackRef.current
    })
    setWavesurferPlayback(wavesurfer)

    return () => {
      wavesurfer.destroy()
    }
  }, [wavesurferPlaybackRef])

  useEffect(() => {
    if (!wavesurferPlayback) {
      return
    }

    const subscriptions = [
      wavesurferPlayback.on('ready', (duration) => {
        setIsPlaying(wavesurferPlayback.isPlaying())
        setCurrentTime(0)
        setTotalTime(duration)
        if (isPlaying) {
          wavesurferPlayback.play()
          wavesurferPlayback.setVolume(1)
        }
      }),
      wavesurferPlayback.on('play', () => setIsPlaying(true)),
      wavesurferPlayback.on('pause', () => setIsPlaying(false)),
      wavesurferPlayback.on('timeupdate', (currentTime) =>
        setCurrentTime(currentTime)
      ),
      wavesurferPlayback.on('finish', () => {
        setAudioCardIndex((prevState) => {
          let newState = prevState + 1
          if (newState >= audioCardProps.length) {
            newState = 0
          }
          return newState
        })
        setAutoplay(true)
      })
    ]

    return () => {
      subscriptions.forEach((unsub) => unsub())
      wavesurferPlayback.destroy()
    }
  }, [wavesurferPlayback])

  useEffect(() => {
    if (!wavesurferPlayback || !wavesurferPlaybackRef.current) {
      return
    }
    const wavesurfer = WaveSurfer.create({
      ...wavesurferPlaybackOptions,
      container: wavesurferPlaybackRef.current
    })
    setWavesurferPlayback(wavesurfer)

    const shouldPlay = async () => {
      if (wavesurfer && autoplay) {
        setIsPlaying(true)
        setAutoplay(true)
      }
    }
    shouldPlay()
  }, [wavesurferPlaybackOptions])

  useEffect(() => {
    setWavesurferPlaybackOptions((prevState) => ({

      ...prevState,

      url: audioCardProps[audioCardIndex]?.audioUrl
    }))
    setRemainingAudioCards(() => {
      const array1: AudioDataProps[] = []
      const array2: AudioDataProps[] = []
      audioCardProps?.forEach((item) => {
        array1.push(Object.assign({}, item))
        array2.push(Object.assign({}, item))
      })
      return [
        ...array1.splice(audioCardIndex),
        ...array2.splice(0, audioCardIndex)
      ].splice(1)
    })
  }, [audioCardIndex, shipsAtSeaLists, audioCardProps])

  useEffect(() => {
    if (
      !soundtrackWavesurferPlaybackRef ||
      !soundtrackWavesurferPlaybackRef.current
    ) {
      return
    }
    const wavesurfer = WaveSurfer.create({
      ...soundtrackWavesurferPlaybackOptions,
      container: soundtrackWavesurferPlaybackRef.current
    })
    setSoundtrackWavesurferPlayback(wavesurfer)
    return () => {
      wavesurfer.destroy()
    }
  }, [soundtrackWavesurferPlaybackRef])

  useEffect(() => {
    if (!soundtrackWavesurferPlayback) {
      return
    }

    soundtrackWavesurferPlayback.on('ready', (duration) => {
      if (autoplaySoundtrack) {
        soundtrackWavesurferPlayback.play()
        soundtrackWavesurferPlayback.setVolume(0.08)
      }
    })

    soundtrackWavesurferPlayback.on('finish', () => {
      if (autoplaySoundtrack) {
        soundtrackWavesurferPlayback.play()
        soundtrackWavesurferPlayback.setVolume(0.08)
      }
    })
    return () => {
      soundtrackWavesurferPlayback.destroy()
    }
  }, [soundtrackWavesurferPlayback])

  useEffect(() => {
    if (
      !soundtrackWavesurferPlayback ||
      !soundtrackWavesurferPlaybackRef.current
    ) {
      return
    }
    const wavesurfer = WaveSurfer.create({
      ...soundtrackWavesurferPlaybackOptions,
      container: soundtrackWavesurferPlaybackRef.current
    })
    setSoundtrackWavesurferPlayback(wavesurfer)

    const shouldPlay = async () => {
      if (autoplay) {
        setAutoplaySountrack(true)
      }
    }
    shouldPlay()
  }, [soundtrackWavesurferPlaybackOptions])

  useEffect(() => {
    if (audioList[0]) {
      setSoundtrackWavesurferPlaybackOptions((prevState) => ({
        ...prevState,
        url: soundtrackSelectValue
      }))
    }
  }, [soundtrackSelectValue, audioList])

  const onNextClick = () => {
    setAudioCardIndex((prevState) => {
      let newState = prevState + 1
      if (newState >= audioCardProps.length) {
        newState = 0
      }
      return newState
    })
  }

  const onPlayClick = async () => {
    if (!wavesurferPlayback || !soundtrackWavesurferPlayback) {
      return
    }
    if (!wavesurferPlayback.isPlaying()) {
      await wavesurferPlayback.play()
      wavesurferPlayback.setVolume(1)
    }
    if (!soundtrackWavesurferPlayback.isPlaying() && soundTrackRequired) {
      await soundtrackWavesurferPlayback.play()
      soundtrackWavesurferPlayback.setVolume(0.08)
    }
  }

  const onPauseClick = () => {
    if (!wavesurferPlayback || !soundtrackWavesurferPlayback) {
      return
    }
    if (wavesurferPlayback.isPlaying()) {
      wavesurferPlayback.pause()
    }
    if (soundtrackWavesurferPlayback.isPlaying()) {
      soundtrackWavesurferPlayback.pause()
    }
  }

  const onPreviousClick = () => {
    setAudioCardIndex((prevState) => {
      let newState = prevState - 1
      if (newState < 0) {
        newState = audioCardProps.length - 1
      }
      return newState
    })
  }

  const onUploadFileClick = () => {
    if (!inputFileRef || !inputFileRef.current) {
      return
    }

    inputFileRef.current.click()
  }

  const onUploadFileChange = async (e: ChangeEvent<HTMLInputElement | any>) => {
    setAudioUploadLoading(true)
    const files = e.target.files
    if (files[0].size > (7 * 1024 * 1024)) {
      notifyUser('File must be less than 7 mb', 'danger')
    } else {
      if (files && e.target.files.length > 0) {
        const audioData = {
          audio: e.target.files[0]
        }
        await uploadAudio(audioData)
      }
    }
    setAudioUploadLoading(false)
  }

  const handleAudioDelete = async (id: string) => {
    setDeleteId(id)
    await deleteAudio(id)
    setDeleteId(null)
  }

  const onSoundtrackSelectChange = async (event: SelectChangeEvent) => {
    if (event.target.value === '') {
      return
    }

    if (wavesurferPlayback && wavesurferPlayback.isPlaying()) {
      setAutoplaySountrack(true)
    }
    setSoundtrackSelectValue(event.target.value)
    wavesurferPlayback?.play()
  }

  if (audioLoading || isShipsLoading) {
    window.location.reload()
    return (
      <>
      Loading...
      </>
    )
  }

  return (
    <>
      <div className='ship-manifests__wrapper'>

        <Grid
          container spacing={2} className='mob-rev' >
          <Grid item xs={12} lg={6} >
            <Stack p={4} style={{ padding: 16 }}>
              <div
                id='wavesurferPlaybackRef'
                style={{ display: 'none' }}
                ref={wavesurferPlaybackRef}
              ></div>
              <div
                id='soundtrackWavesurferPlaybackRef'
                style={{ display: 'none' }}
                ref={soundtrackWavesurferPlaybackRef}
              ></div>
              {/* {
                soundTrackRequired && (
                  <ButtonBase sx={{ justifyContent: 'start' }} onClick={() => {
                    navigate('/calibrate')
                  }}>
                    <ArrowBackIosIcon fontSize={window.innerWidth >= 600 ? 'large' : 'small'} sx={{ color: '#2C4B64' }} />
                    <span className='breadcrumb-text'>Seven Knots</span>
                  </ButtonBase>
                )
              } */}
           <h1 className='page-title-text'>Ship Manifests</h1>

              <div
                className='ship-manifests__header'
              >
                {
                  !controlInside && <Stack direction='row'>
                    <IconButton
                      aria-label='Previous'
                      sx={{ padding: 0 }}
                      onClick={onPreviousClick}
                    >
                      <SkipPrevious
                        fontSize='large'
                        sx={{ color: '#355167', fontSize: '44px' }}
                      />
                    </IconButton>
                    <IconButton
                      aria-label='Play'
                      sx={{ display: !isPlaying ? 'block' : 'none', padding: 0 }}
                      onClick={onPlayClick}
                    >
                      <PlayCircle sx={{ color: '#355167', fontSize: '62px' }} />
                    </IconButton>
                    <IconButton
                      aria-label='Pause'
                      sx={{ display: isPlaying ? 'block' : 'none', padding: 0 }}
                      onClick={onPauseClick}
                    >
                      <PauseCircle sx={{ color: '#355167', fontSize: '62px' }} />
                    </IconButton>
                    <IconButton
                      aria-label='Next'
                      sx={{ padding: 0 }}
                      onClick={onNextClick}
                    >
                      <SkipNext
                        fontSize='large'
                        sx={{ color: '#355167', fontSize: '44px' }}
                      />
                    </IconButton>
                  </Stack>
                }

              </div>

              <>
   <h2 className='page-subtitle-text'>NOW PLAYING</h2>
                {
                  showSubTitle && <h2 className='page-subtitle-text'>NOW PLAYING</h2>
                }

                <Box sx={{ marginBottom: '8px', width: '100%' }}>

                  <AudioCard
                    {...audioCardProps[audioCardIndex]}
                    audioShowTime={true}
                    audioTotalTime={totalTime}
                    audioCurrentTime={currentTime}
                    playAudio={onPlayClick}
                    pauseAudio={onPauseClick}
                    isPlaying={isPlaying}
                    leftgap={leftGap}
                  />
                </Box>
                {

                  soundTrackRequired && (
                    <>
                      <h2 className='page-subtitle-text'>UP NEXT</h2>

                      <Stack>
                        {remainingAudioCards.map((audioCard, index) => (
                          <AudioCard {...audioCard} key={index} />
                        ))}
                      </Stack>
                    </>
                  )

                }
              </>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4}>
            <div style={{ marginTop: '55px' }}>

              {
                soundTrackRequired && (
                  audioUploadLoading
                    ? <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '10px' }}><p>Uploading Audio</p> <CircularProgress size={35} /></div>
                    : <Stack direction='row' style={{ width: window.innerWidth < 600 ? '100%' : 'unset', marginTop: '10px' }}>
                    <FormControl sx={{ minWidth: '255px', width: window.innerWidth < 600 ? '100%' : 'unset' }} size='medium'>
                      <InputLabel htmlFor='ambient-soundtrack-select'>
                        Ambient Soundtrack
                      </InputLabel>
                      <Select
                        id='ambient-soundtrack-select'
                        label='Ambient Soundtrack'
                        value={soundtrackSelectValue?.length > 0 ? soundtrackSelectValue : ''}
                        defaultValue={soundtrackSelectValue?.length > 0 ? soundtrackSelectValue : ''}
                        SelectDisplayProps={{
                          style: { paddingRight: '30px', paddingLeft: '30px', color: '#000' }
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              marginTop: '14px',
                              paddingTop: '10px',
                              paddingBottom: '10px',
                              borderRadius: '20px'
                            }
                          }
                        }}
                        onChange={onSoundtrackSelectChange}
                      >
                         {/* <MenuItem
                            value={}
                            sx={{
                              margin: '5px 22px',
                              padding: '8px 18px',
                              borderRadius: '10px'
                            }}
                          >
                           No Sound
                          </MenuItem> */}
                        {audioList?.map((soundtrack: AudioDetails, index: number) =>
                          <MenuItem
                            value={soundtrack?.url}
                            sx={{
                              margin: '5px 22px',
                              padding: '8px 18px',
                              borderRadius: '10px',
                              position: 'relative'
                            }}
                            key={index}
                          >
                            {soundtrack.name}
                            {
                              soundtrack.user_id && (
                                deleteId === soundtrack.id
                                  ? (
                                  <CircularProgress size={20} color='primary' style={{ position: 'absolute', right: '0', color: 'red' }} />
                                    )
                                  : (
                                  <CloseIcon
                                    className='close-icon'
                                    style={{ cursor: 'pointer', position: 'absolute', right: '0', color: 'red' }}
                                    onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                                      e.stopPropagation()
                                      handleAudioDelete(soundtrack.id)
                                    }}
                                  />
                                    )
                              )
                            }
                          </MenuItem>)
                        }
                        <MenuItem
                          value=''
                          sx={{
                            margin: '5px 22px',
                            padding: '8px 18px',
                            borderRadius: '10px',
                            fontWeight: 700,
                            color: '#2C4B64',
                            opacity: '0.6!important',
                            pointer: 'cursor'
                          }}
                          onClick={onUploadFileClick}
                        >
                          Upload Sound File
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <input
                      type='file'
                      id='file'
                      accept=".mp3, .wav, .aac"
                      ref={inputFileRef}
                      style={{ display: 'none' }}
                      onChange={onUploadFileChange}
                    />
                  </Stack>

                )
              }
            </div>
          </Grid>

        </Grid>

      </div>
    </>
  )
}

export default ShipManifests
