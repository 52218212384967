import React from 'react'

import Video from '../../components/video/7C/video'

const Clear = () => {
  // const body = 'Your dreams can be as big or small as you want, but they must be crystal CLEAR. This means each ship\'s cargo is so clearly defined that you will be able to identify the exact moment when it arrives.'
  return (
    <Video name='clear.mp4' title='Clear' body={''} />
  )
}

export default Clear
