import React, { useState } from 'react'

import './index.css'
import error from '../../assets/images/error.png'
import BasicModal from '../BasicModal/BasicModal'
import ContactUs from '../Contact'

const CustomError = () => {
  const [openContact, setOpenContact] = useState(false)
  const handleClick = () => {
    setOpenContact(true)
  }
  return (
    <>
     <BasicModal isOpen={openContact} header='Contact Us' body={<ContactUs setOpenContact={setOpenContact}/>} closeHandler={() => {}} labelCancel='' okHandler={() => setOpenContact(false)} labelOk='Close' cancelHandler={() => {}} />
    <div className='error-container'>
        <div className="error-wrapper">
            <div>
            <div className='title'>OOps!!</div>
            <div className='subtitle'>  Something Went Wrong</div>
            <a href="" className='refresh'>Refresh</a>
            <button onClick={handleClick} className='mob-help-btn' style={{ color: '#5E7689', marginTop: '15px', textAlign: 'center', fontSize: '12px', textDecoration: 'underline', backgroundColor: 'transparent', cursor: 'pointer', border: 'none' }}>Need Help?</button>
            </div>
            <img src={error} alt="" />
        </div>
    </div>
    </>

  )
}

export default CustomError
