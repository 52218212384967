import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { Logo } from '../../assets/images/icons'

import './navbar.css'
import { useContext } from 'react'

import { AuthContext } from '../../context/AuthContext'
const barStyle = {
  bgcolor: '#1D2432',
  height: '70px',
  padding: '5px',
  position: 'fixed',
  top: '0px',
  left: '0px'
}
const btnText = {
  fontFamily: 'Montserrat',
  marginRight: '10px'
}

const NavBar: React.FC = () => {
  const navigate = useNavigate()
  const { user, sidebarOpen, setSidebarOpen } = useContext(AuthContext)
  const handleLogoClick = () => {
    if (user.is_training_completed) {
      navigate('/ship-sea')
    }
  }
  const handleClick = () => {
    setSidebarOpen(!sidebarOpen)
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={barStyle} >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img className="nav-logo" src={Logo} width={'40px'} onClick={handleLogoClick} />
          </Typography>
          <Button color="inherit" sx={btnText} onClick={handleClick} >Dream Design System
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {
              sidebarOpen ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />
            }
          </IconButton>
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
export default NavBar
