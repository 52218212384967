import React from 'react'

import Video from '../../components/video/7C/video'

const Claim = () => {
  // const body = 'CLAIM is where you give yourself permission to receive and unload your cargo, gratefully celebrating and honoring the moment that you visualized. Take the time to bask in the moment you created and fully honor it.'
  return (
    <Video name='claim.mp4' title='Claim' body={''} />
  )
}

export default Claim
