import React, { createContext, useContext, useEffect, useState } from 'react'

import { api } from '../../services/api'
import { updateProfileImage, updateUserDetails } from '../../services/users.service/updateUser'
import { ApiResponse } from '../../types'
import { ProfileContextData, ProfileItem, ProfileItemUpdate, ProfileUpdate } from '../../types/profileTypes'
import { notifyUser } from '../../utils/notify'
import { AuthContext } from '../AuthContext'

interface ProfileProviderProps extends React.PropsWithChildren {

}
export const ProfileContext = createContext({} as ProfileContextData)
const ProfileProvder = ({ children }:ProfileProviderProps) => {
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [profileData, setProfileData] = useState<ProfileItem>()
  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          setLoadingProfile(true)
          const response = await api.get<ApiResponse<ProfileItem>>('/users')
          if (response?.data?.data) {
            setProfileData(response.data?.data)
          } else {
            notifyUser('no data found', 'danger')
          }
          setLoadingProfile(false)
        } catch (error: any) {
          setLoadingProfile(false)
        }
      })()
    }
  }, [isAuthenticated])

  const updateProfile = async (data:ProfileItemUpdate) => {
    try {
      setLoadingProfile(true)
      const resp = await updateUserDetails(data)
      if (resp.status === 200) {
        const newData = { ...profileData, ...resp.data.data }
        setProfileData(newData)
        notifyUser('User updated successfully', 'success')
        setLoadingProfile(false)
      }
    } catch (error:any) {
      notifyUser(error?.data?.message || 'Something went Wrong', 'danger')
      setLoadingProfile(false)
    }
  }

  const updateProfilePhoto = async (image: ProfileUpdate) => {
    try {
      setLoadingProfile(true)
      const resp = await updateProfileImage(image)
      if (resp.status === 200) {
        const newData = { ...profileData, image_url: resp.data.data.url }
        setProfileData(newData as ProfileItem)
        notifyUser('User updated successfully', 'success')
        setLoadingProfile(false)
      }
    } catch (error:any) {
      notifyUser(error?.data?.message || 'Something went Wrong', 'danger')
      setLoadingProfile(false)
    }
  }

  return (
    <ProfileContext.Provider value={{ loadingProfile, profileData, updateProfile, setProfileData, updateProfilePhoto }}>{children}</ProfileContext.Provider>
  )
}

export default ProfileProvder
