import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

import shipImg from '../../assets/images/ship.png'

const PageNotFound = () => {
  return (
    <div className='error-container'>
        <div className="error-wrapper">
            <div>
            <div className='title'>404!!</div>
            <div className='subtitle'>  Page Not Found</div>
            <Link to="/" className='refresh'>Home Page</Link>
            </div>
            <img src={shipImg} alt="" />
        </div>
    </div>
  )
}

export default PageNotFound
