import React, { useRef } from 'react'

import './index.css'
import { imageValidator } from '../../helper/imageValidator'
import { ShipItemParams } from '../../types/ShipTypes'
import { notifyUser } from '../../utils/notify'

interface DropzoneProps {
  formData:ShipItemParams
  setIsSelect:React.Dispatch<React.SetStateAction<number | null>>
  setFormData:React.Dispatch<React.SetStateAction<ShipItemParams>>
}

const Dropzone = ({ formData, setIsSelect, setFormData }:DropzoneProps) => {
  const dropRef = useRef<HTMLLabelElement>(null)
  const imageInput = useRef <HTMLInputElement>(null)

  const handleChange = (e:any) => {
    const files = e.target.files[0]
    const isImageValid = imageValidator(files)
    if (isImageValid) {
      setFormData({
        ...formData,
        image: files
      })
      setIsSelect(null)
    } else {
      notifyUser('Please upload a PNG or JPG image that is less than 7MB in size.', 'warning')
    }
  }

  return (
    <label className="drop-container" id="dropcontainer" ref={dropRef}>
    <span className="drop-title">Drop files here</span>
    <div className='drop-photo-prev'>
        {formData.image && <img src={typeof formData.image === 'string' ? formData.image : URL.createObjectURL(formData.image)} alt="asdasd" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}
    </div>
    or
    <input ref={imageInput} type="file" id="images" accept="image/*" onChange={handleChange} />
  </label>
  )
}

export default Dropzone
