import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useState } from 'react'
import './index.css'

import Text from '../../components/Text/Text'
import ProfileAccount from '../Account/ProfileAccount'
import EditForm from './EditForm'
import ViewForm from './ViewForm'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function ProfilePanel (props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

function a11ProfileProps (index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  }
}
const ProfileTabs: React.FC = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const [showForm, setShowForm] = useState(true)

  const setForm = () => {
    setShowForm(!showForm)
  }

  return (
    <div className='dashboard__profile__container'>
      <Box><Text preset='h3' text='Profile' style={{ color: '#000' }} /></Box>
      <Box sx={{ width: '100%' }}>
        <Box >
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={'Information'} {...a11ProfileProps(0)} />
            <Tab label={'Account'} {...a11ProfileProps(1)} />
          </Tabs>
        </Box>

        <ProfilePanel value={value} index={0}>

          {showForm ? <ViewForm setForm={setForm} /> : <EditForm setShowForm={setShowForm} showForm={showForm} />}

        </ProfilePanel>
        <ProfilePanel value={value} index={1}>
          <ProfileAccount />
        </ProfilePanel>
      </Box>

    </div >
  )
}

export default ProfileTabs
