import { Box, Card, CardContent, CircularProgress } from '@mui/material'
import React from 'react'

import { shipDemoImg } from '../../assets/images'
import { EditButton, TickIcon } from '../../assets/images/icons'
import { Asset, EditItemDataType, MoveShipItemToArrivedType, ShipItem } from '../../types/ShipTypes'
import Text from '../Text/Text'
import './index.css'
import { formatDate } from '../../utils/utils'

const cardStyle = {
  height: '239px',
  width: 'auto',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  backgrounPosition: 'center',
  backgroundRepeat: 'no-repeat'

}

interface shipProps {
  shipItemData: ShipItem
  location: string
  setIsOpen: (isOpen: boolean) => void
  isForEdit: (value: EditItemDataType) => void
  setIsForView?: (value: boolean) => void
  moveShipItemToArrived?: MoveShipItemToArrivedType
  viewAll?: Boolean
  isLoadingArrived?: boolean
  isLoadingAtSea?: boolean
  setIsMoveShipBoxOpen?: (isOpen: boolean) => void
  isMoveShipBoxOpen?: boolean
  setDeleteArrivedItem?:(value: ShipItem) => void
}

const ShipsCard: React.FC<shipProps> = (props) => {
  const assetArray = props.shipItemData.assets
  const imageAsset: Asset | undefined = assetArray.find((asset: Asset) => asset.model_name === 'SHIP-ITEM-IMAGE')
  const imageUrl = imageAsset?.url || shipDemoImg
  const atSeaClass = (props.location === 'ship-sea') ? 'cardoverlay sea' : 'cardoverlay arrived'

  const card = (
    <React.Fragment>
      <CardContent sx={{ ...cardStyle, backgroundImage: `url(${imageUrl})` }}>
      <div className='cardText'><Text preset='h3' text={props.shipItemData.title} style={{ color: '#FFFFFF', position: 'absolute', bottom: '0', width: '87%', overflowWrap: 'anywhere', lineHeight: '35px' }}/></div>
      </CardContent>
    </React.Fragment>
  )

  const handleEditClick = () => {
    props.setIsOpen(true)
    props.isForEdit(props.shipItemData)
    props.setIsForView && props?.setIsForView(false)
  }

  const handleViewClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    props.setIsOpen(true)
    props.isForEdit(props.shipItemData)
    props.setIsForView && props?.setIsForView(true)
  }

  const handleDoneButtion = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    if (props.moveShipItemToArrived) {
      props?.moveShipItemToArrived(props.shipItemData.ship_id, props.shipItemData.id)
    }
  }

  const handleBackToSeaButtion = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    props.setIsMoveShipBoxOpen && props.setIsMoveShipBoxOpen(true)
    props.setDeleteArrivedItem!(props.shipItemData)
  }

  return (
      <Box sx={{
        borderRadius: '40px',
        marginRight: '20px',
        marginTop: '10px',
        width: '30%',
        '@media (max-width: 600px)': {
          margin: '10px auto',
          width: '100%'
        }
      }}>

        <div className={atSeaClass} >
        <Card variant="outlined" sx={{ borderRadius: '40px' }} >{card}</Card>

        {
              (props.location === 'ship-sea')
                ? <div className='action__items'>
                    <div className='editbtn' onClick={() => handleEditClick()}>
                      <img src={EditButton} />
                    </div>
                    { !props.isLoadingArrived
                      ? <div className='tickIcon' onClick={(event) => handleDoneButtion(event)}>
                      <button className='btn'><img src={TickIcon} /> <span>Arrived</span></button>
                    </div>
                      : <div className='tickIcon' >
                      <button className='btn'><CircularProgress color='success' size='15px'/></button>
                    </div>
                    }
                    <div className='viewItems' onClick={(event) => handleViewClick(event)}>
                      <button className='btn'> <span>View</span></button>
                    </div>
                  </div>

                : <div><div className='editbtn ship-arrived-constainer'>
                  <Text preset='paragraph' text={'Arrival'} style={{ color: 'white', textAlign: 'right', margin: 0, fontWeight: '600' }}/>
                  <Text preset='paragraph' text={`${formatDate(props.shipItemData.arrival_date as string)}`} style={{ color: 'white', textAlign: 'right', margin: 0, fontWeight: '600' }}/>
                  </div>
                  { !props.isLoadingAtSea
                    ? <div className='tickIcon ship-arrived-container' onClick={(event) => handleBackToSeaButtion(event)}>
                    <button className='btn'><img src={TickIcon} /> <span>Back to sea</span></button>
                </div>
                    : <div className='tickIcon ship-arrived-container' >
                      <button className='btn'><CircularProgress color='success' size='15px'/></button>
                    </div>
                    }
                    </div>

           }
        </div>

    </Box>
  )
}

export default ShipsCard
