import React from 'react'

import Video from '../../components/video/7C/video'

const Clues = () => {
  // const body = 'When you start having this level of clarity paths will begin to open up and opportunities will start flooding in. CLUES means seeing and following these trusted signals to pivot, move, and make course corrections, and to say "yes" every time a step opens up to get closer to your dream.'
  return (
    <Video name='clues.mp4' title='Clue' body={''} />
  )
}

export default Clues
