import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Grid, TextField, FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'
import { CardCvcElement, CardNumberElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import * as yup from 'yup'

// import { setStorageData } from '../../utils/localStorage'
import { AuthContext } from '../../context/AuthContext'
import { SubscriptionContext } from '../../context/SubscriptionContext'
import { notifyUser } from '../../utils/notify'
import Text from '../Text/Text'
import { paymentFormSchema } from './AuthValidation'
import CouponPaymentForm from './couponPaymentForm'

interface PaymentFormProps {
  // handleStepChange: (step: string, inputs?: any) => void
}

const PaymentForm: React.FC<PaymentFormProps> = () => {
  const [isChecked, setIsChecked] = useState(false)
  const [cardNumberError, setCardNumberError] = useState('')
  const [cvcError, setCvcError] = useState('')
  const [expiryError, setExpiryError] = useState('')
  // const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()

  const { addUpdatePaymentMethod, dataLoading } = useContext(SubscriptionContext)
  const { signOut } = useContext(AuthContext)

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }

  const finalValidationSchema = () => {
    if (isChecked) {
      return paymentFormSchema.shape({
        couponCode: yup.string().required('Coupon code is required')
      })
    }
    return paymentFormSchema
  }

  const { values, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      billingAddress: '',
      fullName: '',
      couponCode: ''
    },
    validationSchema: finalValidationSchema,
    onSubmit: async (values) => {
      if (!stripe || !elements) {
        return
      }

      const cardElement = elements.getElement('cardNumber')

      if (!cardElement) {
        return
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      })

      if (error) {
        notifyUser(error?.message || 'Invalid Card', 'danger')
      } else {
        const paymentMethodId = paymentMethod.id
        addUpdatePaymentMethod(paymentMethodId)
      }
    }
  })

  const handleError = (event: any) => {
    const { elementType, error } = event

    switch (elementType) {
      case 'cardNumber':
        setCardNumberError(error?.message || '')
        break
      case 'cardCvc':
        setCvcError(error?.message || '')
        break
      case 'cardExpiry':
        setExpiryError(error?.message || '')
        break
      default:
        break
    }
  }

  return (
    <>
      <Text preset='eyebrow' text="Last Step" style={{ textAlign: 'center', marginBottom: 0 }} />
      <Text preset="h1" text="Account Info" style={{ textAlign: 'center', marginTop: '0' }} />
      <FormGroup sx={{ marginBottom: '25px' }}>
        <FormControlLabel control={<Checkbox checked={isChecked} onChange={handleChangeEvent} />} label="Member of a paid
organization?" style={{
          justifyContent: 'flex-end'
        }}
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '16px',
              textAlign: 'center',
              color: '#000000',
              maxWidth: '130px'

            }
          }}
        />
      </FormGroup>

      {
        isChecked
          ? <>
            <CouponPaymentForm />
          </>
          : <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <><Grid item xs={12} lg={6}>
                  <Text preset="label" text="FULL NAME" />
                  <TextField fullWidth value={values.fullName} onChange={handleChange} name="fullName" error={!!(touched.fullName && errors.fullName)}
                    helperText={touched.fullName && errors.fullName} />
                </Grid>
                  <Grid item xs={12} lg={6}>
                    <Text preset="label" text="CARD NUMBER" />
                    <fieldset style={{ background: '#FFFFFF', border: '2px solid #E8E8E8', boxShadow: '0px 20px 25px rgba(103, 103, 103, 0.0509804)', borderRadius: '15px', padding: '20px 12px' }}
                    >
                      <CardNumberElement onChange={handleError} />
                    </fieldset>
                    <p className="custom-error light-red">{cardNumberError}</p>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Text preset="label" text="CVC" />
                    <fieldset style={{ background: '#FFFFFF', border: '2px solid #E8E8E8', boxShadow: '0px 20px 25px rgba(103, 103, 103, 0.0509804)', borderRadius: '15px', padding: '20px 12px' }}>
                      <CardCvcElement onChange={handleError} />
                    </fieldset>
                    <p className="custom-error light-red">{cvcError}</p>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Text preset="label" text="EXPIRATION" />
                    <fieldset style={{ background: '#FFFFFF', border: '2px solid #E8E8E8', boxShadow: '0px 20px 25px rgba(103, 103, 103, 0.0509804)', borderRadius: '15px', padding: '20px 12px' }}>
                      <CardExpiryElement onChange={handleError} />
                    </fieldset>
                    <p className="custom-error light-red">{expiryError}</p>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Text preset="label" text="BILLING ADDRESS" />
                    <TextField value={values.billingAddress} fullWidth onChange={handleChange} name="billingAddress" error={!!(touched.billingAddress && errors.billingAddress)}
                      helperText={touched.billingAddress && errors.billingAddress} />
                  </Grid></>

              </Grid>
              <Grid container justifyContent="center" className="mt-10">
                <Button
                  size="large"
                  type="submit"
                  className="form-submit-button"
                  disabled={dataLoading}
                >
                  {
                    dataLoading ? <CircularProgress /> : 'Finish'
                  }
                </Button>

              </Grid>
            </form>
            <hr />
            <div style={{ textAlign: 'center' }}>
               <Button
                  size="large"
                  type="submit"
                  onClick={() => signOut()}

                >
                  <ArrowBackIosNewIcon />
                  Logout
                </Button>
                </div>
          </>
      }

    </>
  )
}

export default PaymentForm
