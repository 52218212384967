/* eslint-disable */
import {
  AxiosDefaults,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios'

import { deleteStorage, getStorageDate, setStorageData } from '../utils/localStorage'
import { notifyUser } from '../utils/notify'
import { api } from './api'
import { tokenUpdateService } from './tokenUpdateService'
interface IFailedRequestQueue {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}

let isRefreshing = false
let failedRequestQueue: IFailedRequestQueue[] = []

export function setAuthorizationHeader (
  request: AxiosDefaults | AxiosRequestConfig | any,
  token: string
) {
  request.headers.Authorization = `Bearer ${token}`
}

const handleRefreshToken = async (refreshToken: string | null) => {
  isRefreshing = true
  if (refreshToken) {
    try {
      const response = await api.post('/auth/token', { refresh_token: refreshToken })
        const { token } = response.data.data

        setStorageData('TOKEN', token)
        setAuthorizationHeader(api.defaults, token)

        failedRequestQueue.forEach((request) => request.onSuccess(token))
        failedRequestQueue = []
    } catch (error: any) {
        failedRequestQueue.forEach((request) => request.onFailure(error))
        failedRequestQueue = []
        deleteStorage()
    }
    isRefreshing = false
  } else {
    tokenUpdateService.send(0)
  }
}

function onRequest (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  const token = getStorageDate('TOKEN')
  token ? setAuthorizationHeader(config, token) : setAuthorizationHeader(config, '')

  return config
}

function onRequestError (error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error)
}

function onResponse (response: AxiosResponse): AxiosResponse {
  return response
}

function onResponseError (
  error: AxiosError<{error: {code?: number, message?: string, details?: {message: string, param: string}[]}}>
) {
  if (error?.response?.status === 401) {
    if (error.response?.data?.error.message === 'jwt expired') {
      const originalConfig = error.config
      const refreshToken = getStorageDate('REFRESH_TOKEN')
      if(!isRefreshing) {
        handleRefreshToken(refreshToken).then(() => {
          return new Promise((resolve, reject) => {
            failedRequestQueue.push({
              onSuccess: (token: string) => {
                setAuthorizationHeader(originalConfig, token)
                if (originalConfig) {
                  resolve(api(originalConfig))
                } else {
                  reject(error)
                }
              },
              onFailure: (error: AxiosError) => {
                reject(error)
              }
            })
          })
        }).catch((error: any) => {
          return Promise.reject(error)
        })
      }

      
    } else {
      deleteStorage()
    }
  }
  if (error?.response?.status === 500) {
    notifyUser('Something went wrong', 'danger')
  }
  return Promise.reject(error)
}

export function setupInterceptors (axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}
