import { BehaviorSubject } from 'rxjs'

const subscriber = new BehaviorSubject(1)
// const shipOrderForTraining = new BehaviorSubject(1)
const currentRecordingTime = new BehaviorSubject<string | null>('00:00')

const tokenUpdateService = {
  send: function (message: number) {
    subscriber.next(message)
  }
}

// const shipOrderUpdateService = {
//   send: function (order: number) {
//     subscriber.next(order)
//   }
// }

const updateCurrentRecordingTime = {
  send: function (order: string) {
    currentRecordingTime.next(order)
  }
}

export {
  tokenUpdateService,
  // shipOrderUpdateService,
  // shipOrderForTraining,
  updateCurrentRecordingTime,
  currentRecordingTime,
  subscriber
}
