import './footer.css'
import React from 'react'

import { FacebookIcon, FooterLogo, InstagramIcon, TwitterIcon } from '../../assets/images/icons'
const index = () => {
  const navitems = [
    { id: 1, Title: 'Home', url: 'https://www.dreambigtribe.com/' },
    { id: 2, Title: 'Product', url: 'https://www.dreambigtribe.com/products' },
    { id: 3, Title: 'Pricing', url: 'https://www.dreambigtribe.com/pricing' },
    { id: 4, Title: 'About', url: 'https://www.dreambigtribe.com/about' },
    { id: 5, Title: 'Dream Design', url: 'https://www.dreambigtribe.com/dream-design' },
    { id: 6, Title: 'Contact', url: 'https://www.dreambigtribe.com/contact-pages/sign-up' }
  ]

  const socialitems = [
    { id: 1, Title: 'Twitter', url: 'https://tribalry.com/login', image: TwitterIcon },
    { id: 2, Title: 'Instagram', url: 'https://tribalry.com/login', image: InstagramIcon },
    { id: 3, Title: 'Facebook', url: 'https://tribalry.com/login', image: FacebookIcon }
  ]

  return (
    <div className='container'>
      <div className='footer__container'>
        <div className='footer__component'>
          <div className='footer__inner'>
            <div className='footer__left'>
              <div className='footer_logo'>
                <img src={FooterLogo} alt='dreambig' />
              </div>
              <p>“It’s time to get serious about your dreams. Chart your course, launch this dream chasing expedition, and watch your ships come into harbor.”</p>
            </div>
            <div className='footer__nav'>
              <h2>NAVIGATION</h2>
              <ul>
                {navitems.map((navitem) =>
                  <li key={navitem.id}><a href={navitem.url}>{navitem.Title}</a></li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className='footer__lower'>
          <div className='copyright__container' >
            <p>Dream Big © 2022  |  All Right Reserved</p>
          </div>
          <div className='footer__socialIcon'>
            <ul>
              {socialitems.map((socialitem) =>
                <li key={socialitem.id}><a href={socialitem.url}><img src={socialitem.image} /></a></li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default index
