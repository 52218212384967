import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'

import { NavBar } from '../components/NavBar'
import Sidebar from '../components/Sidebar'
import './index.css'
import { AuthContext } from '../context/AuthContext'

const DashboardLayout = () => {
  const { sidebarOpen } = useContext(AuthContext)
  return (<>
    <NavBar />
    <Box
      sx={{
        marginTop: '10px',
        Width: '100%',
        padding: '0px',
        margin: '0px'
        // transition: '0.5s'
      }}

      className={'main-wrapper'}
    >
      <Sidebar />
      <div className={`${sidebarOpen ? 'isClose' : ' isOpen'}`} style={{ marginTop: '80px' }}>
      <Outlet />
      </div>

    </Box>
  </>)
}

export default DashboardLayout
