import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'

import Text from '../../components/Text/Text'
import { formatDateString } from '../../helper/formatDateString'
import { PaymentTableProps } from '../../types/accountTypes'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function PaymentTable (props:PaymentTableProps) {
  const { data } = props
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell><Text preset='paragraph' text="Date" style={{ lineHeight: '10px', color: '#959595' }} /></StyledTableCell>
            <StyledTableCell><Text preset='paragraph' text="Type" style={{ lineHeight: '10px', color: '#959595' }} /></StyledTableCell>
            <StyledTableCell><Text preset='paragraph' text="Amount" style={{ lineHeight: '10px', color: '#959595' }} /></StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {data?.bills?.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
              <Text preset='paragraph' text={formatDateString(item.created_at)} style={{ lineHeight: '10px', color: '#151517', fontWeight: 'bold' }} />
              </StyledTableCell>
              <StyledTableCell><Text preset='paragraph' text={item.type} style={{ lineHeight: '10px', color: '#151517', fontWeight: 'bold' }} /></StyledTableCell>
              <StyledTableCell><Text preset='paragraph' text={item.amount} style={{ lineHeight: '10px', color: '#151517', fontWeight: 'bold' }} /></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
