import { Button } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

import Text from '../../Text/Text'
const Video:React.FC<{name: string, title: string, body?: string}> = ({ name, title, body }) => {
  const location = useLocation()
  const handleClick = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <div style={{ width: '80%', paddingTop: '20px' }}>
      <Text preset="h1" text={title} style={{ marginTop: '0', marginBottom: '0', marginLeft: '20px' }} />
      {
        body && (
          <p style={{ marginLeft: '20px', marginTop: '0', fontFamily: 'Montserrat', padding: '20px 10px', background: '#5E7689', borderRadius: '10px', color: 'white', lineHeight: '25px', wordSpacing: '3px' }}>
            {body}
          </p>
        )
      }
      <div style={{ marginLeft: '20px', border: '3px solid #b9b9b9', borderRadius: '10px' }}>
        <video controls width={'100%%'} height={'auto'} style={{ margin: 'auto' }} autoPlay >
          <source src={`https://d3fzisqnsukjql.cloudfront.net/training_video/${name}`} type="video/mp4" />
        </video>
      </div>

      {
        location.pathname === '/crew' && <div style={{
          display: 'flex',
          gap: '20px',
          justifyContent: 'center',
          marginTop: '20px',
          marginBottom: '20px'
        }}>
          <Button
            size="small"
            type="submit"
            className="image-upload__submit-btn"
            style={{ maxWidth: '320px' }}
            onClick={() => handleClick('https://tribalry.com/join/dreambig-tribe')}
          >
            Sign up for free online community
          </Button>
          <Button
            size="small"
            type="submit"
            className="image-upload__submit-btn"
            onClick={() => handleClick('https://www.dreambigtribe.com/contact-pages/sign-up')}
          >
            Sign up for a Dream Team
          </Button>
        </div>
      }
    </div>
  )
}

export default Video
