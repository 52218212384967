import { IconButton, Avatar } from '@material-ui/core'
import React, { useState } from 'react'

const RoundedImageUploader = () => {
  const [selectedImage, setSelectedImage] = useState('')

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0]
    // Perform any additional validation or processing if needed
    const urlFile = URL.createObjectURL(file)
    setSelectedImage(urlFile)
  }

  return (
    <div>
      <input
        accept="image/*"
        id="contained_button_file"
        multiple={false}
        type="file"
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />
      <label htmlFor="contained_button_file">
        <IconButton component="span">
          {selectedImage
            ? (
            <Avatar
              src={selectedImage}
              alt="Uploaded Image"
              style={{
                width: '60px',
                height: '60px'
              }}
            />
              )
            : (
            <Avatar
              style={{
                width: '60px',
                height: '60px'
              }}
            >
                {/* <ProfileImage /> */}

            </Avatar>
              )}
        </IconButton>
      </label>
    </div>
  )
}

export default RoundedImageUploader
