import React from 'react'

import Congratulation from '../../components/Congratulation/congratulation'

const GetStarted = () => {
  const text = (
    <div style={{ textAlign: 'left' }}>
      {/* <strong style={{ textAlign: 'center', display: 'block' }}>
        You&apos;re now a Certified Dreamer!
      </strong> */}
      <div style={{ padding: '10px 0' }}>
        To begin your DreamBig Journey, we will first guide you through your Dream Design Session. <br /> <br />
        This experience will take roughly 90-120 minutes to complete, and <strong>can be completed in one session or multiple sessions. If you choose to break it up into multiple sessions,</strong> your progress will be saved and you can return at a later time and pick up where you left off.  <br /> <br />
        Ideally, the Dream Design should be done in a time and place that you can concentrate without distraction.  <br /> <br />
        It&apos;s important to view this as a &quot;wet clay&quot; or rough draft experience. Instead of attempting to make each &quot;ship&quot; perfect, just focus on coming up with at least three solid dreams for each of the five sections.  <br /> <br />
        <strong>  Once your session is complete, the system will unlock and you’ll have access to the full toolset and be able to </strong>
      go back and add more dreams as well as refine the ones you already created.
      </div>
      <strong style={{ textAlign: 'center', display: 'block' }}>
        Bon Voyage!
      </strong>
    </div>
  )
  const path = 'training'
  const title = 'Success'
  const subtitle = 'Congrats, Lets\'s Start Dreaming.'
  return (
    <Congratulation buttonText={'Begin your dream design'} path={path} text={text} title={title} subtitle={subtitle} />
  )
}

export default GetStarted
