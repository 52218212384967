import Avatar from '@mui/material/Avatar'
import React, { useContext, useEffect, useState } from 'react'
import './index.css'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  CalibrateIcon,
  ProfileIcon,
  ShipSeaIcon,
  LogoutIcon,
  OnlineCommunity, SevenSeaTraining, DailyCalibrate, ShipArrived
} from '../../assets/images/icons'
import { AuthContext } from '../../context/AuthContext'
import { ProfileContext } from '../../context/profileContext'
import { updateBookmark } from '../../services/bookmarks.services/bookmark'
import { getStorageDate } from '../../utils/localStorage'
import BasicModal from '../BasicModal/BasicModal'
import ContactUs from '../Contact'
import { deleteData } from '../IDB/idb'
import Text from '../Text/Text'
import SidebarTabs from './SidebarTabs'

const Sidebar: React.FC = () => {
  const currentLink = useLocation()
  const [currentLocation, setCurrentLocation] = useState<string>(currentLink.pathname)
  const [openContact, setOpenContact] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [deviceType, setDeviceType] = useState<string | null>(null)

  const { profileData } = useContext(ProfileContext)
  const { sidebarOpen, setSidebarOpen } = useContext(AuthContext)
  const { signOut, user } = useContext(AuthContext)
  // eslint-disable-next-line no-unused-vars

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [location, setLocation] = useState('callibrate')

  useEffect(() => {
    setLocation(pathname.split('/').slice(-1)[0])
  }, [pathname])

  useEffect(() => {
    setCurrentLocation(currentLink.pathname)
  }, [currentLink.pathname])

  useEffect(() => {
    const userAgent = navigator.userAgent

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Safari/i.test(userAgent)) {
      setDeviceType('Mobile')
    } else if (/iPad/i.test(userAgent)) {
      setDeviceType('Tablet')
    } else {
      setDeviceType('Desktop')
    }
  }, [])
  useEffect(() => {
    if (user?.is_training_completed && user.is_training_enabled) {
      setSidebarOpen(!sidebarOpen)
    }
    if (deviceType !== null && deviceType === 'Mobile') {
      setSidebarOpen(true)
    }
    if (currentLink.pathname !== currentLocation && deviceType !== 'Mobile') {
      setSidebarOpen(false)
    }
  }, [user, deviceType, currentLink.pathname])
  const handleLogout = async () => {
    try {
      await updateBookmark(getStorageDate('videoTime') || '00:00', user.videoBookmark?.training_data?.audioId)
      deleteData()
      deleteData(2)
      signOut()
    } catch (error: any) {
    }
  }
  const handleCollaps = () => {
    setSidebarOpen((prev: boolean) => !prev)
  }

  const navItem = [
    {
      title: 'Ships | At Sea',
      to: 'ship-sea',
      icon: <ShipSeaIcon style={{ fill: location === 'ship-sea' ? 'black' : 'white' }} />,
      active: user?.is_training_completed && user?.is_training_enabled,
      show: true
    },
    {
      title: 'Ships | Arrived',
      to: 'ship-arrived',
      icon: <ShipArrived style={{ fill: location === 'ship-arrived' ? 'black' : 'white' }} />,
      active: user?.is_training_completed && user?.is_training_enabled,
      show: true
    },
    // {
    //   title: 'Ships | Manifests',
    //   to: 'ship-manifest',
    //   icon: <ShipArrivedIcon style={{ fill: location === 'ship-manifest' ? 'black' : 'white' }} />,
    //   active: user?.is_training_completed
    // },
    // {
    //   title: '7 Knots',
    //   to: 'calibrate',
    //   icon: <CalibrateIcon style={{ fill: location === 'calibrate' ? 'black' : 'white' }} />,
    //   active: user?.is_training_completed
    // },
    {
      title: 'Daily Calibrate',
      to: 'daily-calibrate',
      icon: <DailyCalibrate style={{ fill: location === 'calibrate' ? 'black' : 'white' }} />,
      active: user?.is_training_completed && user?.is_training_enabled,
      show: true
    },
    {
      title: '7C\'s Training',
      to: 'seven-sea',
      icon: <SevenSeaTraining style={{ fill: location === 'seven-sea' ? 'black' : 'white' }} />,
      active: user?.is_training_completed && user?.is_training_enabled,
      show: true
    },
    {
      title: 'Dream Design',
      to: 'training',
      icon: <CalibrateIcon style={{ fill: location === 'training' ? 'black' : 'white' }} />,
      active: true,
      show: !user?.is_training_enabled
    },
    {
      title: 'Online Community',
      to: 'https://tribalry.com/login',
      icon: <OnlineCommunity style={{ fill: location === 'online-platform' ? 'black' : 'white' }} />,
      active: user?.is_training_completed && user?.is_training_enabled,
      show: true
    },
    {
      title: 'Profile',
      to: 'profile',
      icon: <ProfileIcon style={{ fill: location === 'profile' ? 'black' : 'white' }} />,
      active: user?.is_training_completed && user?.is_training_enabled,
      show: true
    }

  ]

  return (
    <>
      <BasicModal isOpen={openContact} header='Contact Us' body={<ContactUs setOpenContact={setOpenContact} />} closeHandler={() => { }} labelCancel='' okHandler={() => setOpenContact(false)} labelOk='Close' cancelHandler={() => { }} />

      <div className='sidebar__main__container' style={{ position: 'relative' }}>
        <div className={sidebarOpen ? 'sidebar__component_toggle' : ' sidebar__component '}>
          <div className="profile__component">
            <Avatar alt="profile image" className='headerOption__icon' src={profileData?.image_url} sx={{ width: 40, height: 40, marginLeft: '11px' }} />
            <div className='profile__info'>
              <Text preset='h4' text={profileData?.first_name || ''} style={{ lineHeight: '10px', color: '#ffffff', textTransform: 'capitalize' }} />
              <div className='mob_email'>
                <Text preset='eyebrow' text={profileData?.email || ''} />

              </div>
            </div>
            <button onClick={handleCollaps} className='mob_close'>X</button>
          </div>
          <BasicModal body="" cancelHandler={() => setOpenDialog(false)} closeHandler={() => setOpenDialog(false)} header="You need to complete training to unlock these features." isOpen={openDialog} labelCancel="" labelOk="Close" okHandler={() => setOpenDialog(false)} />
          <div className='sideitems__container' style={{ position: 'relative' }}>
            <ul className='nav__items'>
              {navItem.map((item: { title: string; to: string, icon: React.ReactElement, active: boolean, show:boolean }, index: number) => {
                if (item.show) {
                  return (
                    <SidebarTabs key={index} onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      if (item.title === 'Online Community') {
                        if (e.ctrlKey || e.metaKey) {
                          const newTab = window.open(item.to, '_blank')
                          newTab?.focus()
                        } else {
                          if (item.active) {
                            window.open(item.to, '_blank')
                          } else {
                            setOpenDialog(true)
                          }
                        }
                      } else {
                        if (e.ctrlKey || e.metaKey) {
                          const newTab = window.open(item.to, '_blank')
                          newTab?.focus()
                        } else {
                          if (item.active) {
                            navigate(`/${item.to}`)
                          } else {
                            setOpenDialog(true)
                          }
                        }
                      }

                      // setActiveToggle(!isActive)
                    }} location={location} to={item.to} icon={item.icon} title={item.title} active={item.active} />
                  )
                }
                return null
              })}
              <div style={{ marginTop: '25px', borderTop: '1px solid #95AFB9', paddingTop: '15px' }}>
                <SidebarTabs onClick={() => { handleLogout() }} location={location} icon={<LogoutIcon style={{ fill: 'white' }} />} title='Log out' active={true} />
              </div>
            </ul>

          </div>

        </div>
        <button onClick={() => setOpenContact(true)} className='mob-sidebar-help' style={{ position: 'absolute', bottom: '100px', left: '10px', color: '#fff', textAlign: 'center', fontSize: '12px', textDecoration: 'underline', backgroundColor: 'transparent', cursor: 'pointer', border: 'none' }}>
          Need Help?
        </button>
      </div>

    </>

  )
}
export default Sidebar
