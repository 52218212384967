import React from 'react'

import AccountProvder from './AccountContext'
import { AuthProvider } from './AuthContext'
import DepositPhotoProvder from './DepositPhotoContext'
import ProfileProvder from './profileContext'
import { ShipProvider } from './ShipContext'
import ShipManifestProvder from './ShipManifestContext'
import SubscriptionProvder from './SubscriptionContext'

interface DreamDesignProviderProps extends React.PropsWithChildren {

}

export const DreamDesignProvider: React.FC<DreamDesignProviderProps> = ({ children }) => {
  return (

    <AuthProvider>
      <SubscriptionProvder>
        <DepositPhotoProvder>
        <ShipProvider>
          <ProfileProvder>
            <AccountProvder>
              <ShipManifestProvder>
              {children}
              </ShipManifestProvder>
            </AccountProvder>
          </ProfileProvder>
        </ShipProvider>
        </DepositPhotoProvder>

      </SubscriptionProvder>
    </AuthProvider>

  )
}
