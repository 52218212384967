import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import React, { useContext, useEffect, useState } from 'react'
import './index.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfiniteScroll from 'react-infinite-scroll-component'

import { AuthContext } from '../../context/AuthContext'
import { DepositPhotoContext } from '../../context/DepositPhotoContext'
import { useDebounce } from '../../hooks/UseDebounce'
import { ShipItemParams } from '../../types/ShipTypes'
import Dropzone from '../Dropzone'
// import NoDataFound from '../NoDataFound'

interface DepositPhotoProps {
  category: string
  setFormData: React.Dispatch<React.SetStateAction<ShipItemParams>>
  formData: ShipItemParams,
  showDropzone?: boolean

}

const DepositPhotos = ({ category, setFormData, formData, showDropzone = true }: DepositPhotoProps) => {
  const { isAuthenticated, user } = useContext(AuthContext)
  const [isSelect, setIsSelect] = useState<number | null>(null)
  const [searchOffset, setSearchOffset] = useState<number>(0)
  const { depositPhotos, fetchDepositPhotos } = useContext(DepositPhotoContext)
  const [searchQuery, setSearchQuery] = useState<string>(category)

  const debouncedSearch = useDebounce(searchQuery, 1500)

  useEffect(() => {
    if (isAuthenticated && user?.is_subscribe) {
      fetchDepositPhotos(debouncedSearch, searchOffset).then()
    }
  }, [isAuthenticated, user, debouncedSearch, searchOffset])

  const handlePhotoClick = (index: number, item: string, depositURL:string = '') => {
    setIsSelect(index === isSelect ? null : index)
    setFormData({
      ...formData, image: index === isSelect ? null : item, depositURL
    })
  }

  const handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    setSearchQuery(e.target.value)
  }
  const loadNext = () => {
    setSearchOffset((prev:number) => prev + 18)
  }

  return (
    <div className='deposit-photo-wrapper' >
      <div>
        {
          showDropzone && <Dropzone formData={formData} setFormData={setFormData} setIsSelect={setIsSelect} />
        }

      </div>
      <hr />
      <div >
        <div >
          <input type="text" className='photo-search' onChange={handleSearch} placeholder='Search Photo Here' />
        </div>
        <Box>

          <InfiniteScroll next={loadNext} dataLength={depositPhotos && depositPhotos?.length} hasMore={true}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  } >

                  <Grid container spacing={2} sx={{ width: 'auto', maxWidth: '100%' }}>{
                    depositPhotos?.map((item: any, index: number) => {
                      return (
                          <Grid item xs={6} md={4} lg={3} sx={{ position: 'relative', width: 'auto' }} key={index} onClick={() => handlePhotoClick(index as number, item?.huge_thumb, item?.url as string)} >
                            <img src={item.huge_thumb} alt="" className={`image-preview ${isSelect === index && 'image-select'}`} />
                            {
                              isSelect === index && <div className='image-checked'>
                                <CheckCircleIcon />
                              </div>
                            }

                          </Grid>
                      )
                    })

                  }
                  </Grid>
                </InfiniteScroll>
        </Box>
      </div>

    </div>
  )
}

export default DepositPhotos
