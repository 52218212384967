import React from 'react'

import './CalibrateCards.css'
// import { StartWhiteSVG } from '../../utils/svgs'
import Text from '../Text/Text'

/* eslint-disable indent */
const CalibrateCards: React.FC = () => {
    const items = [
      { color: '#132235', bgColor: '#0000004f', name: 'Island Visualization', img: 'https://d3fzisqnsukjql.cloudfront.net/static/media/calibrate.jpg', body: 'begin', path: 'calibrate', title: 'Calibrate', text: 'To CALIBRATE means consistently aligning your body and mind to the frequency of the ship you are bringing to shore. You do this by creating a time where you calm your mind and then visualize your ships coming to shore.' },
      { color: '#132235', bgColor: '#0000004f', name: 'Island Visualization', img: 'https://d3fzisqnsukjql.cloudfront.net/static/media/crew.jpg', body: 'begin', path: 'crew', title: 'Crew', text: 'You only have a 5% chance of NOT reaching your dream if you are consistently reporting your progress to a supportive friend or group. CREW means committing to a plan of action with another dreamer or group of dreamers, then creating an accountability meeting or method.' },
        { color: '#132235', bgColor: '#0000004f', name: 'Island Visualization', img: 'https://d3fzisqnsukjql.cloudfront.net/static/media/clues.jpg', body: 'begin', path: 'clues', title: 'Clues', text: 'When you start having this level of clarity paths will begin to open up and opportunities will start flooding in. CLUES means seeing and following these trusted signals to pivot, move, and make course corrections, and to say "yes" every time a step opens up to get closer to your dream.' },
      { color: '#132235', bgColor: '#0000004f', name: 'Island Visualization', img: 'https://d3fzisqnsukjql.cloudfront.net/static/media/claim.jpg', body: 'begin', path: 'claim', title: 'Claim', text: 'CLAIM is where you give yourself permission to receive and unload your cargo, gratefully celebrating and honoring the moment that you visualized. Take the time to bask in the moment you created and fully honor it.' },
      { color: '#132235', bgColor: '#0000004f', name: 'Island Visualization', img: 'https://d3fzisqnsukjql.cloudfront.net/static/media/calm.jpg', body: 'begin', path: 'calm', title: 'Calm', text: 'Your thoughts are the wind that will either fill your sails or blow them off course. CALM means that you create ideal sailing conditions by finding consistent ways to quiet your internal thoughts to keep your mental environment at seven knots, ideal sailing conditions.' },
      { color: '#132235', bgColor: '#0000004f', name: 'Island Visualization', img: 'https://d3fzisqnsukjql.cloudfront.net/static/media/clear.jpg', body: 'begin', path: 'clear', title: 'Clear', text: 'Your dreams can be as big or small as you want, but they must be crystal CLEAR. This means each ship\'s cargo is so clearly defined that you will be able to identify the exact moment when it arrives.' },
      { color: '#132235', bgColor: '#0000004f', name: 'Island Visualization', img: 'https://d3fzisqnsukjql.cloudfront.net/static/media/cues.jpg', body: 'begin', path: 'cues', title: 'Cues', text: 'Once you are clear on your dreams, you will then create create two CUES for each dream--one that you hear and one that you see. You will do this by creating an audio recording of yourself saying each manifest and finding a picture that represents each dream.' }
    ]

  const handleClick = (path: string) => {
      window.location.href = path
  }

    return (
        <>
            {items.map((item: { name: string, img: string, body: string, bgColor: string, color: string, path: string, title: string, text: string }, index) => (
              <>
                <Text preset='h3' text={item.title} style={{ marginTop: '0', marginBottom: '0' }} />
                <div className='calibrate-card__item' key={index} style={{
                    backgroundImage: `url(${item.img})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
                     onClick={() => handleClick(item.path)}
                >
                  <div className='calibrate-item__content' style={{
                    background: item.bgColor
                  }} />
                    {/* <div style={{ position: 'absolute', zIndex: 3, top: '30px', right: '30px', display: 'flex', alignItems: 'center', gap: '24px' }}> */}
                    {/*  <Text preset='paragraph' text={item.body.toUpperCase()} */}
                    {/*        style={{ */}
                    {/*          marginTop: '0', */}
                    {/*          marginBottom: '0', */}
                    {/*          textAlign: 'end', */}
                    {/*          maxWidth: 200, */}
                    {/*          lineHeight: 'normal', */}
                    {/*          color: '#ffffff', */}
                    {/*          letterSpacing: '0.5em' */}
                    {/*        }} /> */}
                    {/*  <StartWhiteSVG newPath={item.path} /> */}
                    {/* </div> */}
                    {/* <div className='calibrate-card__bottom'>
                       <p style={{
                         margin: '0',
                         textAlign: 'justify',
                         lineHeight: 'normal',
                         fontFamily: 'Montserrat'
                       }}>
                         {item.text}
                       </p>
                    </div> */}
                </div>
                </>
            ))}
        </>
    )
}
export default CalibrateCards
