/* eslint-disable indent */
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const CloseSVG: React.FC = () => {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13L14 0.999977" stroke="black" />
            <path d="M1 1L14 13" stroke="black" />
        </svg>
    )
}
export const StartBlackSVG: React.FC<{newPath?: string}> = ({ newPath }) => {
    const navigate = useNavigate()
    const handleClick = () => {
      navigate(`/${newPath}`)
    }

    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
            <g clipPath="url(#clip0_514_3670)">
                <path d="M15.4635 19.61L17.3999 21.5328L23.8499 15.0828L17.3999 8.61914L15.4635 10.5555L18.559 13.6373H6.88623V16.3646H18.709L15.4635 19.61Z" fill="#132235" />
                <path d="M15 30C23.2773 30 30 23.2773 30 15C30 6.72273 23.2773 0 15 0C6.72273 0 0 6.72273 0 15C0 23.2773 6.72273 30 15 30ZM15 2.72727C21.7636 2.72727 27.2727 8.23636 27.2727 15C27.2727 21.7636 21.7636 27.2727 15 27.2727C8.23636 27.2727 2.72727 21.7636 2.72727 15C2.72727 8.23636 8.23636 2.72727 15 2.72727Z" fill="#132235" />
            </g>
            <defs>
                <clipPath id="clip0_514_3670">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg >
    )
}
export const StartWhiteSVG: React.FC<{newPath?: string}> = ({ newPath }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/${newPath}`)
  }
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick} style={{ cursor: 'pointer' }}>
            <g clipPath="url(#clip0_514_3681)">
                <path d="M15.4635 19.61L17.3999 21.5328L23.8499 15.0828L17.3999 8.61914L15.4635 10.5555L18.559 13.6373H6.88623V16.3646H18.709L15.4635 19.61Z" fill="white" />
                <path d="M15 30C23.2773 30 30 23.2773 30 15C30 6.72273 23.2773 0 15 0C6.72273 0 0 6.72273 0 15C0 23.2773 6.72273 30 15 30ZM15 2.72727C21.7636 2.72727 27.2727 8.23636 27.2727 15C27.2727 21.7636 21.7636 27.2727 15 27.2727C8.23636 27.2727 2.72727 21.7636 2.72727 15C2.72727 8.23636 8.23636 2.72727 15 2.72727Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_514_3681">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
