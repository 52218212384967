export const getStorageDate = (key: string) => {
  return localStorage.getItem(key)
}

export const setStorageData = (key: string, data: string) => {
  localStorage.setItem(key, data)
}

export const deleteStorage = (key?: string) => {
  key ? localStorage.removeItem(key) : localStorage.clear()
}
