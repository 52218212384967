import { shipName } from '../components/training/utils'
import { ProgressDataInterface, ShipInfo } from '../types/progressBarTypes'
export const trainingList = [
  {
    order: 1,
    manifestExamples: [
      'I just completed fifty push-ups in a single session.',
      'I just stood on the scale and confirmed that I\'ve reached my target weight of 180 lbs.',
      'I just won my first professional pickleball game in my division.',
      'I am standing at the peak of Mt Kilimanjaro.',
      'I just held a full backbend for a full minute.',
      'My husband just confirmed that I\'ve completely stopped snoring.',
      'My doctor just told me that my autoimmune condition is gone.',
      'I am crossing the finish line of the Kokoro 50-hr challenge.',
      'I can see perfectly without contacts or glasses.',
      'I just woke up from my twentieth night of uninterrupted sleep.',
      'I’m laying in savasana at the last session of a month-long exploration into yoga.',
      'I am finishing the final leg of my solo bike ride across the Alps.',
      'Three people have now commented on my good posture.'
    ]
  },
  {
    order: 2,
    manifestExamples: [
      'I have written down five things that I\'m grateful for a full month.',
      'I have gone an entire week without yelling.',
      'I stopped to celebrate a major win instead of just moving on to the next thing.',
      'I acted spontaneously and did something fun without encouragement.',
      'I was unapologetically myself at a time when I normally bend to others expectations.',
      'I have gone a full month without any social media.',
      'I just dropped off the final bag to Goodwill and my house is officially clutter free.',
      'I listened to the inspiration I felt, and acted on it immediately.',
      'I took a current world issue, researched it and wrote out the arguments on both sides.',
      'I just finished a full month giving sincere compliments to my kids daily.',
      'I\'ve now had three people tell me I’m a great host.',
      'I went to three networking events and connected with ten new people.',
      'I just finished having a ten-minute conversation with someone completely in Spanish.'
    ]
  },
  {
    order: 3,
    manifestExamples: [
      'I am in a golf cart heading out to the first hole of Pebble Beach.',
      'I just finished building a new crib for my new grandchild.',
      'I just crossed my final state line and have now been to all fifty states.',
      'I am watching the kick-off live at the Super Bowl.',
      'I just harvested an elk on my guided hunt in Colorado.',
      'I am serving up delicious food cooked for my monthly dinner group of friends.',
      'I am on a stage performing improv.',
      'I’m on the interstate, riding my Harley for my summer road trip.',
      'The audience went wild as I played my keyboard solo with my band.',
      'I am in a jeep watching a pride of lions pace around the savannah.',
      'I’m putting the finishing touches on the smoker I built out of a wine barrel.',
      'I’m skating at the Rockefeller Center on Christmas Day in New York.',
      'I am walking out of the arena - healthy and ecstatic- after riding a bull for 8 seconds.',
      'I am watching the moon slide across the sun in a full solar eclipse.'
    ]
  },
  {
    order: 4,
    manifestExamples: [
      'I’m making my final descent before obtaining my pilot’s license.',
      'I just accepted my third speaking invitation this month.',
      'I am watching my YouTube channel as it hits 1 million views.',
      'We are visiting our new mountain cabin making incredible family memories with my kids and grandkids.',
      'I am being interviewed by a major magazine about how I came up with the idea for my disruptive business model.',
      'I am holding my newborn baby in my arms filled with overwhelming love for the newest member of our family.',
      'I am signing the first copy of my newly published book.',
      'I am finishing my annual Grandma Adventure Day with my last grandchild.',
      'I am visiting one of the ten centers using our treatment model for substance abuse with a 90% success rate.',
      'I am introducing myself to my students on the first day of class as their university professor.',
      'I am in a theater watching the film that I created the soundtrack for.',
      'I am digging into a delectable salad made of veggies I grew in my garden from seeds.',
      'I am in the audience at a gala hearing the stories of each of the underprivileged youth that my fund gave a start to.'
    ]
  },
  {
    order: 5,
    manifestExamples: [
      'I have ten hours blocked out on my calendar each week to work on creative projects.',
      'I am driving through my twenty acres of land as the rays from the sunset filter through the trees.',
      'I’m paying the last semester tuition for my youngest child.',
      'I just left a $100 tip for excellent service.',
      'I’m on my yacht with my friends and family',
      'I am watching the final payment for my mortgage clear the bank.',
      'I look around our team meeting and realize each leadership seat is filled with the perfect fit.',
      'My first $20,000 monthly salary check was just posted to my account.',
      'We are hanging out with the family at our Lake House in Sweden.',
      'As we traveled, we discovered our taxi driver needed an appliance he couldn’t afford, and we gave him the needed money in cash.',
      'I’m looking at the P&L statement of the company I founded, and see that we made $500,000 in profits last year.',
      'I am standing inside my luxurious theater venue, watching people’s eyes widen as they walk through the door.',
      'I just quit my full-time job because income from my invention has replaced my required income.'
    ]
  }

]

export const progressData: ProgressDataInterface[] = [
  {
    title: 'Intro',
    introTime: '00:00',
    subItemsTimeFrame: [
      { time: '00:36', order: 0, step: 'start' },
      { time: '01:12', order: 1, step: 'spark_word' },
      { time: '01:48', order: 2, step: 'auditory_cue' },
      { time: '02:24', order: 3, step: 'image_upload_step' },
      { time: '03:01', order: 4, step: 'review_ship' }
    ],
    isDone: true,
    count: 5
  },
  {
    title: 'Calm',
    introTime: '03:01',
    subItemsTimeFrame: [
      { time: '03:59', order: 0 },
      { time: '04:57', order: 1 },
      { time: '05:55', order: 2 },
      { time: '06:53', order: 3 },
      { time: '07:52', order: 4 }
    ],
    isDone: true,
    count: 5
  },
  {
    title: 'Clear',
    introTime: '07:52',
    subItemsTimeFrame: [
      { time: '08:27', order: 0 },
      { time: '09:02', order: 1 },
      { time: '09:37', order: 2 },
      { time: '10:12', order: 3 },
      { time: '10:47', order: 4 }
    ],
    isDone: true,
    count: 5
  },
  {
    title: 'Health',
    introTime: '10:48',
    subItemsTimeFrame: [
      { time: '12:44', order: 0, step: 'start' },
      { time: '13:07', order: 1, step: 'spark_word' },
      { time: '14:42', order: 2, step: 'auditory_cue' },
      { time: '14:46', order: 3, step: 'image_upload_step' },
      { time: '14:58', order: 4, step: 'review_ship' }
    ],
    isDone: true,
    count: 5,
    order: 1
  },
  {
    title: 'Self',
    isDone: true,
    introTime: '14:59',
    subItemsTimeFrame: [
      { time: '18:50', order: 0, step: 'start' },
      { time: '19:03', order: 1, step: 'spark_word' },
      { time: '19:11', order: 2, step: 'auditory_cue' },
      { time: '19:20', order: 3, step: 'image_upload_step' },
      { time: '19:24', order: 4, step: 'review_ship' }
    ],
    count: 5,
    order: 2
  },
  {
    title: 'Play',
    isDone: false,
    introTime: '19:26',
    subItemsTimeFrame: [
      { time: '22:50', order: 0, step: 'start' },
      { time: '23:04', order: 1, step: 'spark_word' },
      { time: '23:11', order: 2, step: 'auditory_cue' },
      { time: '23:18', order: 3, step: 'image_upload_step' },
      { time: '23:23', order: 4, step: 'review_ship' }
    ],
    count: 5,
    order: 3
  },
  {
    title: 'Passion',
    isDone: false,
    introTime: '23:24',
    subItemsTimeFrame: [
      { time: '26:28', order: 0, step: 'start' },
      { time: '26:46', order: 1, step: 'spark_word' },
      { time: '26:54', order: 2, step: 'auditory_cue' },
      { time: '26:57', order: 3, step: 'image_upload_step' },
      { time: '27:01', order: 4, step: 'review_ship' }
    ],
    count: 5,
    order: 4
  },
  {
    title: 'Wealth',
    isDone: false,
    introTime: '27:02',
    subItemsTimeFrame: [
      { time: '29:58', order: 0, step: 'start' },
      { time: '30:15', order: 1, step: 'spark_word' },
      { time: '30:23', order: 2, step: 'auditory_cue' },
      { time: '30:27', order: 3, step: 'image_upload_step' },
      { time: '30:31', order: 4, step: 'review_ship' }
    ],
    count: 5,
    order: 5
  },
  {
    title: 'Finish',
    isDone: false,
    introTime: '30:32',
    subItemsTimeFrame: [
      { time: '01:04', order: 0 },
      { time: '02:08', order: 1 },
      { time: '03:12', order: 2 },
      { time: '04:16', order: 3 },
      { time: '05:20', order: 4 }
    ],
    count: 0,
    order: 6
  }
]

export const audioLists = [
  { value: 1, label: 'DreamScape', url: 'https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3' },
  { value: 2, label: 'Bali Sunrise', url: 'https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3' },
  { value: 3, label: 'Bali Sunrise', url: 'https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3' },
  { value: 4, label: 'Bali Sunrise', url: 'https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3' },
  { value: 5, label: 'Bali Sunrise', url: 'https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3' },
  { value: 6, label: 'Bali Sunrise', url: 'https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3' }
]

export const ProgressData = [
  { order: 1, name: 'Health' },
  { order: 2, name: 'Self' },
  { order: 3, name: 'Play' },
  { order: 4, name: 'Passion' },
  { order: 5, name: 'Wealth' },
  { order: 6, name: 'Finishing Touches' },
  { order: 7, name: 'Congratulations!' }
]
export const manifestDesc = (order:number, shipData: ShipInfo[] | undefined) => {
  const count = shipData?.find((d: ShipInfo) => d.order === order)?.count
  const ordninalsData = [
    { count: 0, rank: 'first' },
    { count: 1, rank: 'second' },
    { count: 2, rank: 'third' },
    { count: 3, rank: 'fourth' },
    { count: 4, rank: 'fifth' }
  ]

  const ordinal = ordninalsData.find((ord) => ord.count === count)?.rank
  return (
    `Think of a statement that defines your ${ordinal} personal Manifest Statement for ‘${shipName(order)}’. Be sure to use as much detail as possible.`
  )
}

export const auditoryText = (order:number) => `Record your voice saying the ‘${shipName(order)}’ Spark Word and then the Manifest Statement.`

export const sparkWordText = (order:number) => `Think of one word that distills your ‘${shipName(order)}’ manifest into a single 'spark' and type it below.`
export const trainingDesc = 'Congratulations! You have completed the Dream Design. Give yourself a pat on the back for taking this very important step and moving closer to achieving your dreams!'

export const uploadText = (order:number) => `Find and upload an image that corresponds with your ‘${shipName(order)}‘ Spark Word and Manifest Statement.`

export const manifestReviewDesc = (order:number) => `Review your completed ‘${shipName(order)}’ manifest below. Click back on the Timeline to make any necessary changes.`
export const ShipItemLimit = 3

export const sparkWordexample = [{
  order: 1, text: 'Rested, Energized, Healed, Finish Line.'
},
{
  order: 2, text: 'Backbone, Serene, Focused, Hilarious, Swagger.'
},
{
  order: 3, text: 'Harley, Lake, Next, Safari, Aurora, St. Andrews'
},
{
  order: 4, text: 'Fund, Composer, Growth, Mentor, Kids, Inventions.'
},
{
  order: 5, text: 'Getaway, Help, Friends, Millions, Free, Land.'
}
]
