// import { Container } from '@mui/material'
import { Outlet } from 'react-router-dom'

import Logo from '../../assets/images/logo.png'
import Footer from '../../components/Footer/index'
import '../../pages/Register/register.component.css'
import './index.css'

export function AuthenticationPage () {
  return (
    <>
      <img src={Logo} alt='Logo' className='logo'/>
      <div className='background'></div>
      <div className='containerStyle'>
      <Outlet />
      </div>
      <Footer />
    </>
  )
}
