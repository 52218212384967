import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'

import inspIcon from '../../assets/images/icons/insprationIcon.png'
import { manifestDesc } from '../../constant/TrainingConstant'
import { TrainingProps } from '../../types/trainingTypes'
import { setStorageData } from '../../utils/localStorage'
import CustomButton from '../Button'

const TrainingStart: React.FC<TrainingProps> = ({ handleFormCallback, data, onChangeCallback, order, shipData, handleInspiration, indexedDBdata }) => {
  const [isDisable, setIsDisable] = useState(indexedDBdata?.manifest?.length === 0 || indexedDBdata?.manifest === undefined)
  const [manifestLengthCount, setManifestLengthCount] = useState(0)
  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (handleFormCallback) {
      const shouldSetValue = e.target.value.length <= 200 && e.target.value.length > 0
      if (shouldSetValue) {
        setManifestLengthCount(e.target.value.length)
        setIsDisable(false)
        handleFormCallback(e)
      } else {
        if (e.target.value.length === 0) setManifestLengthCount(0)
        setIsDisable(true)
      }
    }
  }

  const handleForm: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const val = e.currentTarget.id
    setStorageData('step', 'manifest_statement')
    onChangeCallback(val)
  }

  const handleShowInspiration = () => {
    handleInspiration()
  }

  return (
    <>
      <Typography color="white">
        {manifestDesc(order, shipData)}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', margin: '35px 0' }}>
        <div>
          <img src={inspIcon} alt="" />
        </div>
        <button style={{ textDecoration: 'underline', color: '#fff', marginLeft: '25px', background: 'transparent', border: 'none', cursor: 'pointer' }} onClick={handleShowInspiration} >Need Some Inspiration?</button>
        {/* <a href="" style={{ textDecoration: 'underline', color: '#fff', marginLeft: '25px' }}>Need Some Inspiration?</a> */}
        {/* <Typography sx={{ textDecoration: 'underline', color: '#fff', marginLeft: '25px' }}>Need Some Inspiration?</Typography> */}
      </Box>
      <div className='text-area-wrapper'>
        <textarea name="" className="text-area" id="manifest" placeholder="Manifest Statement" defaultValue={indexedDBdata?.manifest || data?.manifest} onChange={handleChange} maxLength={200}/>
        <Typography color="white" sx={{ position: 'absolute', buttom: '20px', left: '30px' }}>Characters Left - <strong>{`${manifestLengthCount}/200`}</strong></Typography>
        <div className='mbo-center'>

        <CustomButton eventHandle={handleForm} buttonText="NEXT" formStage='manifest_statement' isDisable={isDisable} />
        </div>
      </div>
    </>
  )
}

export default TrainingStart
