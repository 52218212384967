/* eslint-disable */
import Text from '../../components/Text/Text'

import './styles.css'
import DailyCalibrate from '../../components/DailyCalibrate/DailyCalibrate'
import { Grid } from '@material-ui/core'
import { dailyCalibrate } from '../../constant/DailyCalibrate'
import {StartWhiteSVG} from "../../utils/svgs";
import React from "react";
import audioCuseImage from "../../assets/images/audio_cue.png"

const imageUrl = 'https://images.unsplash.com/photo-1693491228225-0095ad2672d8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyNHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60'


const CalibrateBanner = () => {
  const styles: any = {
    wrapperAudioCue: {
      width: '100%',
      height: '250px',
      borderRadius: '25px',
      position: 'relative',
      background: `url(${imageUrl}) no-repeat`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      marginTop: '18px',
      // marginBottom: '50px'
    },
    wrapper: {
      width: '100%',
      height: '250px',
      borderRadius: '25px',
      position: 'relative',
      background: `url(${audioCuseImage}) no-repeat`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      marginTop: '18px',
      // marginBottom: '50px'
    },
    sideOverlay: {
      width: '250px',
      height: '100%',
      backgroundColor: '#5e7689e7',
      position: 'absolute',
      top: '0px',
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    bigFont: {
      fontSize: '170px',
      color: '#fff',
      fontWeight: 'bolder',
      opacity: '0.4'
    },
    content: {
      color: '#fff',
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: ' 50%',
      transform: ' translate(-50%, -50%)',
      fontSize: '35px',
      fontWeight:'bolder'

    },
    mainContent: {
      backgroundColor: '#5E7689',
      color: '#fff',
      borderRadius: '10px',
      padding: '25px 20px',
      fontSize: '18px',
      marginTop: '-50px'

    }
  }

  const handleClick = () => {
    window.location.href = 'island-visualization'
  }
  const handleClick2 = () => {
    window.location.href = 'audio-cues'
  }


  return (
    <div className='mob-daily-calibrate-wrapper' style={{ paddingLeft: '40px' }}>
      <Text preset='h2' text='Daily Calibrate' style={{ marginTop: '10px', marginBottom: '20px', paddingTop: '20px' }} />
      <Grid container spacing={2} style={{ marginBottom: '100px' }} >
        <Grid item xs={12} lg={5}>
          <div style={styles?.wrapperAudioCue} className='visualization-wrapper' onClick={handleClick}>
            <div style={styles?.sideOverlay}>
              <div style={styles.bigFont}>
                {dailyCalibrate.first.index}
              </div>
              <div style={styles.content}>
                {dailyCalibrate.first.title}
              </div>
            </div>
            {/*<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', right: '20px', position: 'absolute', gap: '10px' }}>*/}
            {/*  <Text preset='paragraph' text='Begin'*/}
            {/*        style={{*/}
            {/*          lineHeight: 'normal',*/}
            {/*          color: '#ffffff',*/}
            {/*          letterSpacing: '0.5em'*/}
            {/*        }} />*/}
            {/*  <StartWhiteSVG newPath='island-visualization' />*/}
            {/*</div>*/}
          </div>
        </Grid>
        <Grid item xs={12} lg={5}>
    {/* <DailyCalibrate /> */}

     <div style={styles?.wrapper} className='visualization-wrapper' onClick={handleClick2}>
            <div style={styles?.sideOverlay}>
              <div style={styles.bigFont}>
                {dailyCalibrate.second.index}
              </div>
              <div style={styles.content}>
                {dailyCalibrate.second.title}
              </div>
            </div>
            {/*<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', right: '20px', position: 'absolute', gap: '10px' }}>*/}
            {/*  <Text preset='paragraph' text='Begin'*/}
            {/*        style={{*/}
            {/*          lineHeight: 'normal',*/}
            {/*          color: '#ffffff',*/}
            {/*          letterSpacing: '0.5em'*/}
            {/*        }} />*/}
            {/*  <StartWhiteSVG newPath='island-visualization' />*/}
            {/*</div>*/}
          </div>

        </Grid>
         <Grid item xs={12} lg={2}>

        </Grid>

      </Grid>
  
    </div>
  )
}

export default CalibrateBanner
