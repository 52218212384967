import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { AuthContext } from '../context/AuthContext'

interface IPublicRoute {
  children: React.JSX.Element
}
// eslint-disable-next-line space-before-function-paren
export function PublicRoute({ children }: IPublicRoute) {
  const { isAuthenticated, user } = useContext(AuthContext)
  const location = useLocation()
  if (isAuthenticated && !location.pathname.includes('reset-password') && !location.pathname.includes('profile-image')) {
    return <Navigate to={'/ship-sea'} />
  }
  if (!user?.is_subscribe && location.pathname.includes('profile-image')) {
    return children
  }

  return children
}
