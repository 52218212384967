import { DefaultValues } from '../../components/Contact'
import { ApiResponse } from '../../types'
import { ProfileItem, ProfileItemUpdate, ProfileUpdate, ProfileUpdateResponse } from '../../types/profileTypes'
import { api } from '../api'

export const updateUserDetails = async (data:ProfileItemUpdate) => {
  return api.put<ApiResponse<ProfileItem>>('/users', data)
}
export const updateProfileImage = async (image:ProfileUpdate) => {
  return api.post<ApiResponse<ProfileUpdateResponse>>('/users/upload-profile-image', image, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const contactUs = async (data: DefaultValues) => {
  return api.post<ApiResponse<{ message: string }>>('/contact-us', data)
}
