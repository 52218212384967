import { ApiResponse, ShipItem } from '../../types'
import { ShipCategory } from '../../types/ShipTypes'
import { api } from '../api'

// export function fetchAllShipItems () {
//   return api({
//     url: '/ship-items',
//     method: 'GET'
//   })
// }

export async function getShipItemsByStatusService (status: string) {
  const res = await api.get<ApiResponse<ShipCategory[]>>(
    '/ships/ship-items/all', { params: { status } })

  return res
}

export async function updateShipService (shipCategoryId: string, body: FormData, shipItemId: string) {
  return api.put<ApiResponse<ShipItem>>(
    `/ships/${shipCategoryId}/ship-items/${shipItemId}`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export async function getShipItemsByCategoryService (shipCategoryId: string, status: string) {
  const res = await api.get<ApiResponse<ShipItem[]>>(
    `/ships/${shipCategoryId}/ship-items`, { params: { status } })

  return res
}

export async function moveShipItemToArrivedService (shipCategoryId: string, shipItemId: string) {
  return await api.patch<ApiResponse<ShipItem>>(
    `/ships/${shipCategoryId}/ship-items/${shipItemId}/status`,
    { status: 'arrived' }
  )
}

export async function moveShipItemToSeaService (shipCategoryId: string, shipItemId: string) {
  return await api.patch<ApiResponse<ShipItem>>(
    `/ships/${shipCategoryId}/ship-items/${shipItemId}/status`,
    { status: 'at_sea' }
  )
}

export async function createShipItemService (shipCategoryId: string, body: any) {
  return await api.post<ApiResponse<ShipItem>>(
    `/ships/${shipCategoryId}/ship-items`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export async function deleteShipItemService (shipCategoryId: string, shipItemId: string) {
  return await api.delete<ApiResponse<ShipItem[]>>(
    `/ships/${shipCategoryId}/ship-items/${shipItemId}`
  )
}
