/* eslint-disable multiline-ternary */
/* eslint-disable indent */
import { Grid } from '@mui/material'
import React from 'react'

import Text from '../Text/Text'
import './Input.css'

interface Props {
    ariaLabel?: string;
    bgColor?: string;
    disabled?: boolean;
    handler: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    inputCustomStyle?: string;
    inputType?: 'input' | 'textarea';
    name: string;
    placeholder: string;
    readOnly?: boolean;
    required?: boolean;
    rows?: number;
    text: string;
    type?: string;
    value: string | number;
}
const Input: React.FC<Props> = ({
    ariaLabel,
    bgColor = '#ffffff',
    disabled,
    handler,
    inputCustomStyle,
    inputType = 'input',
    name,
    placeholder,
    readOnly = false,
    required = false,
    rows,
    text,
    type = 'text',
    value
}) => {
    return (
        <Grid item xs={12} sm={6} xl={6} md={6}>
            <div className='input-container'>
                <Text preset='smallParagraph' text={text.toUpperCase()} style={{ color: '#222222', letterSpacing: '0.5em', marginTop: '0', marginBottom: '0', marginLeft: '10px', fontFamily: 'Montserrat', fontWeight: 600 }} />
                <div className='input-content w100' style={{ background: bgColor }}>
                    {inputType === 'input' ? (
                        <input
                            aria-label={ariaLabel || placeholder}
                            disabled={disabled}
                            readOnly={readOnly}
                            name={name}
                            type={type}
                            className={`${(disabled || readOnly) ? 'input disabled' : 'input'} ${inputCustomStyle}`}
                            placeholder={placeholder}
                            onChange={handler}
                            value={value}
                            required={required}
                        />
                    ) : (
                        <textarea
                            aria-label={ariaLabel || placeholder}
                            disabled={disabled}
                            readOnly={readOnly}
                            name={name}
                            className={`${(disabled || readOnly) ? 'input disabled' : 'input'} ${inputCustomStyle}`}
                            placeholder={placeholder}
                            onChange={handler}
                            value={value}
                            required={required}
                            rows={rows}
                            style={{ resize: 'none' }}
                        />
                    )}
                </div>
            </div>
        </Grid>
    )
}
export default Input
