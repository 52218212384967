import { openDB } from 'idb'

const DB_NAME = 'dreamDesignDB'
const DB_VERSION = 1
const STORE_NAME = 'trainingStore'

export const openDatabase = () => {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade (db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' })
      }
    }
  })
}

export const addData = async (data: any) => {
  const db = await openDatabase()
  const tx = db.transaction(STORE_NAME, 'readwrite')
  const store = tx.objectStore(STORE_NAME)
  await store.put(data)
}

export const getAllData = async () => {
  const db = await openDatabase()
  const tx = db.transaction(STORE_NAME, 'readonly')
  const store = tx.objectStore(STORE_NAME)
  return store.getAll()
}

export const deleteData = async (key = 1) => {
  const db = await openDatabase()
  const tx = db.transaction(STORE_NAME, 'readwrite')
  const store = tx.objectStore(STORE_NAME)

  // keyPath is set 1 for all
  store.delete(key)
}
