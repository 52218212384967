export const dailyCalibrate = {
  first: {
    index: 1,
    title: 'Island Visualization',
    description: 'Calm your thinking and visit your island, where you will visualize your ships at sea'
  },
  second: {
    index: 2,
    title: 'Audio Cues',
    description: ' Listen to your manifest statements and forecast yourself into that future moment, when that ship comes in.'
  }
}

export const noAudio = [{
  created_at: '',
  id: 'no-background',
  is_user_uploaded: false,
  name: 'No Background Music',
  order: '0',
  updated_at: '',
  url: 'test',
  user_id: null
}]
