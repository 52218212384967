import React, { SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { subscriptionStatus, createSubscription, subscriptionRenew, updatePaymentId, sendPaymentId, cancelSubscription, deletePayment } from '../../services/accounts.services/account'
import { Subscription, UserSubscriptionStatus } from '../../types/accountTypes'
import { notifyUser } from '../../utils/notify'
import { AuthContext } from '../AuthContext'

interface SubscriptionProviderProps extends React.PropsWithChildren {

}
interface SubscriptionContextData {
  subscriptionData: Subscription,
  setSubscriprionData: React.Dispatch<SetStateAction<Subscription | null>>,
  createSubscriptionMethod: Function
  createNewSubscribe: Function
  addUpdatePaymentMethod: Function
  cancelSubscriptionAtPeriodEnd: Function
  cancelSubscriptionAtImmediately: Function
  deletePaymentMethod: Function
  userSubscriptionStatus: UserSubscriptionStatus
  setUserSubscriprionStatus: React.Dispatch<SetStateAction<UserSubscriptionStatus | null>>,
  dataLoading: boolean
}
export const SubscriptionContext = createContext({} as SubscriptionContextData)
const SubscriptionProvder = ({ children }: SubscriptionProviderProps) => {
  const [subscriptionData, setSubscriprionData] = useState<Subscription | null>(null)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [userSubscriptionStatus, setUserSubscriprionStatus] = useState<UserSubscriptionStatus | null>(null)
  const { isAuthenticated, loadingUserData, setUser, user, signOut } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated && !loadingUserData) {
      (async () => {
        try {
          const resp = await subscriptionStatus()
          if (resp.status) {
            setSubscriprionData(resp.data.data.subscription)
            setUserSubscriprionStatus(resp.data.data)
          }
        } catch (error: any) {
          notifyUser(error.response.data.error.message, 'danger')
        }
      })()
    }
  }, [isAuthenticated])

  const createSubscriptionMethod = async (coupon: string) => {
    try {
      const subResponse = await createSubscription(coupon)
      if (subResponse.data.data.status === 'active') {
        setUser({
          ...user,
          is_subscribe: true,
          subscription: subResponse.data.data
        })
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        notifyUser(error.response.data.error.message, 'danger')
      }
      throw error
    }
  }

  const createNewSubscribe = async (coupon?: string) => {
    setDataLoading(true)
    try {
      const resp = await subscriptionRenew(coupon)
      setDataLoading(false)
      setSubscriprionData(resp?.data.data)
      setUser({
        ...user,
        is_subscribe: true
      })
      notifyUser('New Subscription Added', 'success')
      navigate('/ship-sea')
    } catch (error: any) {
      setDataLoading(false)
      notifyUser(error.response.data.error.message, 'danger')
      throw error
    }
  }

  const addUpdatePaymentMethod = async (paymentMethodId: string) => {
    setDataLoading(true)
    let notification = ''
    try {
      if (!user?.hasPaymentMethod) {
        const resp = await sendPaymentId(paymentMethodId)
        if (!user.is_subscribe && !user.subscription) {
          await createSubscriptionMethod('')
        } else {
          await createNewSubscribe()
        }
        setUser({
          ...user,
          hasPaymentMethod: true,
          is_subscribe: true
        })
        setDataLoading(false)
        notification = resp.data.data.msg || 'New payment method Added successfully'
      } else {
        const resp = await updatePaymentId(userSubscriptionStatus?.paymentMethod?.id!, paymentMethodId)
        setUserSubscriprionStatus({
          ...userSubscriptionStatus!,
          paymentMethod: resp?.data?.data
        })
        if (user?.subscription?.status === 'canceled') {
          await createNewSubscribe()
        }
        setUser({
          ...user,
          hasPaymentMethod: true
        })
      }
      notifyUser(notification, 'success')

      navigate('/get-started')
    } catch (error: any) {
      setDataLoading(false)
    }
  }
  const cancelSubscriptionAtPeriodEnd = async () => {
    try {
      const resp = await cancelSubscription('atPeriodEnd')
      setSubscriprionData(resp.data.data)
    } catch (error: any) {
      notifyUser(error.response.data.error.message, 'danger')
    }
  }
  const cancelSubscriptionAtImmediately = async () => {
    try {
      await cancelSubscription('immediately')
      setUser({
        ...user,
        is_subscribe: false
      })
      signOut()
    } catch (error: any) {
      notifyUser(error.response.data.error.message, 'danger')
    }
  }
  const deletePaymentMethod = async () => {
    try {
      const resp = await deletePayment(userSubscriptionStatus?.paymentMethod?.id!)
      notifyUser(resp.data.data.message, 'success')
      setUser({
        ...user,
        hasPaymentMethod: false
      })
    } catch (error: any) {
      notifyUser(error.response.data.error.message, 'danger')
    }
  }
  return (
    <SubscriptionContext.Provider value={{ subscriptionData: subscriptionData!, setSubscriprionData, cancelSubscriptionAtPeriodEnd, deletePaymentMethod, cancelSubscriptionAtImmediately, addUpdatePaymentMethod, createSubscriptionMethod, createNewSubscribe, userSubscriptionStatus: userSubscriptionStatus!, setUserSubscriprionStatus, dataLoading }}>{children}</SubscriptionContext.Provider>
  )
}

export default SubscriptionProvder
