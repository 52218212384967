import { Box, Tab, Tabs, Typography, Switch, Dialog } from '@mui/material'
import * as React from 'react'
import './index.css'
import { useState } from 'react'

import DreamDesignDownload from '../../components/Downloads/DREAMDESIGN'
import Text from '../../components/Text/Text'
import { ShipContext } from '../../context/ShipContext'
import ShipContainer from '../../pages/Welcome/ShipContainer'
import { ShipItemsPdfData } from '../../types'
import { ShipCategory, ShipItemByCategory } from '../../types/ShipTypes'
import { CloseSVG } from '../../utils/svgs'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel (props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps (index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function BasicTabs () {
  const { shipCategories, shipItemsByCategory, activeTabIndex, arrivedShipItemsByCategory, loadingShipItems, setActiveTabIndex, setShipItemsByCategory, setArrivedShipItemsByCategory, shipItemData } = React.useContext(ShipContext)

  const [selectedShipCategory, setSelectedShipCategory] = React.useState<ShipCategory>()
  const [checked, setChecked] = React.useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedShipCategory(shipCategories[newValue])
    setActiveTabIndex(newValue)
  }

  React.useEffect(() => {
    setSelectedShipCategory(shipCategories[activeTabIndex])
  }, [shipCategories])

  const shipColors = {
    Health: {
      containerColor: '#f0f4f4',
      spaceColor: '#FFFFFF',
      textColor: '#000000'
    },
    Self: {
      containerColor: '#c8e4ec',
      spaceColor: '#f0f4f4',
      textColor: '#000000'
    },
    Play: {
      containerColor: '#5E7689',
      spaceColor: '#c8e4ec',
      textColor: '#FFFFFF'
    },
    Passion: {
      containerColor: '#384c5c',
      spaceColor: '#60748c',
      textColor: '#FFFFFF'
    },
    Wealth: {
      containerColor: '#182434',
      spaceColor: '#384c5c',
      textColor: '#FFFFFF'
    }
  }

  // const err = true
  // if (err) {
  //   throw new Error('err')
  // }

  type titleType = 'Health' | 'Wealth' |'Self' | 'Passion' | 'Play'

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const atSeaData = {} as ShipItemByCategory
      const arrivedSeaData = {} as ShipItemByCategory
      shipItemData.map((shipData: ShipItemsPdfData) => shipData.shipItems).flat(Infinity).forEach((item: any) => {
        const shipId = item.ship_id
        if (item.status === 'at_sea') {
          if (!atSeaData[shipId]) {
            atSeaData[shipId] = []
          }
          atSeaData[shipId].push(item)
        } else {
          if (!arrivedSeaData[shipId]) {
            arrivedSeaData[shipId] = []
          }
          arrivedSeaData[shipId].push(item)
        }
      })
      setShipItemsByCategory(atSeaData)
      setArrivedShipItemsByCategory(arrivedSeaData)
    }
    setChecked(event.target.checked)
  }

  return (
    <Box sx={{ width: '100%' }} id="right-content-wrapper">
      {downloadOpen
        ? <Dialog open className='edit-ship__dialog'>
            <div onClick={() => setDownloadOpen(false)} className='edit-ship__close-btn'><CloseSVG /></div>
            <div className='edit-ship__wrapper'>
              <div className='edit-ship-header'>
                <div className="popup-title">
                  <div className='header-main__title'>
                    DREAM DESIGN
                  </div>
                  <div className='header-sub__title'>
                    My Files
                  </div>
                </div>
              </div>
              <DreamDesignDownload audioRequired={true} />
            </div>
          </Dialog>
        : null
      }
      <Box sx={{}}>
        <div className='ships__topbanner'>
        <div className='ships__topbanner_right'>
          <Text preset='h2' text='Ships' style={{ color: '#000' }} />
          <div className='view-all-btn' >
          <Switch checked={checked} onChange={handleChecked} sx={{ height: '43px' }}/>
              <div className='viewall-btn'>View All</div>
          </div>

              </div>
            <div className='ships__topbanner_right second'>
              <div onClick={() => setDownloadOpen(true)}>
                <Text preset='paragraph' text='DOWNLOADS' />
              </div>
            </div>
        </div>
        {
        !checked
          ? (

  <Tabs disableRipple value={activeTabIndex} onChange={handleChange} aria-label="Ships tab" className='ship__nav'>
            {
              shipCategories && shipCategories.map((shipCategory: ShipCategory, index: number) => {
                return (
                  <Tab
                    key={shipCategory.id}
                    label={
                      <div className={`individual__tabs first ${shipCategory.title}-wrap`}>
                      <div className='individual__tabs_inner'>
                      <img src={shipCategory?.image} alt={shipCategory.title} />
                      <Text preset='paragraph' text={shipCategory?.title || 'N/A'} style={{ letterSpacing: '0.5em' }} />
                      </div>
                      </div>
                    } {...a11yProps(index)}
                />
                )
              })
            }
          </Tabs>

            )
          : null
        }
      </Box>
      <TabPanel value={activeTabIndex} index={activeTabIndex} >
        {
          !checked
            ? (selectedShipCategory?.id &&
            <ShipContainer
              atSeaData={selectedShipCategory?.id ? shipItemsByCategory[selectedShipCategory.id] : []}
              arrivedData={selectedShipCategory?.id ? arrivedShipItemsByCategory[selectedShipCategory.id] : []}
              loading={loadingShipItems} shipCategory={selectedShipCategory}
            />
              )
            : shipCategories.map(ship => ship.id).map((key) => {
              const arrivedData = arrivedShipItemsByCategory[key]
              const seaData = shipItemsByCategory[key]
              const ship = shipCategories.find(ship => ship.id === key)
              const shipColor = shipColors[ship!.title as titleType].containerColor
              const spaceColor = shipColors[ship!.title as titleType].spaceColor
              const textColor = shipColors[ship!.title as titleType].textColor
              return (

              <ShipContainer
                atSeaData={seaData}
                arrivedData={arrivedData}
                loading={loadingShipItems} shipCategory={ship} viewAll={checked} shipColor={shipColor} spaceColor={spaceColor} textColor={textColor} key={String(key)}/>
              )
            })
        }
      </TabPanel>
    </Box>
  )
}
