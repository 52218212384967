import React, { useContext, useEffect, useState } from 'react'
import './index.css'
import { useLocation } from 'react-router-dom'

import BasicModal from '../../components/BasicModal/BasicModal'
import EditShipPopup from '../../components/EditShipPopup/EditShipPopup'
import ShipItemHeader from '../../components/ShipItemHeader'
import ShipsCard from '../../components/ShipsCard'
import CommonSkeleton from '../../components/Skeleton'
import Text from '../../components/Text/Text'
import { ShipContext } from '../../context/ShipContext'
import {
  EditItemDataType,
  // MoveShipItemToArrivedType,
  ShipCategory,
  ShipItem
} from '../../types/ShipTypes'

interface ShipProps {
  loading: boolean;
  shipCategory?: ShipCategory;
  atSeaData?: ShipItem[];
  arrivedData?: ShipItem[];
  viewAll?: Boolean;
  shipColor?: string;
  spaceColor?: string;
  textColor?: string;
}

const ShipContainer = (props: ShipProps) => {
  const {
    loading,
    shipCategory,
    atSeaData,
    arrivedData,
    viewAll,
    shipColor,
    spaceColor,
    textColor
  } = props

  const { pathname } = useLocation()
  const [location, setLocation] = useState('ship-sea')
  const [isOpen, setIsOpen] = useState(false)
  const [isForView, setIsForView] = useState(false)
  const [editItemData, setEditItemData] = useState<EditItemDataType>(null)
  const [isMoveShipBoxOpen, setIsMoveShipBoxOpen] = useState(false)
  const [deleteArrivedItem, setDeleteArrivedItem] = useState<ShipItem | null>(null)
  // const [moveItemData, setMoveItemData] = useState<MoveShipItemToArrivedType>()

  const { moveShipItemToArrived, isLoadingArrived, moveShipItemBackToSea, isLoadingAtSea } = useContext(ShipContext)

  useEffect(() => {
    setLocation(pathname.split('/').slice(-1)[0])
  }, [pathname])

  if (loading) {
    return (
      <CommonSkeleton
        count={2}
        height={200}
        style={{ margin: '15px', width: '100%', borderRadius: '25px' }}
      />
    )
  }

  const closeHandler = () => {
    setIsOpen(false)
    setEditItemData(null)
    setIsForView(false)
  }

  const handleShipItemDelete = () => {
    // api call for deleting this ship
    if (moveShipItemBackToSea) {
      moveShipItemBackToSea(deleteArrivedItem?.ship_id!, deleteArrivedItem?.id!)
    }
    // make deleteArrivedItem null again
    setDeleteArrivedItem(null)
    setIsMoveShipBoxOpen(false)
  }

  return (
    <div style={{ background: spaceColor, color: textColor }}>
      <BasicModal
                isOpen={isMoveShipBoxOpen}
                closeHandler={() => setIsMoveShipBoxOpen(false)}
                okHandler={() => {
                  handleShipItemDelete()
                }}
                cancelHandler={() => setIsMoveShipBoxOpen(false)}
                header="Move Ship Item"
                body="Are you sure you want to move item to sea?"
                labelOk="Move"
                labelCancel='Cancel'
            />
      <div className='ship__container' style={{ marginTop: 0, background: shipColor, borderTopRightRadius: viewAll ? '50px' : '0' }}>
      <ShipItemHeader shipId = {shipCategory?.id} header={shipCategory?.title as string} location={location} setIsOpen={(value: boolean) => setIsOpen(value)} isForEdit={(val: EditItemDataType) => setEditItemData(val)} textColor={textColor as string} />

      <div className='customStyle'>
      { pathname === '/ship-sea'
        ? (!loading && Boolean(atSeaData)) && atSeaData?.map((item: ShipItem) => {
            return <ShipsCard key={item?.id} shipItemData={item} location={location} setIsOpen={(value: boolean) => setIsOpen(value)} isForEdit={(val: EditItemDataType) => setEditItemData(val)} moveShipItemToArrived={(shipId, itemId) => moveShipItemToArrived(shipId, itemId)}
            viewAll={viewAll} isLoadingArrived={isLoadingArrived} setIsForView={(val) => setIsForView(val)} />
          })
        : (!loading && Boolean(arrivedData)) && arrivedData?.map((item: ShipItem) => {
            return <ShipsCard key={item?.id} shipItemData={item} location={location} setIsOpen={(value: boolean) => setIsOpen(value)} isForEdit={(val: EditItemDataType) => setEditItemData(val)} isLoadingAtSea={isLoadingAtSea} setIsMoveShipBoxOpen={(value: boolean) => setIsMoveShipBoxOpen(value)} setDeleteArrivedItem={setDeleteArrivedItem}/>
          })
        }
    </div>

        {isOpen && (
          <EditShipPopup
            closeHandler={() => closeHandler()}
            editItemData={editItemData}
            shipId={shipCategory?.id || ''}
            isForView={isForView}
            setIsForView={(val: boolean) => setIsForView(val)}
            isForEdit={(val: EditItemDataType) => setEditItemData(val)}
            setIsOpen={setIsOpen}
          />
        )}
      </div>
      { pathname === '/ship-sea' && !viewAll
        ? !atSeaData && <Text preset="h3" text="No Data Found" style={{ marginTop: '30px', marginBottom: '0', lineHeight: 'normal', textAlign: 'center' }} />
        : ''
      }
      { pathname === '/ship-arrived' && !viewAll
        ? !arrivedData && <Text preset="h3" text="All Ships At Sea" style={{ marginTop: '30px', marginBottom: '0', lineHeight: 'normal', textAlign: 'center' }} />
        : ''
      }
    </div>
  )
}

export default ShipContainer
