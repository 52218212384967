import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Grid, Button, FormControl, InputAdornment, IconButton, TextField, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useState, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// import { login } from '../../api/login'
// import { Navigate } from 'react-router-dom'

import { loginFormSchema } from '../../components/Register/AuthValidation'
import Text from '../../components/Text/Text'
import { AuthContext } from '../../context/AuthContext'
// import { setStorageData } from '../../utils/localStorage'

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const { signIn } = useContext(AuthContext)

  const { values, handleSubmit, handleChange, touched, errors } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginFormSchema,
    onSubmit: async (values) => {
      window.scrollTo(0, 0)
      setIsLoading(true)
      await signIn(values)
      setIsLoading(false)
    }
  })

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const handleRegister = () => {
    navigate('/register')
  }
  const handleForgotPass = () => {
    navigate('/change-password')
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit}>
        {/* <Text preset='eyebrow' text="Login" style={{ textAlign: 'center', marginBottom: 0 }} /> */}
        <Text preset="h1" text="Sign In" style={{ textAlign: 'center', marginTop: '0' }} />
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Text preset='label' text="Email" />
            <TextField fullWidth name="email" type="email" value={values.email} onChange={handleChange} error={!!(touched.email && errors.email)}
              helperText={touched.email && errors.email} />
          </Grid>
          <Grid item xs={12}>
            <Text preset='label' text="Password" />
            <FormControl sx={{ m: 1, width: '100%', position: 'relative' }} variant="filled">
              {/* <InputLabel htmlFor="filled-adornment-password">Password</InputLabel> */}
              <TextField fullWidth name="password" type={showPassword ? 'text' : 'password'} id="filled-adornment-password"
                onChange={handleChange} error={!!(touched.password && errors.password)}
                helperText={touched.password && errors.password} />
              <InputAdornment position="end" sx={{ position: 'absolute', top: '50%', right: '5%' }}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            </FormControl>
          </Grid>

        </Grid>
        <Grid container justifyContent="center" className="mt-10">
          <Button
            size="large"
            type="submit"
            className="form-submit-button"
            disabled={isLoading}
          >
            {
              isLoading ? <CircularProgress /> : 'Sign In'
            }
          </Button>
        </Grid>
        <Grid container justifyContent="center" className="mt-10">
          <span onClick={handleForgotPass}>
            <Text preset='paragraph' text="Forgot Password?" style={{ marginRight: '10px', cursor: 'pointer' }} /></span>
          <span onClick={handleRegister}>
            <Text preset='paragraph' text="Register" style={{ cursor: 'pointer' }} />
          </span>

        </Grid>
      </form>
    </>
  )
}

export default Login
