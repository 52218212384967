import { Grid, TextField } from '@mui/material'
import React from 'react'

import Text from '../Text/Text'
interface Props{
    name:string,
    labels: string,
    value: string,
    isEdit?: boolean,
    handleEdit?: Function,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    disabled?:boolean,
    errors?:boolean | undefined,
    helperText?:string,
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const customStyle = {
  border: '2px solid #E8E8E8',
  boxShadow: '0px 20px 25px rgba(103, 103, 103, 0.0509804)',
  borderRadius: '15px',
  width: '100%'
}

const FormComponent:React.FC<Props> = (props) => {
  const { labels, value, isEdit, disabled, helperText, handleChange, errors, ...other } = props

  return (
    <>
      <Grid item xs={12} sm={6} xl={6} md={6}>
          <div className='first__name edit_profile_content'>
            <Text preset='paragraph' text={labels} style={{ lineHeight: '10px', color: '#5E7689', letterSpacing: '0.5em' }}/>
            { isEdit ? <TextField id="outlined-basic" label={value} variant="outlined" value={value} sx={customStyle} {...other} disabled={disabled} error={errors} helperText={helperText} onChange={handleChange} /> : <Text preset='paragraph' text={value} style={{ lineHeight: '10px', color: '#222222' }}/>}
          </div>
        </Grid>

          </>
  )
}

export default FormComponent
