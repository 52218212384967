import { ResetPassword } from '../../types/authTypes'
import { api } from '../api'

export const signup = async (data: any) => {
  const res = await api.post(
    '/auth/register',
    data
  )
  return res.data
}

export const forgetPassword = async (email: string) => {
  const res = await api.post(
    '/auth/forgot-password',
    { email }
  )
  return res.data
}

export const resetPassword = async (data: ResetPassword) => {
  const { resetToken, uid, password, confirmPassword } = data
  const res = await api.post(
    '/auth/reset-password',
    {
      reset_token: resetToken,
      uid,
      password,
      confirm_password: confirmPassword
    }
  )
  return res.data
}
