import { Button, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteStorage } from '../../utils/localStorage'
import Text from '../Text/Text'

interface CustomText {
  dreamBigJourney: {
    text: string
    style: React.CSSProperties
  }
}

const CompletionPage = () => {
  const navigate = useNavigate()
  const customText: CustomText = {
    dreamBigJourney: {
      text: 'To begin your DreamBig journey we first need to conduct your DreamDesign Session. This will take about 60 minutes and ideally should be done at a time and place you can concentrate and listen without distractions. If for some reason you have to pause, your progress will be saved. ',
      style: {
        maxWidth: '494px',
        height: '146px',
        fontFamily: 'Arial',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '21px',
        color: '#333333',
        backdropFilter: 'blur(5.70647px)',
        margin: '0 auto',
        textAlign: 'center'
      }
    }
  }

  const handleClick = () => {
    deleteStorage('step')
    navigate('/')
  }

  return (
    <>

      <Text preset='eyebrow' text="Success" style={{ textAlign: 'center', marginBottom: 0 }} />
      <Text preset="h1" text="Congrats, Let’s Start Dreaming." style={{ textAlign: 'center', marginTop: '0' }} />
      <Text preset="paragraph" text={customText.dreamBigJourney.text} style={customText.dreamBigJourney.style}/>
      <Grid container justifyContent="center" className="mt-10">
        <Button
          size="large"
          type="submit"
          className="form-submit-button"
          onClick={handleClick}
        >
          BEGIN YOUR DREAM DESIGN
        </Button>
      </Grid>
    </>
  )
}

export default CompletionPage
