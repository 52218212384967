import axios from 'axios'

const DEPOSITPHOTO_URL = 'https://api.depositphotos.com/?dp_command=search'

export function getPhotosFromDepositPhotos (searchQuery: string, offset: number) {
  return axios.post(DEPOSITPHOTO_URL, {
    dp_apikey: process.env.REACT_APP_DEPOSIT_PHOTO_KEY,
    dp_search_query: searchQuery,
    dp_search_limit: 18,
    dp_search_offset: offset
  })
}
