import React from 'react'
import { ReactNotifications } from 'react-notifications-component'
import { BrowserRouter } from 'react-router-dom'

// import NavBar from './components/NavBar/navbar'
import 'react-notifications-component/dist/theme.css'

import { DreamDesignProvider } from './context'
import { RouteList } from './routes'

const App = () => (
  <>
    <ReactNotifications />
    <BrowserRouter>
      <DreamDesignProvider>
        <RouteList />
      </DreamDesignProvider>
    </BrowserRouter>
    </>
)

export default App
