import React, { createContext, useContext, useEffect, useState } from 'react'

import { getSubscriptionDetails } from '../../services/accounts.services/account'
import { AccountContextData } from '../../types/accountTypes'
import { AuthContext } from '../AuthContext'

interface AccountProviderProps extends React.PropsWithChildren {

}
export const AccountContext = createContext({} as AccountContextData)
const AccountProvder = ({ children }:AccountProviderProps) => {
  const [loadingData, setLoadingData] = useState(false)
  const [accountData, setAccountData] = useState({})
  const { isAuthenticated } = useContext(AuthContext)
  const getAccountData = async () => {
    setLoadingData(true)
    try {
      const resp = await getSubscriptionDetails()
      setAccountData(resp.data)
      setLoadingData(false)
    } catch (error) {
      setLoadingData(false)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getAccountData()
    }
  }, [isAuthenticated])

  return (
    <AccountContext.Provider value={{ loadingData, accountData }}>{children}</AccountContext.Provider>
  )
}

export default AccountProvder
