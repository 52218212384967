import React, { useContext, useEffect, useState } from 'react'

import './RenewSubscription.css'
import { useNavigate } from 'react-router-dom'

import RenewPaymentForm from '../../components/Register/RenewPaymentFrom'
import { AuthContext } from '../../context/AuthContext'
import { SubscriptionContext } from '../../context/SubscriptionContext'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!)

const RenewSubscription = () => {
  const [showPaymentMethod, setShowPaymentMethod] = useState(false)
  const { createNewSubscribe, dataLoading } = useContext(SubscriptionContext)
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleClick = async () => {
    try {
      await createNewSubscribe()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (user && user?.hasPaymentMethod && !user?.is_subscribe) {
      setShowPaymentMethod(false)
    } else {
      setShowPaymentMethod(true)
    }
  }, [user])
  useEffect(() => {
    if (user && (user?.is_subscribe && user?.hasPaymentMethod)) {
      navigate('/ship-sea')
    }
  }, [user])

  return (<>
    {
      showPaymentMethod
        ? <div>
          <h4 style={{ textAlign: 'center' }}>You Do not have payment method please add a new one !!</h4>
          <Elements stripe={stripePromise}>
            <RenewPaymentForm />
          </Elements>

        </div>
        : <div style={{ textAlign: 'center' }}>
          <h4>Your Subscription is Expired</h4>
          {
            dataLoading ? <>Please Wait ...</> : <button onClick={() => handleClick()} className='renew-btn'>Continue To Renew Your subscription</button>
          }

        </div>
    }
  </>

  )
}

export default RenewSubscription
