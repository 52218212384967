import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { api } from '../../services/api'
import { pdfData } from '../../services/ship.service/pdfData'
import { createShipItemService, deleteShipItemService, getShipItemsByCategoryService, moveShipItemToArrivedService, moveShipItemToSeaService, updateShipService } from '../../services/ship.services/ship'
import { ApiResponse, ShipItemsPdfData } from '../../types'
import { ShipInfo } from '../../types/progressBarTypes'
import { CreateShipItem, DeleteShipItemType, EditShipItem, GetShipItemsByCategory, MoveShipItemToArrivedType, ShipCategory, ShipContextData, ShipItem, ShipItemByCategory } from '../../types/ShipTypes'
import { notifyUser } from '../../utils/notify'
import { AuthContext } from '../AuthContext'

interface ShipProviderProps extends React.PropsWithChildren {
}

export const ShipContext = createContext({} as ShipContextData)

export function ShipProvider ({ children }: ShipProviderProps) {
  const [loadingShips, setloadingShips] = useState(false)
  const [loadingShipItems, setLoadingShipItems] = useState(false)
  const [shipCategories, setShipCategories] = useState<ShipCategory[]>([])
  const [shipItemsByCategory, setShipItemsByCategory] = useState<ShipItemByCategory>({})
  const [arrivedShipItemsByCategory, setArrivedShipItemsByCategory] = useState<ShipItemByCategory>({})
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [isLoadingArrived, setIsLoadingArrived] = useState(false)
  const [isLoadingAtSea, setIsLoadingAtSea] = useState(false)
  const [shipItemData, setShipItemData] = useState<ShipItemsPdfData[]>([])
  const [trainingVideoTime, setTrainingVideoTime] = useState('00:00')
  const [order, setOrder] = useState(1)
  const { isAuthenticated } = useContext(AuthContext)
  const [shipDataArray, setShipDataArray] = useState<ShipInfo[]>([])

  const { pathname } = useLocation()

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          setloadingShips(true)
          const response = await api.get<ApiResponse<ShipCategory[]>>('/ships')
          if (response.data?.data) {
            setShipCategories(response.data?.data)
          } else {
            notifyUser('no data found', 'danger')
          }
          setloadingShips(false)
        } catch (error: any) {
          setloadingShips(false)
          if (error?.response?.data?.error) {
            notifyUser(error?.response?.data?.error?.message, 'danger')
          }
        }
      })()
    }
  }, [isAuthenticated])

  useEffect(() => {
    // const d = 's'
    if (shipCategories.length > 0 && ['/ship-sea', '/ship-arrived'].includes(pathname)) {
      const shouldCallShipItemByCategoryForAtSea = pathname === '/ship-sea' && shipItemsByCategory[shipCategories[activeTabIndex].id] === undefined
      const shouldCallShipItemByCategoryForArrived = pathname === '/ship-arrived' && arrivedShipItemsByCategory[shipCategories[activeTabIndex].id] === undefined
      if (shouldCallShipItemByCategoryForAtSea) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getShipItemsByCategory(shipCategories[activeTabIndex].id, 'at_sea')
      }
      if (shouldCallShipItemByCategoryForArrived) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getShipItemsByCategory(shipCategories[activeTabIndex].id, 'arrived')
      }
    }
  }, [pathname, shipCategories, activeTabIndex])

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const response = await pdfData()
          setShipItemData(response.data.data)
        } catch (error: any) {
          if (error?.response?.data?.error) {
            notifyUser(error?.response?.data?.error?.message, 'danger')
          }
        }
      })()
    }
  }, [shipItemsByCategory])

  const updateShipItemsByCategory = (shipId: string, shipItem: ShipItem, isEdit = false) => {
    if (isEdit) {
      const editedData = shipItemsByCategory[shipId].map((item: ShipItem) => {
        if (item.id === shipItem.id) {
          return shipItem
        }
        return item
      })
      setShipItemsByCategory((prevIetms) => ({
        ...prevIetms,
        [shipId]: editedData
      }))
    } else {
      let itemToUpdate: ShipItem[] = shipItemsByCategory[shipId] || []
      itemToUpdate = [...itemToUpdate, shipItem]
      setShipItemsByCategory((prevItems) => ({
        ...prevItems,
        [shipId]: [...itemToUpdate]

      }))
    }
  }

  const deleteShipItemsByCategory = (categoryId: string, itemId: string) => {
    const dataAfterDelete = shipItemsByCategory[categoryId].filter((item: ShipItem) => item.id !== itemId)
    setShipItemsByCategory((prevIetms) => ({
      ...prevIetms,
      [categoryId]: dataAfterDelete
    }))
  }

  const deleteArrivedShipItemsByCategory = (categoryId: string, itemId: string) => {
    const dataAfterDelete = arrivedShipItemsByCategory[categoryId].filter((item: ShipItem) => item.id !== itemId)
    setArrivedShipItemsByCategory((prevIetms) => ({
      ...prevIetms,
      [categoryId]: dataAfterDelete
    }))
  }

  const getShipItemsByCategory: GetShipItemsByCategory = async (shipCategoryId, status = '', isTraining = false) => {
    try {
      setLoadingShipItems(true)
      const response = await getShipItemsByCategoryService(shipCategoryId, status)
      const allResp = response.data?.data
      if (isTraining) {
        return allResp
      }
      if (allResp.length > 0) {
        if (status === 'at_sea') {
          setShipItemsByCategory((prevItems) => ({
            ...prevItems,
            [shipCategoryId]: allResp
          }))
        } else {
          setArrivedShipItemsByCategory((prevItems) => ({
            ...prevItems,
            [shipCategoryId]: allResp
          }))
        }
        setLoadingShipItems(false)
        return response.data?.data
      } else {
        setLoadingShipItems(false)
        return null
      }
    } catch (error: any) {
      setLoadingShipItems(false)
      if (error?.response?.data?.error) {
        notifyUser(error?.response?.data?.error?.message, 'danger')
      }
      return null
    }
  }

  const editShipItem: EditShipItem = async (shipCategoryId, data, shipItemId) => {
    try {
      const body = new FormData()
      if (Object.entries(data).length > 0) {
        body.append('title', data.title)
        body.append('manifest', data.manifest)
        if (data.audio) {
          body.append('audio', data.audio)
        }
        if (data.image) {
          if (typeof data.image === 'string') {
            body.append('imageURL', data?.image)
            body.append('depositURL', data.depositURL!)
            body.delete('image')
          } else {
            body.append('image', data.image)
            body.delete('imageURL')
          }
        }
      } else {
        notifyUser('No data updated', 'warning')
      }
      const res = await updateShipService(shipCategoryId, body, shipItemId)

      if (Object.entries(res.data.data).length > 0) {
        updateShipItemsByCategory(shipCategoryId, res.data.data, true)
      } else {
        notifyUser('No ship items found.', 'danger')
      }
      notifyUser('Ship Items Updated.', 'success')
    } catch (error: any) {
      if (error?.response?.data?.error) {
        notifyUser(error?.response?.data?.error?.message, 'danger')
      }
    }
  }

  const createShipItem: CreateShipItem = async (shipCategoryId, data, isTrainingShip = false) => {
    const isTraining = isTrainingShip ?? false
    try {
      const body = new FormData()
      body.append('title', data.title)
      body.append('manifest', data.manifest)
      body.append('audio', data.audio)
      body.append('is_training_ship', `${isTrainingShip}`)
      body.append('depositURL', data.depositURL!)

      if (typeof data.image === 'string') {
        body.append('imageURL', data?.image)
      } else {
        body.append('image', data.image)
      }

      const response = await createShipItemService(shipCategoryId, body)

      if (isTraining) {
        return response.data.data
      } else {
        if (Object.entries(response.data.data).length > 0) {
          updateShipItemsByCategory(shipCategoryId, response.data.data)
        } else {
          notifyUser('No ship items found.', 'danger')
        }
      }
      notifyUser('Ship Items Created.', 'success')
    } catch (error: any) {
      if (error?.response?.data?.error) {
        notifyUser(error?.response?.data?.error?.message, 'danger')
      }
    }
  }

  const deleteShipItem: DeleteShipItemType = async (shipCategoryId, shipItemId) => {
    try {
      await deleteShipItemService(shipCategoryId, shipItemId)
      // await api.delete<void>(`/ships/${shipCategoryId}/ship-items/${shipItemId}`)
      notifyUser('Ship Item Deleted.', 'danger')
      deleteShipItemsByCategory(shipCategoryId, shipItemId)
    } catch {

    }
  }

  const moveShipItemToArrived: MoveShipItemToArrivedType = async (shipCategoryId, shipItemId) => {
    try {
      setIsLoadingArrived(true)
      const response = await moveShipItemToArrivedService(shipCategoryId, shipItemId)
      setIsLoadingArrived(false)
      if (response.data.data) {
        deleteShipItemsByCategory(shipCategoryId, shipItemId)
        // add ship item to arrived ship items data locally  to avoid page refresh to reflect data change
        let itemToUpdate: ShipItem[] = arrivedShipItemsByCategory[shipCategoryId] || []

        if (itemToUpdate.length === 0) {
          try {
            const response = await getShipItemsByCategory(shipCategoryId, 'arrived')
            if (response) {
              itemToUpdate = response || []
            }
          } catch (error) {
          }
          itemToUpdate = [...itemToUpdate]
        } else {
          itemToUpdate = [...itemToUpdate, response.data.data]
        }

        setArrivedShipItemsByCategory((prevItems) => ({
          ...prevItems,
          [shipCategoryId]: [...itemToUpdate]

        }))
      } else {
        notifyUser('No ship items found.', 'danger')
        setIsLoadingArrived(false)
      }
    } catch {}
  }

  const moveShipItemBackToSea: MoveShipItemToArrivedType = async (shipCategoryId, shipItemId) => {
    try {
      setIsLoadingAtSea(true)
      const response = await moveShipItemToSeaService(shipCategoryId, shipItemId)
      setIsLoadingAtSea(false)
      if (response.data.data) {
        deleteArrivedShipItemsByCategory(shipCategoryId, shipItemId)
        // add ship item to arrived ship items data locally  to avoid page refresh to reflect data change
        let itemToUpdate: ShipItem[] = shipItemsByCategory[shipCategoryId] || []

        if (itemToUpdate.length === 0) {
          try {
            const response = await getShipItemsByCategory(shipCategoryId, 'at_sea')
            if (response) {
              itemToUpdate = response || []
            }
          } catch (error) {
          }
          itemToUpdate = [...itemToUpdate]
        } else {
          itemToUpdate = [...itemToUpdate, response.data.data]
        }

        setShipItemsByCategory((prevItems) => ({
          ...prevItems,
          [shipCategoryId]: [...itemToUpdate]

        }))
      } else {
        notifyUser('No ship items found.', 'danger')
        setIsLoadingArrived(false)
      }
    } catch {}
  }

  return (
    <ShipContext.Provider value={{
      loadingShips,
      loadingShipItems,
      shipItemsByCategory,
      arrivedShipItemsByCategory,
      shipCategories,
      activeTabIndex,
      createShipItem,
      editShipItem,
      getShipItemsByCategory,
      deleteShipItem,
      setActiveTabIndex,
      moveShipItemToArrived,
      isLoadingArrived,
      moveShipItemBackToSea,
      isLoadingAtSea,
      setShipItemsByCategory,
      setArrivedShipItemsByCategory,
      shipItemData,
      trainingVideoTime,
      setTrainingVideoTime,
      order,
      setOrder,
      shipDataArray,
      setShipDataArray
    }}>
      {children}
    </ShipContext.Provider>
  )
}
